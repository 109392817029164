import React, { useRef, useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Label } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import { ListView } from "@progress/kendo-react-listview";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import {
   getDateTime,
   getDateObject,
   isNullOrEmpty,
} from "../../helpers/utils";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import "./DataWeatherManual.css";

const fields = [
   { field: "source", type: "string", label: "Source", disabled: true, hidden: true },
   { field: "dateTime", type: "string", label: "Date/Time", disabled: true },
   { field: "location", type: "string", label: "Location", disabled: true },
   { field: "condition", type: "string", label: "Short Description" },
   { field: "tempHiC", type: "number", label: "High Temperature Celsius" },
   { field: "tempAvgC", type: "number", label: "Average Temperature Celsius" },
   { field: "tempLoC", type: "number", label: "Low Temperature Celsius" },
   { field: "tempHiF", type: "number", label: "High Temperature Fahrenheit" },
   { field: "tempAvgF", type: "number", label: "Average Temperature Fahrenheit" },
   { field: "tempLoF", type: "number", label: "Low Temperature Fahrenheit" },
   { field: "humidity", type: "number", label: "% Humidity" },
   { field: "pressuremb", type: "number", label: "Millibars Atmospheric Pressure" },
   { field: "pressurein", type: "number", label: "Inches of hg Atmospheric Pressure" },
   { field: "clouds", type: "number", label: "% Cloud Cover" },
   { field: "precipmm", type: "number", label: "Millimeters of Precipitation" },
   { field: "precipin", type: "number", label: "Inches of Precipitation" },
   { field: "visibilitykm", type: "number", label: "Kilometers of Visibility" },
   { field: "visibilitymi", type: "number", label: "Miles of Visibility" },
   { field: "windDirection", type: "string", label: "Wind Direction" },
   { field: "windSpeedkph", type: "number", label: "Wind Speed kph" },
   { field: "windGustkph", type: "number", label: "Wind Gust kph" },
   { field: "windSpeedmph", type: "number", label: "Wind Speed mph" },
   { field: "windGustmph", type: "number", label: "Wind Gust mph" },   
];

function DataWeatherManual({
   dataValue,
   handleOnChange,
   sizeFactor,
   formIsLocked,
   setShowDetail,
}) {
   const [mode, setMode] = useState("Modify");
   const data = useRef({ ...dataValue });

   if (isNullOrEmpty(data.current.dateTime)) {
      data.current.dateTime = getDateTime();
   }

   const onValueChange = (event) => {
      const field = event.target.name.split("-")[1];
      let value = event.value;
      if (field === "dateTime") value = getDateTime(value);
      data.current[field] = value;
      if (data.current.source !== "manual") data.current.source = "manual";
   };

   const onSaveOverride = () => {
      if (mode === "Modify") {
         data.current.source = "manual";
         setMode("Save");
         return;
      }
      let obj = data.current;
      let keys = Object.keys(obj);
      let anychange = false;
      for (let k = 0; k < keys.length; k++) {
         const key = keys[k];
         if (key === "source") continue;
         if (obj[key] !== dataValue[key]) anychange = true;
      }
      if (!anychange) {
         setShowDetail(false);
         return;
      }
      handleOnChange(JSON.stringify(obj));
      setShowDetail(false);
   };

   const dataListing = (props) => {
      let field = props.dataItem;
      let tvalue = data.current[field.field];

      if (field.type === "number") {
         if (isNaN(tvalue) || typeof tvalue === "string") tvalue = null;
      }
      if (field.type === "datetime") {
         tvalue = getDateObject(tvalue.substr(0,10)+"T12:00:00");
      }

      let disabled = false;
      if (field.hasOwnProperty("disabled")) {
         disabled = field.disabled;
      } else if (mode === "Modify") {
         disabled = true;
      }

      if ((field.hasOwnProperty("hidden")) && (field.hidden === true)) {
         return (<></>);
      }

      return (
         <div>
            <div>
               <Label style={{ fontWeight: "600", fontSize: 1 * sizeFactor + "rem" }}>
                  {field.label}
               </Label>
            </div>
            <div style={{ marginBottom: "2px" }}>

               {field.type === "string" && (
                  <Input
                     name={"weather-" + field.field}
                     style={{ width: "100%", fontSize: 1 * sizeFactor + "rem" }}
                     defaultValue={tvalue}
                     onChange={onValueChange}
                     disabled={disabled}
                  />
               )}

               {field.type === "number" && (
                  <NumericTextBox
                     name={"weather-" + field.field}
                     style={{ fontSize: 1 * sizeFactor + "rem" }}
                     defaultValue={tvalue}
                     spinners={false}
                     onChange={onValueChange}
                     disabled={disabled}
                  />
               )}

               {field.type === "datetime" && (
                  <DateTimePicker
                     name={"weather-" + field.field}
                     defaultValue={tvalue}
                     style={{
                        fontSize: 0.85 * sizeFactor + "rem",
                        borderRadius: "2px",
                        padding: "5px",
                        height: "calc(1.4285714286em + 10px)",
                        color: "black",
                        backgroundColor: "white",
                        borderColor: "rgba(0, 0, 0, 0.08)",
                     }}
                     onChange={onValueChange}
                     disabled={disabled}
                     width={"70%"}
                  />
               )}

            </div>
         </div>
      );
   };

   return (
      <Dialog
         title="Weather Detail"
         width={window.innerWidth * 0.9}
         height={window.innerHeight * 0.95}
         onClose={() => setShowDetail(false)}
         style={{ padding: "-10px" }}
      >
         <ListView
            style={{ marginTop: "-15px" }}
            data={fields}
            item={dataListing}
         />
         <DialogActionsBar layout="center">
            {formIsLocked ? (
               <Button onClick={() => setShowDetail(false)}>Done</Button>
            ) : (
               <>
                  <Button onClick={onSaveOverride}>{mode}</Button>
                  <Button onClick={() => setShowDetail(false)}>Cancel</Button>
               </>
            )}
         </DialogActionsBar>
      </Dialog>
   );
}

export default DataWeatherManual;
