import Dexie from "dexie";

const db = new Dexie("enformDB");
db.version(1).stores({
   user: "id",
   keys: "id",
   vals: "id",
   defs: "Id",
   forms: "Id",
   formGroups: "Id, formId",
   formData: "Id, formId",
   ui: "id",
   logs: "++id",
});
db.version(2).stores({
   defsLast: "id",
});
db.on("populate", function () {
   // User information
   db.user.add({ id: 1 });

   // Keys - Since there will be a mix of new and existing records,
   //   this table will hold the next key value for new records
   db.keys.add({ id: 1, nextFormId: -1, nextGroupId: -1, nextDataId: -1 });

   // UI data - this will be used for user preferences in place of cookies
   db.ui.add({ id: 1 });
});

export default db;
