import React, { useState } from "react";
import "./Login.css";
import { Button } from "@progress/kendo-react-buttons";
import { Window } from "@progress/kendo-react-dialogs";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../helpers/authConfig";

function Login() {
   const [errorMessage, setErrorMessage] = useState({
      show: false,
      message: "",
   });
   const { instance } = useMsal();

   const handleLogin = () => {
      instance.loginRedirect(loginRequest).catch((e) => {
         console.log(e);
         setErrorMessage({ show: true, message: e.toString() });
      });
   };

   return (
      <div className="login-container">
         <div className="login-button">
            <Button
               fillMode="outline"
               themeColor="success"
               onClick={handleLogin}
            >
               Login
            </Button>
         </div>
         {errorMessage.show ? (
            <Window
               title="Login Error"
               initialHeight={450}
               initialWidth={350}
               initialTop={200}
               onClose={() => setErrorMessage({ show: false, message: "" })}
            >
               {errorMessage.message}
            </Window>
         ) : null}
      </div>
   );
}

export default Login;
