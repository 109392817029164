import * as types from "../actionTypes";
import initialState from "../initialState";

export default function uiReducer(state = initialState.ui, action) {
   switch (action.type) {
      case types.SET_INITIAL_UI_STATE:
         return { ...state, ...action.newstate };
      case types.SET_TROUBLE_CODE:
         return {
            ...state,
            troubleCode: action.code,
         };
      case types.SET_INCLUDE_SUBMITTED:
         return { ...state, includeSubmitted: !state.includeSubmitted };
      case types.LOAD_SYNC_INFO:
         return {
            ...state,
            syncinfo: action.obj.Sync,
            support: action.obj.support,
         };
      case types.SET_ROUTE:
         return { ...state, route: action.route };
      case types.SET_SUBMIT_TYPE:
         return { ...state, submitType: action.submitType, route: "submit" };
      case types.IS_ONLINE:
         return { ...state, online: action.online };
      case types.SET_LOG_LEVEL:
         return { ...state, logLevel: action.logLevel };
      case types.SET_SIZE_FACTOR:
         return { ...state, sizeFactor: action.sizeFactor };
      case types.CHECK_VERSION:
         return { ...state, checkVersion: action.checkVersion };
      case types.PURGE_LOGS:
         // eslint-disable-next-line eqeqeq
         if (state.lastLogPurge == action.lastLogPurge) return state;
         return { ...state, lastLogPurge: action.lastLogPurge };
      case types.SET_RELEASE_NOTES:
         return { ...state, releaseNotes: action.releaseNotes };
      case types.SET_FORM_LIST_SORT:
         return { ...state, formListSortBy: action.sortBy };
      default:
         return state;
   }
}
