import React, { useEffect } from "react";
import { isNullOrEmpty } from "../../helpers/utils";

function DataInfoOnlyPic({ dataDef, sizeFactor }) {
   useEffect(() => {
      if (dataDef.hasOwnProperty("image") && !isNullOrEmpty(dataDef.image)) {
         fetch(dataDef.image)
            .then((res) => res.blob())
            .then((blob) => {
               const file = new File([blob], "filename", { type: blob.type });
               displayImage(file);
            });
      }
   });

   const displayImage = (file) => {
      const preview = document.querySelector("#preview-" + dataDef.Id);
      while (preview.firstChild) {
         preview.removeChild(preview.firstChild);
      }

      const image = document.createElement("img");
      image.src = URL.createObjectURL(file);
      image.className = "data-photo";
      preview.appendChild(image);
   };
   return (
      <div>
         <div
            id={"preview-" + dataDef.Id}
            style={{
               marginTop: "5px",
               padding: "5px",
               width: "100%",
               border: "1px solid lightgrey",
               borderRadius: "5px",
            }}
         >
            <p>No photo selected</p>
         </div>
      </div>
   );
}

export default DataInfoOnlyPic;
