import { instance as axios } from "./apiClient";
import { isNullOrEmpty, normalizeUrl } from "../helpers/utils";
import * as logger from "../helpers/logger";
import { ParseDataDefs, SaveDefsToDB, GetUsedDefIds } from "../db/defHandler";
import { SaveValidations } from "../db/valHandler";
import * as enums from "../helpers/enums";
import db from "../db/db";

export async function GetDefs(config) {
   logger.Info("defsApi:GetDefs", "GetDefs start");
   const defids = (await GetUsedDefIds()).toString();

   const url =
      normalizeUrl(
         process.env.REACT_APP_API_SERVER_URL,
         "api/EnFormMApi/GetFormDefs"
      ) +
      "?usedDefIds=" +
      defids;

   return axios.get(url, config).then(
      async function (response) {
         await ProcessDefs(response.data, config);
         return true;
      },
      function (error) {
         logger.Error(
            "defsApi:GetDefs",
            "Failed to get form definitions",
            error
         );
         return false;
      }
   );
}

export async function ProcessDefs(data, config) {
   logger.Info("defsApi.ProcessDefs", "ProcessDefs start");
   await SaveDefsToDB(data.Defs);
   await SaveValidations(data.Validations);
   await ParseDataDefs();
   await ProcessIllustrations(data.Defs, config);
}

async function ProcessIllustrations(defs, config) {
   const pics = getIllustrationList(defs);
   if (pics.length > 0) {
      await getIllustrations(pics, config);
   }
}

function getIllustrationList(defs) {
   let pics = [];
   for (let d = 0; d < defs.length; d++) {
      const def = defs[d];
      for (let i = 0; i < def.DataDefs.length; i++) {
         const datadef = def.DataDefs[i];
         if (
            datadef.InfoOnly &&
            datadef.InputType === enums.InputType.Picture &&
            !isNullOrEmpty(datadef.Default)
         ) {
            pics.push({
               defid: def.Id,
               datadefid: datadef.Id,
               docid: datadef.Default,
            });
         }
      }
   }
   return pics;
}

async function getIllustrations(pics, config) {
   for (let p = 0; p < pics.length; p++) {
      const picobj = pics[p];
      await getIllustration(picobj, config);
   }
}

async function getIllustration(picObj, config) {
   logger.Info("defsApi:getIllustration", "Get Illustration " + picObj.docid);

   const url = normalizeUrl(
      process.env.REACT_APP_API_SERVER_URL,
      "api/EnFormMApi/GetDocument/" + picObj.docid
   );

   return axios.get(url, config).then(
      async function (response) {
         let defdb = await db.defs.get(picObj.defid);
         defdb.DataDefs[picObj.datadefid].image = response.data.FileContentString;
         await db.defs.put(defdb);
         return true;
      },
      function (error) {
         logger.Error(
            "defsApi:GetDefs",
            "Failed to get Illustration " + picObj.docid,
            error
         );
         return false;
      }
   );
}
