import db from "./db";

export async function GetInitialUserState() {
  const user = await GetUserDb();
  const projects = await db.table('vals').get("Projects");
  let projectList = [];
  if (projects !== null && projects !== undefined) {
    let list = Object.keys(projects.records);
    for (let i = 0; i < list.length; i++) {
      projectList.push(projects.records[list[i]].Name);
    }
  }

  const hasdigitalid =
    user.hasOwnProperty("DigitalIdOnFile") && user.DigitalIdOnFile;

  const dateTime = new Date().toISOString();

  const defaultFormListingPreferences = {
    SortByValue: "Date",
    LastUpdated: dateTime,
  };

  const defaultFormNewPreferences = {
    LastZipCodeUsed: "",
    LastLocationType: "geo",
  };

  return {
    FullName: user.FullName,
    Roles: user.Roles,
    Username: user.Username,
    ProjectList: projectList,
    HasDigitalId: hasdigitalid,
    Email: user.Email,
    LastSyncDate: user.lastSyncDate || new Date().toISOString(),
    Recipients: (user.Recipients && user.Recipients.recipients ? user.Recipients.recipients : []),
    copyApprover: !!user.copyApprover,
    FormListingPreferences: user.FormListingPreferences || defaultFormListingPreferences,
    FormNewPreferences: user.FormNewPreferences || defaultFormNewPreferences,
  };
}

export async function GetUserDb() {
  return await db.user.get(1);
}

export async function UpdateUserDb(userdb) {
  await db.user.update(1, userdb);
  let ui = { username: userdb.Username };
  await db.ui.update(1, ui);
}

export async function SaveUserDb(userdb) {
  await db.user.put(1, userdb);
}

export async function GetDigitalId() {
  let userdb = await GetUserDb();
  if (!userdb.hasOwnProperty("digitalId")) return "";
  return userdb.digitalId;
}

export async function GetDigitalIdInfo() {
  let userdb = await GetUserDb();
  if (!userdb.hasOwnProperty("locationInfo")) userdb.locationInfo = "";
  if (!userdb.hasOwnProperty("reason")) userdb.reason = "";
  return userdb;
}

export async function SaveDigitalIdInfo(digitalIdInfo) {
  await db.user.update(1, digitalIdInfo);
}

export async function AddUpdateRecipient(recipient) {
  let userdb = await GetUserDb();
  let recipientsDb = userdb.Recipients ? { ...userdb.Recipients } : {};

  let newRecipients = [];
  if (recipientsDb.recipients) {
    newRecipients = [...recipientsDb.recipients.filter(x => x.id !== recipient.id), recipient];
  }
  else {
    newRecipients = [recipient];
  }

  userdb.Recipients = {
    lastUpdated: new Date().toISOString(),
    recipients: newRecipients
  }

  await UpdateUserDb(userdb);

  return newRecipients;
}

export async function RemoveRecipient(recipient) {
  let userdb = await GetUserDb();
  let recipientsDb = userdb.Recipients ? { ...userdb.Recipients } : {};

  let newRecipients = [];
  if (recipientsDb.recipients) {
    newRecipients = [...recipientsDb.recipients.filter(x => x.id !== recipient.id)];
  }

  userdb.Recipients = {
    lastUpdated: new Date().toISOString(),
    recipients: newRecipients
  }

  await UpdateUserDb(userdb);

  return newRecipients;
}

const setAdditionalEmailRecipients = (userDb) => {
  const recipientsDb = userDb.Recipients || {};
  const emails = recipientsDb.recipients || [];
  return {
    key: "AdditionalEmailRecipients",
    data: JSON.stringify(emails),
    lastUpdated: recipientsDb.lastUpdated,
  };
};

const setFormListingPreferences = (userDb) => {
  const formListingPreferences = {
    SortByValue: userDb.FormListingPreferences?.SortByValue || "Date",
  }
  const dateTime = new Date().toISOString();
  return {
    key: "FormListingPreferences",
    data: JSON.stringify(formListingPreferences),
    lastUpdated: formListingPreferences.LastUpdated || dateTime,
  };
};

const setFormNewPreferences = (userDb) => {
  const formNewPreferences = {
    LastZipCodeUsed: userDb.FormNewPreferences?.LastZipCodeUsed || "",
    LastLocationType: userDb.FormNewPreferences?.LastLocationType || "geo",
  }
  const dateTime = new Date().toISOString();
  return {
    key: "FormNewPreferences",
    data: JSON.stringify(formNewPreferences),
    lastUpdated: dateTime,
  };
}

export async function getUserSyncData() {
  const userDb = await GetUserDb();
  let userData = [];

  userData.push(
    setAdditionalEmailRecipients(userDb));
  userData.push(
    setFormListingPreferences(userDb));
  userData.push(
    setFormNewPreferences(userDb));

  return userData;
}

export async function SetLastSyncDate(lastSyncDate) {
   await db.user.update(1, { lastSyncDate });
}

export async function StoreCopyApprover(copyApprover) {
  await db.user.update(1, { copyApprover });
}

export async function StoreLastLocation(lastLocation, lastLocationType) {
  const dateTime = new Date().toISOString();
  var preferences = { 
    "FormNewPreferences.LastZipCodeUsed": lastLocation, 
    "FormNewPreferences.LastLocationType": lastLocationType,
    "FormNewPreferences.LastUpdated": dateTime
  };
  await db.user.update(1, preferences);
}

export async function StoreLastFormListSortBy(sortByValue) {
  const dateTime = new Date().toISOString();
  var preferences = {
    "FormListingPreferences.SortByValue": sortByValue,
    "FormListingPreferences.LastUpdated": dateTime
  };
  await db.user.update(1, preferences);
}
