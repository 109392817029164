import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import { Provider as ReduxProvider } from "react-redux";
//import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { store } from "./redux/store";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./helpers/authConfig";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import HealthCheck from "./pages/HealthCheck";

if (process.env.NODE_ENV !== "production") {
   // eslint-disable-next-line no-unused-vars
   window.enform = require("./helpers/devtools");
}

const msalInstance = new PublicClientApplication(msalConfig);

const queryClient = new QueryClient({
   defaultOptions: {
      queries: {
         retry: 1
         // staleTime: 1000 * 60 * 5
      }
   }
});

const pathname = window.location.pathname;

ReactDOM.render(
   <ReduxProvider store={store}>
      <React.StrictMode>
         <MsalProvider instance={msalInstance}>
            <QueryClientProvider client={queryClient}>
               {pathname === "/healthcheck" ? <HealthCheck /> : <App />}
            </QueryClientProvider>
         </MsalProvider>
      </React.StrictMode>
   </ReduxProvider>,
   document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
