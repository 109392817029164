import React, { useState, useEffect } from "react";
import "./Forms.css";
import FormsListing from "../components/FormsListing";
import FormNew from "../components/FormNew";
import Form from "../components/Form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as defActions from "../redux/actions/defActions";
import * as formActions from "../redux/actions/formActions";
import * as uiActions from "../redux/actions/uiActions";
import * as userActions from "../redux/actions/userActions";

function Forms({
   forms,
   defs,
   online,
   actions,
   sizeFactor,
   includeSubmitted,
   username,
   formListingPreferences,
   formNewPreferences,
}) {
   const [formPage, setFormPage] = useState("list");

   useEffect(() => {
      if (forms.formid !== null && formPage === "new") {
         setFormPage("form");
      }
      if (defs.init) {
         actions.loadDefsList();
      }
      if (forms.init) {
         actions.loadFormsList();
      }
   }, [forms.init, defs.init, actions, formPage, forms.formid]);

   useEffect(() => {

      if (formPage === 'list') {
         actions.setFormsInit(true);
      }

   }, [formPage, actions])

   const handleSaveEditForm = (formId, updateobject) => {
      actions.saveEditForm(formId, updateobject);
      actions.loadFormsList();
   };

   return (
      <div>
         {formPage === "list" ? (
            <FormsListing
               forms={forms}
               setFormId={actions.setFormId}
               setFormStatus={actions.setFormStatus}
               online={online}
               setFormPage={setFormPage}
               sizeFactor={sizeFactor}
               setSubmitType={actions.setSubmitType}
               includeSubmitted={includeSubmitted}
               setIncludeSubmitted={actions.setIncludeSubmitted}
               saveEditForm={handleSaveEditForm}
               username={username}
               setFormListSortBy={actions.setFormListSortBy}
               formListingPreferences={formListingPreferences}
            />
         ) : null}
         {formPage === "form" && forms.formid !== null ? (
            <Form
               form={forms.form}
               groupId={forms.groupId}
               setGroupId={actions.setGroupId}
               groupAdd={actions.groupAdd}
               groupDelete={actions.groupDelete}
               setFormPage={setFormPage}
               sizeFactor={sizeFactor}
               online={online}
            />
         ) : null}
         {formPage === "form" && forms.formid === null ? (
            <FormNew
               defs={defs.list}
               createNewForm={actions.createNewForm}
               storeLastLocation={actions.setFormNewLastLocation}
               setFormPage={setFormPage}
               sizeFactor={sizeFactor}
               formNewPreferences={formNewPreferences}
            />
         ) : null}
      </div>
   );
}

function mapStateToProps(state) {
   return {
      forms: state.forms,
      defs: state.defs,
      online: state.ui.online,
      sizeFactor: state.ui.sizeFactor,
      includeSubmitted: state.ui.includeSubmitted,
      username: state.user.Username,
      formListingPreferences: state.user.FormListingPreferences,
      formNewPreferences: state.user.FormNewPreferences
   };
}

function mapDispatchToProps(dispatch) {
   return {
      actions: {
         loadDefsList: bindActionCreators(defActions.loadDefsList, dispatch),
         loadFormsList: bindActionCreators(formActions.loadFormsList, dispatch),
         setFormId: bindActionCreators(formActions.setFormId, dispatch),
         createNewForm: bindActionCreators(formActions.createNewForm, dispatch),
         setFormStatus: bindActionCreators(formActions.setFormStatus, dispatch),
         groupAdd: bindActionCreators(formActions.groupAdd, dispatch),
         groupDelete: bindActionCreators(formActions.groupDelete, dispatch),
         setGroupId: bindActionCreators(formActions.setGroupId, dispatch),
         saveEditForm: bindActionCreators(formActions.saveEditForm, dispatch),
         setFormsInit: bindActionCreators(formActions.setFormsInit, dispatch),
         setSubmitType: bindActionCreators(uiActions.setSubmitType, dispatch),
         setIncludeSubmitted: bindActionCreators(
            uiActions.setIncludeSubmitted,
            dispatch
         ),
         setFormListSortBy: bindActionCreators(userActions.setFormListingSortBy, dispatch),
         setFormNewLastLocation: bindActionCreators(userActions.setFormNewLocationOverride, dispatch),
      },
   };
}

export default connect(mapStateToProps, mapDispatchToProps)(Forms);
