import React, { useEffect, useRef } from "react";
import { TextArea } from "@progress/kendo-react-inputs";
import DataLabel from "./DataLabel";
import { isNullOrEmpty } from "../../helpers/utils";

function DataTextArea({
   dataId,
   editorId,
   dataDef,
   dataValue,
   handleOnChange,
   handleOnBlur,
   addRefElement,
   sizeFactor,
   formIsLocked,
}) {
   const ref = useRef(null);
   useEffect(() => {
      if (!isNullOrEmpty(ref.current)) addRefElement(dataId, ref.current);
   }, [addRefElement, dataId]);
   return (
      <>
         <DataLabel
            dataDef={dataDef}
            dataValue={dataValue}
            sizeFactor={sizeFactor}
         />
         <TextArea
            ref={ref}
            id={editorId}
            rows={4}
            autoSize={true}
            value={dataValue}
            style={{ width: "100%", fontSize: 1 * sizeFactor + "rem" }}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            disabled={dataDef.ReadOnly || formIsLocked}
            required={dataDef.Required}
         />
      </>
   );
}

export default DataTextArea;
