import React, { useEffect, useState } from "react";
import {
   Card,
   CardHeader,
   CardImage,
   CardBody,
} from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { ListView } from "@progress/kendo-react-listview";
import Safety from "../components/Safety";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as formActions from "../redux/actions/formActions";
import * as uiActions from "../redux/actions/uiActions";
import { formatUsername, formatString } from "../helpers/utils";
import "./Main.css";

function Main({ user, forms, actions, sizeFactor, online, releaseNotes }) {
   const [showInfo, setShowInfo] = useState(false);
   const [showReleaseNotes, setShowReleaseNotes] = useState(false);

   useEffect(() => {
      if (!forms.init) actions.setFormsInit(true);
      actions.purgeLogs(3);
   }, [actions, forms]);

   const reloadPage = () => {
      window.location.reload();
   };

   return (
      <div>
         <Card style={{ marginBottom: "5px" }}>
            <CardImage src="../images/enforms_enbridge_logo.png"></CardImage>
            <CardBody style={{ fontSize: 1.1 * sizeFactor + "rem" }}>
               <strong>User:</strong>{" "}
               {formatString(user.FullName) +
                  " (" +
                  formatUsername((user.Username || "").toLowerCase().indexOf("#ext#") > 0 ? user.Email : user.Username) +
                  ")"}{" "}
               <br />
               <strong>Version:</strong> {process.env.REACT_APP_ENFORM_VERSION}{" "}
               <br />
            </CardBody>
         </Card>
         <Card style={{ marginTop: "10px" }}>
            <CardHeader
               className="card-header"
               style={{ fontSize: 1.25 * sizeFactor + "rem" }}
            >
               <div onClick={() => setShowReleaseNotes(!showReleaseNotes)}>
                  <span
                     className={
                        showReleaseNotes
                           ? "k-icon k-i-arrow-chevron-down"
                           : "k-icon k-i-arrow-chevron-right"
                     }
                     style={{ marginTop: "-3px", paddingRight: "7px" }}
                  ></span>
                  Communication Center
               </div>
            </CardHeader>

            {showReleaseNotes && (
               <CardBody
                  style={{
                     fontSize: 1.15 * sizeFactor + "rem",
                  }}
               >
                  {!!releaseNotes && (
                     <div dangerouslySetInnerHTML={{ __html: releaseNotes }} />
                  )}

                  {!releaseNotes && (
                     <span>There are no communications.</span>
                  )}

               </CardBody>
            )}
         </Card>
         <Card style={{ marginTop: "10px" }}>
            <CardHeader
               className="card-header"
               style={{ fontSize: 1.25 * sizeFactor + "rem" }}
            >
               <div onClick={() => setShowInfo(!showInfo)}>
                  <span
                     className={
                        showInfo
                           ? "k-icon k-i-arrow-chevron-down"
                           : "k-icon k-i-arrow-chevron-right"
                     }
                     style={{ marginTop: "-3px", paddingRight: "7px" }}
                  ></span>
                  Info
               </div>
            </CardHeader>

            {showInfo && (
               <CardBody
                  style={{
                     fontSize: 1.15 * sizeFactor + "rem",
                  }}
               >
                  <strong>Role:</strong> {user.Roles} <br />
                  <strong>Projects:</strong>{" "}
                  {user.ProjectList.length === 0 ? (
                     ""
                  ) : user.ProjectList.length === 1 ? (
                     user.ProjectList[0]
                  ) : (
                     <ListView
                        data={user.ProjectList}
                        item={({ dataItem }) => {
                           return (
                              <li style={{ fontSize: 1 * sizeFactor + "rem" }}>
                                 {dataItem}
                              </li>
                           );
                        }}
                     />
                  )}{" "}
                  <br />
                  <strong>Email:</strong> {user.Email} <br />
                  <strong>Has Digital Id:</strong>{" "}
                  {user.HasDigitalId ? "Yes" : "No"} <br />
               </CardBody>
            )}
         </Card>

         <Safety style={{ marginTop: "10px" }} sizeFactor={sizeFactor} />
         <div style={{ margin: "10px 5px 5px 5px" }}>
            <Button
               icon="wrench"
               fillMode="flat"
               onClick={() => actions.setRoute("support")}
               style={{ fontSize: 1 * sizeFactor + "rem" }}
            >
               Contact the Support Team
            </Button>
         </div>
         <div
            style={{
               margin: "10px 5px 5px 5px",
               fontSize: 1 * sizeFactor + "rem",
            }}
         >
            <Button
               icon="columns"
               fillMode="flat"
               onClick={() => actions.setRoute("referencelibrary")}
               style={{ fontSize: 1 * sizeFactor + "rem" }}
            >
               Reference Library
            </Button>
         </div>
         <div style={{ margin: "40px 5px 5px 5px" }}>
            <Button
               fillMode="flat"
               icon="reload"
               disabled={!online}
               onClick={reloadPage}
               style={{ fontSize: 1 * sizeFactor + "rem" }}
            >
               Reload Page
            </Button>
         </div>
      </div >
   );
}

function mapStateToProps(state) {
   return {
      user: state.user,
      forms: state.forms,
      sizeFactor: state.ui.sizeFactor,
      online: state.ui.online,
      releaseNotes: state.ui.releaseNotes
   };
}

function mapDispatchToProps(dispatch) {
   return {
      actions: {
         setFormsInit: bindActionCreators(formActions.setFormsInit, dispatch),
         purgeLogs: bindActionCreators(uiActions.purgeLogs, dispatch),
         setRoute: bindActionCreators(uiActions.setRoute, dispatch),
      },
   };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
