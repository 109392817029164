import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import styles from "./SubmitOffline.module.css";

function SubmitOffline({ actions, sizeFactor }) {
   return (
      <>
         <div
            style={{
               display: "flex",
               justifyContent: "right",
               marginTop: "3px",
            }}
         >
            <Button
               icon="undo"
               onClick={() => actions.setRoute("forms")}
               style={{ fontSize: 1 * sizeFactor + "rem" }}
            >
               Back to Form Listing
            </Button>
         </div>
         <div
            className={styles.submitOfflineMessage}
            style={{ fontSize: 1.15 * sizeFactor + "rem" }}
         >
            A network connection is not detected.
            <br />
            <br />
            Please try again later.
         </div>
      </>
   );
}

export default SubmitOffline;
