/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./FormGroupBreadcrumbs.css";
import { BiHomeSmile } from "react-icons/bi";
import * as enums from "../helpers/enums";
import { isNullOrEmpty } from "../helpers/utils";

function FormGroupBreadcrumbs({
   form,
   groupId,
   setGroupId,
   groupBreadcrumbs,
   sizeFactor,
}) {
   function handleCrumbClick(crumbId) {
      setTimeout(() => setGroupId(form.Id, crumbId), 100);
   }

   let breadcrumblist = groupBreadcrumbs.map((tgroupid) => {
      const groupdef =
         tgroupid !== "root"
            ? form.def.GroupDefs[form.FormGroups[tgroupid].DefId]
            : null;
      let label;
      if (tgroupid === "root") {
         label = <BiHomeSmile />; //"root";
      } else {
         const tgroup = form.FormGroups[tgroupid];
         if (groupdef.Repeats && groupdef.Type === enums.GroupType.Group) {
            if (!isNullOrEmpty(tgroup.dataNameValue)) {
               label = tgroup.dataNameValue;
            } else {
               label = tgroup.BLabel;
            }
         } else {
            label = tgroup.BLabel;
         }
      }
      if (tgroupid === groupId) {
         return <li key={tgroupid}>{label}</li>;
      } else {
         return (
            <li key={tgroupid} onClick={() => handleCrumbClick(tgroupid)}>
               <a href="#">{label}</a>
            </li>
         );
      }
   });

   return (
      <div>
         <div className="enform-breadcrumb-list">
            <ul
               className="enform-breadcrumb"
               style={{ fontSize: 0.95 * sizeFactor + "rem" }}
            >
               {breadcrumblist}
            </ul>
         </div>
      </div>
   );
}

export default FormGroupBreadcrumbs;
