import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Button } from '@progress/kendo-react-buttons';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { getDocumentById } from '../api/queries';
import { loginRequest } from '../helpers/authConfig';
import { downloadBlobAsFileName } from '../helpers/utils';
import { IApiResponse } from '../types/IApiResponse';

interface IProps {
  comment: string,
  documentId: string,
  sizeFactor: number,
  isOnline: boolean
}

const ChangeRequestNotation = ({ comment, documentId, sizeFactor, isOnline }: IProps) => {

  const [accessToken, setAccessToken] = useState<string>();
  const [viewDoc, setViewDoc] = useState<boolean>(false);
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const downloadPdfStringAsBlob = async (pdfString: string) => {
    const r = await fetch(pdfString);
    const blob = await r.blob();
    downloadBlobAsFileName(blob, "document.pdf");
  }

  useQuery(['getAttachment', { documentId: documentId, accessToken }], getDocumentById,
    {
      enabled: (isOnline === true && isAuthenticated === true && viewDoc === true && accessToken !== undefined),
      onSuccess: async (response: IApiResponse<string>) => {
        setAccessToken(undefined);
        setViewDoc(false);

        if (response.Success) {
          await downloadPdfStringAsBlob(`data:application/pdf;base64,${response.Data}`);
        }
      },
      onError: (response: any) => {
        setAccessToken(undefined);
        setViewDoc(false);
      }
    }
  );

  const onViewDocumentClick = async () => {

    const tokenResponse = await instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]
    });

    setAccessToken(tokenResponse.accessToken);
    setViewDoc(true);
  }

  return (
    <>
      {(!!comment || !!documentId) &&
        <div style={{
          border: 'solid 1px #eee',
          marginBottom: 5,
          padding: 5,
          borderRadius: 4,
          backgroundColor: '#ffffb2',
          fontWeight: 600,
          fontStyle: "italic",
          fontSize: 1 * sizeFactor + "rem",
          display: 'grid',
          gap: 5
        }}>
          {!!comment && <div>{comment}</div>}
          {!!comment && !!documentId && <hr style={{ margin: 2 }} />}
          {!!documentId &&
            <div style={{
              textAlign: 'center',
            }}>
              <Button style={{
                fontSize: 1 * sizeFactor + "rem",
              }} disabled={!isOnline} onClick={onViewDocumentClick}>View Change Request Document</Button>
            </div>
          }
        </div>
      }</>
  );

}

export { ChangeRequestNotation };