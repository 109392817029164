import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

interface IProps {
  disabled: boolean;
  title: string;
  body: JSX.Element;
  onYes: () => void;
  onNo: () => void;
  onClose: () => void;
}
const AppYesNoDialog = ({ disabled, title, body, onYes, onNo, onClose }: IProps) => {
  return (
    <Dialog
      title={title}
      onClose={onClose}
      contentStyle={{ maxWidth: '90vw' }}
    >
      <div
        style={{
          margin: "25px",
          textAlign: "center",
        }}
      >
        {body}
      </div>
      <DialogActionsBar>
        <div style={{ display: 'grid', justifyContent: 'stretch', gridAutoFlow: 'column', gap: '10px' }}>
          <Button fillMode='solid'
            themeColor='secondary'
            onClick={onNo}
            disabled={disabled}
          >
            No
          </Button>
          <Button fillMode='solid'
            themeColor='secondary'
            onClick={onYes}
            disabled={disabled}
          >
            Yes
          </Button>
        </div>
      </DialogActionsBar>
    </Dialog>
  );

}

export { AppYesNoDialog };