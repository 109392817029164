import db from './db';

export interface IFormDefinitionDto {
  formId: number,
  formDefinitionId: number,
  formDefinition: IFormDefinition
}

interface IDataDefinition {
  Id: number,
  IsAttachmentInputType: boolean,
}

interface IDataDefinitionKey {
  [key: string]: IDataDefinition
}

interface IFormDefinition {
  DefId: number,
  DataDefs: IDataDefinitionKey,
  [key: string]: any
}

interface IFormData {
  DefId: number,
  fileSize?: number
}

const getFormDefinitionDtoFromDataPoint = async (formDataId: number): Promise<IFormDefinitionDto> => {
  const dataPoint = await db.table('formData').get(formDataId);
  const form = await db.table('forms').get(dataPoint.formId);
  const formDefinition = await db.table('defs').get(form.DefId);

  const response: IFormDefinitionDto = {
    formId: dataPoint.formId,
    formDefinitionId: formDefinition.Id,
    formDefinition: formDefinition
  };

  return response;
}

// get all form attachment data points based on current datapoint and sum attachment total size
const calculateFormAttachmentCurrentTotalSize = async (formDefinition: IFormDefinitionDto): Promise<number> => {

  const formDataDefinitionIds = Object.keys(formDefinition.formDefinition.DataDefs)

  const attachmentDataDefinitions = formDataDefinitionIds.reduce((result: number[], dataDefId: string) => {
    const dataDefinition = formDefinition.formDefinition.DataDefs[dataDefId];

    if (dataDefinition.IsAttachmentInputType) {
      result.push(dataDefinition.Id);
    }

    return result;
  }, []);

  const allFormData: IFormData[] = await db.table('formData').where({ formId: formDefinition.formId }).toArray();
  const attachmentFormData = allFormData.filter(x => attachmentDataDefinitions.indexOf(x.DefId) >= 0);

  const totalAttachmentSize = attachmentFormData.reduce((sum, dataPoint) => sum += (dataPoint.fileSize || 0), 0);

  return totalAttachmentSize;
}

export { getFormDefinitionDtoFromDataPoint, calculateFormAttachmentCurrentTotalSize };

