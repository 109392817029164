import db from "./db";
import * as enums from "../helpers/enums";
import { isNullOrEmpty, getDate } from "../helpers/utils";
import * as logger from "../helpers/logger";
import { UpdateReferences } from "../helpers/resolver";
import { GetGroupDataNameValue, FindValidationIdFromValue } from "./valHandler";
import { UpdateFormSaveData } from "./formHandler";
import {
   UpdateGroupDataNames,
   UpdateGroupTotals,
   UpdateHiddenGroups,
} from "./groupHandler";
import { GetNextDataId, SaveKeys } from "./keyHandler";
import { UpdateDataLast } from "./lastHandler";

export async function GetDataForForm(formId) {
   let formdata = {};
   await db.formData
      .where("formId")
      .equals(formId)
      .each(function (data) {
         formdata[data.Id] = data;
      });

   return formdata;
}

export async function SaveDataValue(dataId, value, form, groupId) {
   await logger.Trace(
      "formHandler:SaveDataValue",
      "dataId:" + dataId + ", value:" + value
   );
   const formGroup = form.FormGroups[groupId];

   let formdatadb = await db.formData.get(dataId);
   let datadef = form.def.DataDefs[formdatadb.DefId];

   // Check/transform value based on type
   if (datadef.InputType === enums.InputType.Date) {
      value = isNullOrEmpty(value) ? null : getDate(value);
   } else if (datadef.InputType === enums.InputType.Picture) {
      formdatadb.content = value;
      value = formdatadb.Value;
   }
   else if (datadef.InputType === enums.InputType.Attachment) {
      formdatadb.content = value.content;
      formdatadb.fileName = value.fileName;
      formdatadb.fileSize = value.fileSize;
      value = formdatadb.Value;
   }

   formdatadb.Value = value;
   if (formdatadb.State !== enums.State.New)
      formdatadb.State = enums.State.Update;

   await db.formData.put(formdatadb);

   UpdateFormSaveData(form.Id, dataId, datadef, value);
   UpdateDataLast(
      form,
      datadef,
      formGroup,
      form.def.GroupDefs[formGroup.DefId],
      datadef.Name,
      value
   );
   await UpdateReferences(form, formdatadb);
   UpdateGroupDataNames(form, formdatadb, value);
   UpdateHiddenGroups(form, formdatadb);

   const groupdata = await GetDataFromIds(formGroup.Data);

   UpdateGroupTotals(groupdata, form, groupId);

   return groupdata;
}

export async function GetDataAndList(form, groupId) {
   let object = {};
   object.data = await GetDataFromIds(form.FormGroups[groupId].Data);
   object.list = [];
   let keylist = [];
   const keys = Object.keys(object.data);
   for (let k = 0; k < keys.length; k++) {
      keylist.push({
         defid: object.data[keys[k]].DefId,
         id: object.data[keys[k]].Id,
      });
   }

   const groupdataids = form.def.GroupDefs[form.FormGroups[groupId].DefId].Data;
   for (let d = 0; d < groupdataids.length; d++) {
      const datadef = form.def.DataDefs[groupdataids[d]];
      const keyobj = keylist.find((item) => {
         return item.defid === datadef.Id;
      });

      if (!isNullOrEmpty(keyobj)) {
         object.list.push({ infoonly: false, id: keyobj.id });
      }
   }

   return object;
}

export async function GetDataFromIds(dataIds) {
   let dataobject = {};

   for (let d = 0; d < dataIds.length; d++) {
      let dataid = dataIds[d];
      const data = await db.formData.get(dataid);
      dataobject[dataid] = data;
   }
   return dataobject;
}

export async function CreateData(
   dataDefIds,
   dataName,
   form,
   def,
   groupId,
   groupName,
   ggDataNames,
   dataNameValue,
   autoListDataPoints = null
) {
   let dataids = [];
   let datanameid = 0;
   let datanamevalue = dataNameValue;

   const getDataValue = async (ddef) => {

      if (autoListDataPoints) {
         return autoListDataPoints[ddef.Id] || '';
      }

      if (ddef.Name === dataName && !isNullOrEmpty(datanamevalue)) {
         return await FindValidationIdFromValue(ddef.ValType, ddef.ValList, datanamevalue);
      }

      return getDefaultValue(ddef.DataType);
   }

   for (let d = 0; d < dataDefIds.length; d++) {
      const ddef = def.DataDefs[dataDefIds[d]];
      const ndataid = await GetNextDataId();
      let ndata = {
         Id: ndataid,
         DefId: ddef.Id,
         Value: await getDataValue(ddef),
         Hidden: false,
         InfoOnly: ddef.InfoOnly,
         State: enums.State.New,
         formId: form.Id,
         groupId: groupId,
         groupDataNameIds: [],
      };
      if (ddef.InputType === enums.InputType.Picture || ddef.InputType === enums.InputType.Attachment) {
         ndata.content = "";
      }
      if (!form.dataDefX.hasOwnProperty(ddef.Id)) form.dataDefX[ddef.Id] = [];
      form.dataDefX[ddef.Id].push({ dataId: ndataid, groupId: groupId });
      if (ddef.Name === dataName) {
         datanameid = ndataid;
         if (isNullOrEmpty(datanamevalue)) {
            datanamevalue = await GetGroupDataNameValue(ddef, ndata.Value);
         }
         ndata.groupDataNameIds.push(groupId);
      }
      for (let gg = 0; gg < ggDataNames.length; gg++) {
         const testname = groupName + "." + ddef.Name;
         if (testname === ggDataNames[gg].dataname) {
            ggDataNames[gg].dataid = ndataid;
            ggDataNames[gg].value = await GetGroupDataNameValue(
               ddef,
               ndata.Value
            );
            ndata.groupDataNameIds.push(ggDataNames[gg].id);
         }
      }
      dataids.push(ndataid);

      db.formData.put(ndata);
   }
   await SaveKeys();
   return [dataids, datanameid, datanamevalue];
}

function getDefaultValue(dataType) {
   switch (dataType) {
      case enums.DataType.Double:
      case enums.DataType.Integer:
         return "";

      case enums.DataType.Boolean:
         return false;

      case enums.DataType.DateTime:
         return null;

      default:
         return "";
   }
}