import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "@progress/kendo-react-buttons";

function Logout() {
   const { instance } = useMsal();

   const handleLogout = () => {
      instance.logoutRedirect({
         postLogoutRedirectUri: "/",
         mainWindowRedirectUri: "/",
      });
   };
   return (
      <Button
         icon="logout"
         style={{ fontSize: 0.95 * 1 + "rem" }}
         fillMode="link"
         onClick={handleLogout}
      >
         Log Out
      </Button>
   );
}

export default Logout;
