import { useState } from "react";
import {
   BsClipboard2Check,
   BsClipboard2CheckFill,
   BsClipboard2Minus,
   BsClipboard2MinusFill,
   BsClipboard2,
   BsClipboard2Fill,
   BsClipboard2X,
   BsClipboard2XFill
} from "react-icons/bs";
import {
   Toolbar,
   ToolbarItem,
   ToolbarSeparator,
   ToolbarSpacer,
   Button,
} from "@progress/kendo-react-buttons";
import "./FormGroupGroupItem.css";
import * as enums from "../helpers/enums";
import { isNullOrEmpty } from "../helpers/utils";
import { getGroupEditStatus } from "../helpers/groupUtils";

function FormGroupGroupItem({
   form,
   group,
   sequence,
   dataNameValue,
   groupDef,
   setGroupId,
   groupAdd,
   groupDelete,
   counts,
   sizeFactor,
   formIsLocked,
}) {

   const groupEditStatus = getGroupEditStatus(form, group.Id);

   const parentGroup = form.FormGroups[form.groupX[group.Id].parents[0]];

   let groups = [];
   if (!!parentGroup) {
      groups = parentGroup.Groups;
   }
   else {
      for (let x in form.groupX) {
         if (form.groupX[x].parents.length === 0) {
            groups.push(x);
         }
      }
   }

   let siblingCount = 0;
   if (groups.length > 0) {
      siblingCount = groups.reduce((count, gid) => {
         if (gid.toString() === group.Id.toString()) return count;

         if (form.FormGroups[gid].DefId === group.DefId) {
            count++;
         }

         return count;
      }, 0);
   }

   const menuIsOpen = groupDef.Repeats
      && group.Sequence === group.sequenceCount
      && (group.WasViewed === true || siblingCount > 0)
      &&
      (
         isNullOrEmpty(groupDef.RepeatMax)
         || groupDef.RepeatMax === 0
         || group.sequenceCount < groupDef.RepeatMax
      );

   const [displayMenu, setDisplayMenu] = useState(menuIsOpen);

   const toggleStyle = {
      color: groupDef.Repeats ? "black" : "lightgrey",
   };

   const toggleClass =
      groupDef.Repeats && displayMenu
         ? "k-icon k-i-arrow-chevron-down"
         : "k-icon k-i-arrow-chevron-left";

   const bottomStyle = {
      display: groupDef.Repeats && displayMenu ? "" : "none",
      marginTop: '10px'
   };


   let label = " " + group.Label;
   if (!isNullOrEmpty(dataNameValue)) {
      label += " : " + dataNameValue;
   } else if (groupDef.Repeats) {
      label += " : " + sequence;
   }

   const onGroupAdd = () => {
      groupAdd(group.Id, group.formId, false);
   };

   const onGroupCopy = () => {
      groupAdd(group.Id, group.formId, true);
   };

   const onGroupDelete = () => {
      groupDelete(group.Id);
   };

   let icon = null;
   let iconColor = '#ccc';

   if (groupEditStatus.isGroup === false) {

      if (groupEditStatus.isViewed === false) {
         iconColor = '#b1b0b0';
         icon = <BsClipboard2Fill size='24px' color={iconColor} />;
      }
      else if (groupEditStatus.isCompleted === true) {
         iconColor = '#679946';
         icon = <BsClipboard2CheckFill size='24px' color={iconColor} />;
      }
      else if (groupEditStatus.isRequired) {
         iconColor = '#dc4242';
         icon = <BsClipboard2XFill size='24px' color={iconColor} />;
      }
      else {
         iconColor = '#c86d30'; // blue
         icon = <BsClipboard2MinusFill size='24px' color={iconColor} />;
      }
   }

   if (groupEditStatus.isGroup) {

      if (groupEditStatus.isViewed === false) {
         iconColor = '#b1b0b0';
         icon = <BsClipboard2 size='24px' color={iconColor} />;
      }
      else if (groupEditStatus.isCompleted === true) {
         iconColor = '#679946';
         icon = <BsClipboard2Check size='24px' color={iconColor} />;
      }
      else if (groupEditStatus.isRequired) {
         iconColor = '#dc4242';
         icon = <BsClipboard2X size='24px' color={iconColor} />;
      }
      else {
         iconColor = '#c86d30';
         icon = <BsClipboard2Minus size='24px' color={iconColor} />;
      }


   }

   const showToggle = groupDef.Repeats && !formIsLocked;
   const isGroup = groupDef.Type === enums.GroupType.Group;

   return (
      <div
         data-group-id={group.Id}
         className="group-list-item"
         style={{
            backgroundColor: group.Highlight ? "#ffffb2" : "",
            fontSize: 1 * sizeFactor + "rem",
         }}
      >
         <div>
            <div
               style={{
                  display: "grid",
                  gridTemplateColumns: showToggle ? 'auto max-content 20px' : 'auto max-content',
                  alignItems: 'center',
                  gap: '10px'
               }}
            >
               <div onClick={() => setGroupId(group.formId, group.Id)}>
                  {icon}
                  {label}
               </div>

               <div>
                  {isGroup && (
                     <div style={{ fontSize: "small" }}>
                        {counts.total} group{counts.total !== 1 ? "s" : ""}
                     </div>
                  )}
                  {!isGroup && (
                     <div style={{ fontSize: "small", backgroundColor: iconColor, padding: '2px 8px', borderRadius: '5px', fontWeight: 'normal', color: '#fff' }}>
                        {`${counts.totalEntered} / ${counts.total}`}
                     </div>
                  )}
               </div>

               {showToggle && (
                  <div onClick={() => setDisplayMenu(!displayMenu)}>
                     <span className={toggleClass} style={toggleStyle}></span>
                  </div>
               )}

            </div>
         </div>
         {groupDef.Repeats && !formIsLocked ? (
            <div style={bottomStyle}>
               <Toolbar>
                  <ToolbarSpacer />
                  <ToolbarItem>
                     <Button
                        icon="add"
                        title="Add"
                        disabled={
                           groupDef.RepeatMax > 0 &&
                           group.sequenceCount >= groupDef.RepeatMax
                        }
                        onClick={onGroupAdd}
                        style={{ fontSize: 0.85 * sizeFactor + "rem" }}
                     >
                        Add
                     </Button>
                  </ToolbarItem>
                  <ToolbarSeparator />
                  <ToolbarItem>
                     <Button
                        icon="copy"
                        title="Copy"
                        disabled={
                           groupDef.RepeatMax > 0 &&
                           group.sequenceCount >= groupDef.RepeatMax
                        }
                        onClick={onGroupCopy}
                        style={{ fontSize: 0.85 * sizeFactor + "rem" }}
                     >
                        Copy
                     </Button>
                  </ToolbarItem>
                  <ToolbarSeparator />
                  <ToolbarItem>
                     <Button
                        icon="delete"
                        title="Delete"
                        disabled={sequence === 1 && group.sequenceCount === 1}
                        onClick={onGroupDelete}
                        style={{ fontSize: 0.85 * sizeFactor + "rem" }}
                     >
                        Delete
                     </Button>
                  </ToolbarItem>
                  <ToolbarSpacer />
               </Toolbar>
            </div>
         ) : null}
      </div>
   );
}

export default FormGroupGroupItem;
