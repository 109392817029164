import * as types from "../actionTypes";
import initialState from "../initialState";

export default function valReducer(state = initialState.vals, action) {
   switch (action.type) {
      case types.LOAD_ALL_VALIDATIONS:
         return { ...action.newstate };
      default:
         return state;
   }
}
