import { Button } from '@progress/kendo-react-buttons';
import { InputChangeEvent, Input } from '@progress/kendo-react-inputs';
import { useEffect, useState } from 'react';
import { IRecipient } from "../../types/IRecipient";
import styles from './RecipientEditor.module.css';

interface IEditorProps {
  recipient: IRecipient,
  onChange: (event: InputChangeEvent) => void;
  onCancel: () => void;
  onSave: () => void;
}

interface IFieldError {
  [key: string]: string
}

const RecipientEditor = ({ recipient, onChange, onCancel, onSave }: IEditorProps) => {

  const [errors, setErrors] = useState<IFieldError>({});

  useEffect(() => {
    document.getElementById('recipient-save-button')?.scrollIntoView();
  }, [])

  const onSaveClick = () => {

    let newErrors: IFieldError = {};

    if (!recipient.name) {
      newErrors.name = 'Please enter a name'
    }

    if (!recipient.title) {
      newErrors.title = 'Please enter a title';
    }

    if (!recipient.email) {
      newErrors.email = 'Please enter an email address';
    }

    if (Object.keys(newErrors).length === 0) {
      onSave();
    }
    else {
      setErrors(newErrors);
    }

  };

  return (
    <div className={styles.editorContainer}>
      <div className={styles.editorHeader}>Recipient Detail</div>
      <div className={styles.formInput}>
        <label>Name</label>
        <Input placeholder='Name' name="name" value={recipient.name} onChange={onChange} />
        {!!errors.name && <span className={styles.error}>{errors.name}</span>}
      </div>
      <div className={styles.formInput}>
        <label>Title</label>
        <Input placeholder='Title' name="title" value={recipient.title} onChange={onChange} />
        {!!errors.title && <span className={styles.error}>{errors.title}</span>}
      </div>
      <div className={styles.formInput}>
        <label>Email</label>
        <Input placeholder='Email' name="email" value={recipient.email} onChange={onChange} />
        {!!errors.email && <span className={styles.error}>{errors.email}</span>}
      </div>
      <div className={styles.actionButtons}>
        <Button fillMode='outline' icon="cancel" size='small' onClick={onCancel}>Cancel</Button>
        <Button id='recipient-save-button' fillMode='outline' icon="save" size='small' onClick={onSaveClick}>Save</Button>
      </div>
    </div>
  );
}

export { RecipientEditor };