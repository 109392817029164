import { useState, useEffect } from "react";
import { filterBy } from "@progress/kendo-data-query";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { IUserLast } from "../types/IUserLast";
import { loginRequest } from '../helpers/authConfig';
import { useMsal } from '@azure/msal-react';
import { getUserLasts } from "../api/queries";
import { useQuery } from '@tanstack/react-query';
import { Loader } from "@progress/kendo-react-indicators";
import "./FormUserLasts.css";

interface IFormUserLastsProps {
    formDefId: number;
    workOrderId: number;
    sizeFactor: number;
    setPreviousFormData: (value: IUserLast | null) => void;
}

const FormUserLasts = ({
    formDefId,
    workOrderId,
    sizeFactor,
    setPreviousFormData,
}: IFormUserLastsProps) => {
    const {instance, accounts} = useMsal();
    const [accessToken, setAccessToken] = useState<string>();
    const [data, setData] = useState<IUserLast[]>([]);
    const [filteredData, setFilteredData] = useState<IUserLast[]>([]);
    const [value, setValue] = useState<IUserLast | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        const go = async () => {
            const tokenResponse = await instance
                .acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0],
                });
            setAccessToken(tokenResponse.accessToken);
        }
        if (accessToken === undefined && accounts.length > 0) go();
    }, [accessToken, accounts, data, instance]);

    const getUserLastsQuery = useQuery(['getUserLasts', { formDefId, workOrderId, accessToken }], getUserLasts, {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: (accessToken !== undefined && accounts.length > 0),
        onSuccess: (response: IUserLast[]) => {
            const getFormattedDate = (dateInput: Date | null) => {
                if (dateInput === null) return "";
                var date = new Date(dateInput);
                return " - " + (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
            }
            function sortFunction (a: IUserLast, b: IUserLast) {
                if (a.LastUpdated === null) return -1;
                if (b.LastUpdated === null) return 1;
                return new Date(b.LastUpdated).getTime() - new Date(a.LastUpdated).getTime();
            }
            response.sort(sortFunction);
            response.forEach((userLast) => {
                userLast.Label = `${userLast.LastName}, ${userLast.FirstName}${getFormattedDate(userLast.LastUpdated)}`;
            });
            setData(response);
            setFilteredData(response);
            setValue(null);
            setErrorMessage(null);
        },
        onError: () => {
            setData([]);
            setFilteredData([]);
            setValue(null);
            setErrorMessage("Error getting previously submitted forms");
        }
    });

    const handleChange = (event: any) => {
        if (!data.includes(event.target.value) || event.target.value === undefined || event.target.value === null) {
            setValue(null);
            setPreviousFormData(null);
        }
        else {
            setValue(event.target.value);
            setPreviousFormData(event.target.value);
        }
        setFilteredData(data);
    };

    const filterChange = (event: any) => {
        if (data === undefined) return;
        setFilteredData(filterBy(data, event.filter));
    };

    // #region Render

    if (accessToken === undefined || accounts.length === 0) {
        return <div style={{color: "red"}}>Please sign in to use this field</div>;
    }

    if (errorMessage !== null) {
        return <div style={{color: "red"}}>{errorMessage}</div>;
    }

    if (data.length === 0 && !getUserLastsQuery.isFetching) {
        return <div style={{color: "red"}}>No previously submitted forms were found</div>;
    }

    if (getUserLastsQuery.isFetching) {
        return <Loader themeColor="info" />;
    }

    return (
        <ComboBox
            id="form-user-lasts-combo-box"
            value={value}
            data={filteredData}
            onChange={handleChange}
            filterable={true}
            onFilterChange={filterChange}
            onClose={() => setFilteredData(data)}
            textField="Label"
            dataItemKey="Id"
            placeholder="Optional"
            style={{
                width: "90%",
                fontSize: 0.9 * sizeFactor + "rem",
                border: "1px solid lightgrey",
                color: "#212529"
            }} />
    );

    // #endregion Render
}

export default FormUserLasts;
