import React, { useRef, useEffect } from "react";
import { Checkbox } from "@progress/kendo-react-inputs";
import { isNullOrEmpty } from "../../helpers/utils";
import DataNote from "./DataNote";

function DataCheckBox({
   dataId,
   editorId,
   dataDef,
   dataValue,
   handleOnChange,
   handleOnBlur,
   addRefElement,
   sizeFactor,
   formIsLocked,
}) {
   const anchor = useRef(null);
   const ref = useRef(null);

   useEffect(() => {
      if (!isNullOrEmpty(ref.current)) addRefElement(dataId, ref.current);
   }, [addRefElement, dataId]);

   const validateDataValue = (value) => {
      if (value === undefined || value === null || value === "") return false;
      return value;
   };

   return (
      <>
         <Checkbox
            ref={ref}
            id={editorId}
            checked={validateDataValue(dataValue)}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            disabled={dataDef.ReadOnly || formIsLocked}
            style={{
               verticalAlign: "text-top",
               border: "1px solid black",
            }}
         >
            <label
               style={{
                  fontSize: 1.05 * sizeFactor + "rem",
                  paddingLeft: "5px",
               }}
            >
               {dataDef.Label}
            </label>
         </Checkbox>
         {isNullOrEmpty(dataDef.Note) ? null : (
            <DataNote
               note={dataDef.Note}
               anchor={anchor}
               sizeFactor={sizeFactor}
            />
         )}
      </>
   );
}

export default DataCheckBox;
