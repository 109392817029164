import { useState, useEffect, cloneElement } from "react";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Card, CardHeader, CardBody } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";
import DefSelect from "./FormNewDefSelect";
import { isNullOrEmpty, getDate, formatString, isCanadianPostalCode, isUSZipCode } from "../helpers/utils";
import * as enums from "../helpers/enums";
import "./FormNew.css";
import { LocationInput } from "./LocationInput";
import { IFormNewPreferences } from "../types/IFormNewPreferences";
import { LocationType } from "../types/LocationType";
import FormUserLasts from "./FormUserLasts";
import { useOnlineDetector } from "../hooks/useOnlineDetector";
import { GetValidation } from "../db/valHandler";
import { UpdateDefsLast } from "../db/lastHandler";
import { IUserLast } from "../types/IUserLast";

const notifyPosition = {
  top: 300,
  left: "50%",
  transform: "translateX(-50%)",
};

const textColor = "#212529";

const findDef = (
  defs: any,
  workorder: any,
  defId: any) =>
{
  if (isNullOrEmpty(defs)) return null;
  if (isNullOrEmpty(workorder)) return null;
  if (isNullOrEmpty(defId)) return null;

  for (let w = 0; w < defs.length; w++) {
    const wo = defs[w];
    if (wo.data.id !== "workorder-" + workorder.id) continue;
    for (let f = 0; f < wo.items.length; f++) {
      const formtype = wo.items[f];
      for (let d = 0; d < formtype.items.length; d++) {
        const def = formtype.items[d];
        if (def.data.Id === defId) return def.data;
      }
    }
  }
};

interface IFormNewProps {
  defs: any,
  createNewForm: any,
  setFormPage: any,
  sizeFactor: any,
  storeLastLocation: any,
  formNewPreferences: IFormNewPreferences,
}

const FormNew = ({
  defs,
  createNewForm,
  setFormPage,
  sizeFactor,
  storeLastLocation,
  formNewPreferences}: IFormNewProps) => 
{
  const [workOrders, setWorkOrders] = useState<any>(null);
  const [workOrderOptions, setWorkOrderOptions] = useState<any>([]);
  const [filter, setFilter] = useState<any>('');
  const [newFormDefId, setNewFormDefId] = useState<any>(-1);
  const [newFormTitle, setNewFormTitle] = useState<string>("");
  const [newFormDate, setNewFormDate] = useState<any>(getDate());
  const [newFormWorkOrder, setNewFormWorkOrder] = useState<any>();
  const [geoLocation, setGeoLocation] = useState<string>("");
  const [locationOverride, setLocationOverride] = useState<string>(formNewPreferences.LastZipCodeUsed || '');
  const [locationTypeOverride, setLocationTypeOverride] = useState<LocationType>(formNewPreferences.LastLocationType);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [showDefSelect, setShowDefSelect] = useState<any>(false);
  const [allowUserLasts, setAllowUserLasts] = useState<boolean>(false);
  const [lastFormData, setLastFormData] = useState<IUserLast|null>(null);

  const { onlineState } = useOnlineDetector();

  let defButtonText;
  let defButtonTextColor;
  if (newFormDefId === -1) {
    defButtonText = isNullOrEmpty(newFormWorkOrder)
      ? "Select Work Order Before Form"
      : "Select Form";
    defButtonTextColor = "#9b9b9b";
  } else {
    const def = findDef(defs, newFormWorkOrder, newFormDefId);
    defButtonText =
      def === null
        ? "Form Not Found. " + newFormDefId
        : def.Name + ": " + def.Label;
    defButtonTextColor = textColor;
  }

  useEffect(() => {
    let isMounted = true;

    GetValidation(enums.ValType.DefinedLists, "WorkOrders").then(
      (response: any) => {
        if (isMounted) setWorkOrders(response);
      }
    );

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isNullOrEmpty(workOrders) && workOrderOptions.length === 0) {
      let wooptions = []

      wooptions = workOrders.keys.map((key: any) => {
        const wo = workOrders.records[key];
        return {
          id: wo.id,
          text: formatString(wo.Name),
        };
      });
      wooptions.sort((a: any, b: any) => {
        return a.text < b.text ? -1 : a.text === b.text ? 0 : 1;
      });

      setWorkOrderOptions(wooptions);

      if (isNullOrEmpty(newFormWorkOrder) && wooptions.length === 1) {
        setNewFormWorkOrder(wooptions[0]);
      }
    }
  }, [workOrders, workOrderOptions, newFormWorkOrder]);

  // Get all Work Order options from vals, then set AllowUserLasts
  useEffect(() => {
    if (isNullOrEmpty(newFormWorkOrder?.id) || newFormDefId <= 0) {
      setAllowUserLasts(false);
      return;
    }
    GetValidation(2, "WorkOrders").then((vals: any) => {
      const value = vals?.records[newFormWorkOrder?.id]?.AllowUsersLast;
      if (isNullOrEmpty(value)) { setAllowUserLasts(false); return; }
      setAllowUserLasts(value);
    });
  }, [newFormWorkOrder?.id, newFormDefId]);

  const handleCreateButton = (e: any) => {
    let nfdate = newFormDate + "T00:00:00";

    if (
      isNullOrEmpty(newFormDefId) ||
      newFormDefId === -1 ||
      isNullOrEmpty(newFormWorkOrder)
    ) {
      setErrorMsg("Form and Work Order must be populated.");
      return;
    }

    if (
      locationTypeOverride.match(/^(manual)$/) 
      && !isCanadianPostalCode(locationOverride)
      && !isUSZipCode(locationOverride)
    ) {
      setErrorMsg("A valid Zip/Postal code is required.");
      return;
    }

    if (newFormTitle.length > 100) {
      setErrorMsg("Form Title must be less than 100 characters.");
      return;
    }

    let location = geoLocation;
    if (locationTypeOverride === "manual") {
      location = locationOverride;
      storeLastLocation(location, locationTypeOverride);
    } else if (locationTypeOverride === "geo") {
      // Always keep the last manual location but update the location type
      storeLastLocation(formNewPreferences.LastZipCodeUsed, locationTypeOverride);
    }

    if (lastFormData !== null) {
      UpdateDefsLast(
        lastFormData.FormDefinitionName,
        lastFormData.WorkOrderId,
        lastFormData.Data);
    }

    createNewForm(newFormDefId, nfdate, newFormWorkOrder.id, location, newFormTitle);
  };

  const handleChange = (e: any) => {
    const newvalue = e.target.value;
    if (e.target.name === "newFormDefId") {
      setNewFormDefId(newvalue.id);
    } else if (e.target.name === "newFormDate") {
      setNewFormDate(newvalue);
    } else if (e.target.name === "newFormWorkOrder") {
      setNewFormDefId(-1);
      setNewFormWorkOrder(newvalue);
    } else if (e.target.name === "newFormTitle") {
      setNewFormTitle(newvalue);
    }
  };

  const onManualLocate = (newLocation: string) => {
    setLocationOverride(newLocation);
    setLocationTypeOverride("manual");
  }

  const onGeoLocate = (newLocation: string) => {
    setGeoLocation(newLocation);
    setLocationTypeOverride("geo");
  }

  const filterChange = (event: any) => {
    setFilter(event.filter.value);
  };

  const handleShowDefSelect = () => {
    if (!isNullOrEmpty(newFormWorkOrder)) {
      setShowDefSelect(true);
    }
  };

  const renderWorkOrderItem = (li: any, itemProps: any) => {
    const itemChild = (
      <span style={{ fontSize: 0.9 * sizeFactor + "rem" }}>
        {li.props.children}
      </span>
    );
    return cloneElement(li, li.props, itemChild);
  };

  const setPreviousFormData = (value: IUserLast|null) => {
    if (value == null || value.Data == null) { 
      setLastFormData(null);
      return;
    }
    setLastFormData(value);
  };

  let options = workOrderOptions;
  if (!isNullOrEmpty(filter)) {
    options = options.filter((x: any) => x.text.toLowerCase().indexOf(filter.toLowerCase()) >= 0);
  }

  return (
    <>
      <div className="formnew-back-button">
        <Button
          icon="undo"
          onClick={() => setFormPage("list")} 
          style={{ fontSize: 1 * sizeFactor + "rem" }}
        >
          Back to Form Listing
        </Button>
      </div>
      <Card>
        <CardHeader
          style={{
            fontWeight: "bold",
            backgroundColor: "gold",
            fontSize: 1 * sizeFactor + "rem",
          }}
        >
          New Form
        </CardHeader>
        <CardBody>
          <div className="formnew-container">
            <div>
              <Label style={{ fontSize: 0.95 * sizeFactor + "rem" }}>
                Select Work Order
              </Label>
              <ComboBox
                placeholder="Start typing to filter"
                name="newFormWorkOrder"
                filterable={true}
                onFilterChange={filterChange}
                onChange={handleChange}
                style={{
                  width: "90%",
                  fontSize: 0.9 * sizeFactor + "rem",
                  color: textColor
                }}
                data={options}
                itemRender={renderWorkOrderItem}
                textField="text"
                dataItemKey="id"
                className="formnew-element"
                value={newFormWorkOrder}
              />
            </div>
            <div>
              <Label style={{ fontSize: 0.95 * sizeFactor + "rem" }}>
                Select Form
              </Label>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: "90%",
                    border: "1px solid lightgrey",
                    padding: "5px",
                    marginTop: "5px",
                    marginBottom: "5px",
                    height: "calc(1.4285714286em + 10px)",
                    fontSize: 0.9 * sizeFactor + "rem",
                    borderRadius: "3px",
                    color: defButtonTextColor,
                  }}
                  onClick={handleShowDefSelect}
                >
                  {defButtonText}
                </div>
                <div
                  style={{
                    margin: "0 auto",
                    border: "1px solid lightgrey",
                    padding: "2px 5px 2px 5px",
                    marginTop: "5px",
                    marginBottom: "5px",
                    borderRadius: "3px",
                  }}
                  onClick={handleShowDefSelect}
                >
                  <span
                    className="k-icon k-i-zoom"
                    style={{ height: "100%" }}
                  ></span>
                </div>
              </div>
            </div>
            <div>
              <Label
                style={{
                  display: "block",
                  fontSize: 0.95 * sizeFactor + "rem",
                  paddingBottom: "3px",
                }}>
                Form Title
              </Label>
              <input
                id="formnew-title-input"
                type="text"
                name="newFormTitle"
                onChange={handleChange}
                className="formnew-element"
                placeholder="Optional"
                style={{
                  height: "calc(1.4285714286em + 10px)",
                  fontSize: 0.9 * sizeFactor + "rem",
                }}
              />
            </div>
            <div style={{ marginBottom: "5px" }}>
              <Label
                style={{
                  display: "block",
                  fontSize: 0.95 * sizeFactor + "rem",
                  paddingBottom: "3px",
                }}
              >
                Select Date
              </Label>
              <input
                type="date"
                name="newFormDate"
                onChange={handleChange}
                className="formnew-element"
                value={newFormDate}
                style={{
                  fontSize: 0.85 * sizeFactor + "rem",
                  borderRadius: "2px",
                  padding: "5px",
                  height: "calc(1.4285714286em + 10px)",
                  color: textColor,
                  backgroundColor: "white",
                  borderColor: "rgba(0, 0, 0, 0.08)",
                }}
              />
            </div>
            <LocationInput
              sizeFactor={sizeFactor}
              location={locationOverride}
              onManualLocate={onManualLocate}
              onGeoLocate={onGeoLocate}
              locationType={locationTypeOverride}
              setFormGeoLocation={setGeoLocation}
            />
            { newFormDefId > 0 && !isNullOrEmpty(newFormWorkOrder?.id) && allowUserLasts &&
              <div>
                <Label style={{
                  display: "block",
                  fontSize: 0.95 * sizeFactor + "rem",
                  paddingBottom: "3px",
                }}>
                  Select Previously Submitted Form
                </Label>
                { onlineState
                  ? (
                    <FormUserLasts
                      formDefId={newFormDefId}
                      workOrderId={newFormWorkOrder?.id}
                      sizeFactor={sizeFactor}
                      setPreviousFormData={setPreviousFormData}
                    />
                  )
                  : (
                    <div style={{ color: "red", fontSize: 0.95 * sizeFactor + "rem" }}>
                      Offline - cannot retrieve previously submitted forms
                    </div>
                  )
                }
              </div>
            }
          </div>
          <div className="formnew-button">
            <Button
              themeColor="primary"
              onClick={handleCreateButton}
              style={{ fontSize: 1 * sizeFactor + "rem" }}
            >
              Create New Form
            </Button>
          </div>
        </CardBody>
      </Card>
      {!isNullOrEmpty(errorMsg) ? (
        <div>
          <NotificationGroup style={notifyPosition}>
            <Notification
              type={{ style: "error", icon: true }}
              closable={true}
              onClose={() => setErrorMsg("")}
              style={{ fontSize: 1 * sizeFactor + "rem" }}
            >
              {errorMsg}
            </Notification>
          </NotificationGroup>
        </div>
      ) : null}
      {showDefSelect ? (
        <DefSelect
          defs={defs}
          setShowDefSelect={setShowDefSelect}
          newFormDefId={newFormDefId}
          setNewFormDefId={setNewFormDefId}
          sizeFactor={sizeFactor}
          newFormWorkOrder={newFormWorkOrder}
        />
      ) : null}
    </>
  );
}

export default FormNew;
