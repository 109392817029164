import React from "react";
import {
   AppBar,
   AppBarSection,
   AppBarSpacer,
} from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import * as enums from "../helpers/enums";
import FormGroup from "./FormGroup";
import { formatDate, isLocked } from "../helpers/utils";

import "./Form.css";

function Form({
   form,
   groupId,
   setGroupId,
   groupAdd,
   groupDelete,
   setFormPage,
   sizeFactor,
   online,
}) {
   return (
      <>
         <div className="enform-back-button">
            <Button
               icon="undo"
               onClick={() => setFormPage("list")}
               style={{ fontSize: 1 * sizeFactor + "rem" }}
            >
               Back to Form Listing
            </Button>
         </div>
         <AppBar
            className="form-title-bar"
            style={{ fontSize: 1 * sizeFactor + "rem" }}
         >
            <AppBarSection>{form.def.Name}</AppBarSection>
            <AppBarSpacer />
            <AppBarSection>{formatDate(form.ReportDate)}</AppBarSection>
            <AppBarSpacer />
            <AppBarSection>
               <span>{enums.Status[form.Status]}</span>
               {isLocked(form) ? (
                  <span
                     className="k-icon k-i-lock"
                     style={{ color: "red" }}
                  ></span>
               ) : null}
            </AppBarSection>
         </AppBar>
         <FormGroup
            form={form}
            groupAdd={groupAdd}
            groupDelete={groupDelete}
            groupId={groupId}
            setGroupId={setGroupId}
            sizeFactor={sizeFactor}
            online={online}
         />
      </>
   );
}

export default Form;
