import { isNullOrEmpty } from "./utils";
import * as enums from "./enums";
import { Jsep } from "jsep";
import * as logger from "./logger";

let jsep = null;
let tree;
let ptree;
let currentExpression;

export function parse(expression) {
   if (isNullOrEmpty(expression)) return null;

   currentExpression = expression;
   lazyLoadJsep();
   ptree = {};

   try {
      tree = jsep.parse(expression);
      parseNode(tree, ptree);
   } catch (err) {
      logger.Error("parser:parse", "Parse expression " + expression, err);
      return null;
   }

   return isNullOrEmpty(ptree) ? null : ptree;
}

function parseNode(node, newNode) {
   if (node.type === enums.jType.Member) {
      if (
         node.object.type === enums.jType.Identifier &&
         node.property.type === enums.jType.Identifier
      ) {
         const name = node.object.name + "." + node.property.name;
         addNode(newNode, name);
      } else if (
         node.object.type === enums.jType.Call &&
         node.property.type === enums.jType.Identifier
      ) {
         if (node.object.callee.type !== enums.jType.Identifier) {
            throw new Error("Unknown callee type. " + currentExpression);
         }
         const name = node.object.callee.name + "." + node.property.name;
         addNode(newNode, name);
         newNode.args = [];
         for (let a = 0; a < node.object.arguments.length; a++) {
            const anode = node.object.arguments[a];
            newNode.args.push({});
            parseNode(anode, newNode.args[newNode.args.length - 1]);
         }
      }
   } else if (node.type === enums.jType.Identifier) {
      const name = node.name;
      addNode(newNode, name);
   } else if (node.type === enums.jType.Literal) {
      newNode.type = enums.DefaultType.Literal;
      newNode.value = node.value;
   } else if (node.type === enums.jType.Binary) {
      newNode.type = enums.DefaultType.Operator;
      newNode.operator = node.operator;
      newNode.left = {};
      newNode.right = {};
      parseNode(node.left, newNode.left);
      parseNode(node.right, newNode.right);
   }
}

function addNode(newNode, name) {
   const [type, info] = getTypeAndInfo(name);
   newNode.type = type;
   if (type === enums.DefaultType.Literal) {
      newNode.value = name;
   } else {
      newNode.info = info;
   }
}

function getTypeAndInfo(name) {
   const identifier = name.charAt(0);
   switch (identifier) {
      case "$":
         return [enums.DefaultType.Action, getActionInfo(name)];

      case "@":
         return [enums.DefaultType.GroupData, getGroupDataInfo(name)];

      case "#":
         return [enums.DefaultType.Reference, getReferenceInfo(name)];

      default:
         return [enums.DefaultType.Literal, name];
   }
}

function getActionInfo(name) {
   switch (name.substring(1, name.length).toUpperCase()) {
      case "DATE":
         return enums.ActionType.Date;

      case "TIME":
         return enums.ActionType.Time;

      case "LAST":
         return enums.ActionType.Last;

      default:
         return enums.ActionType.Unknown;
   }
}

function getGroupDataInfo(name) {
   let parts = name.substring(1, name.length).split(".");
   const group = parts[0].toUpperCase();
   const data =
      parts.length > 1
         ? parts[1].charAt(0) === "@"
            ? parts[1]
            : parts[1].toUpperCase()
         : "";
   return {
      group: group,
      data: data,
      property: parts.length === 3 ? parts[2] : "",
      dataDefId: null,
   };
}

function getReferenceInfo(name) {
   let parts = name.substring(1, name.length).split(".");
   let reftype = null;
   let property = "";
   let definedlist = "";
   if (parts[0].toUpperCase() === "USER") {
      reftype = enums.ReferenceType.User;
      property = parts.length > 1 ? parts[1] : "";
   } else if (parts[0].toUpperCase() === "FORM") {
      reftype = enums.ReferenceType.Form;
      property = parts.length > 1 ? parts[1] : "";
   } else {
      reftype = enums.ReferenceType.DefinedList;
      definedlist = parts[0];
      property = parts.length > 1 ? parts[1] : "";
   }
   return {
      refType: reftype,
      property: property,
      defList: definedlist,
   };
}

function lazyLoadJsep() {
   if (jsep !== null) return;

   jsep = Jsep;
   jsep.addIdentifierChar("@");
   jsep.addIdentifierChar("#");
}
