import React from "react";
import { GiBurningSkull, GiBiohazard, GiDynamite } from "react-icons/gi";
import { FaPhoneSlash } from "react-icons/fa";
import { isNullOrEmpty } from "../helpers/utils";
import "./Safety.css";

function Safety({ style, sizeFactor }) {
   const usestyle = isNullOrEmpty(style) ? {} : style;
   return (
      <div style={usestyle} className="safety-container">
         <div
            className="safety-header"
            style={{ fontSize: 1.25 * sizeFactor + "rem" }}
         >
            <FaPhoneSlash style={{ fontSize: "xx-large" }} />
            <GiBiohazard style={{ fontSize: "xx-large" }} />
            <div>Safety First!</div>
            <GiBurningSkull style={{ fontSize: "xx-large" }} />
            <GiDynamite style={{ fontSize: "xx-large" }} />
         </div>
         <div
            className="safety-body"
            style={{ fontSize: 1.15 * sizeFactor + "rem" }}
         >
            Do not use this application in
            <br />
            Restricted & Hazardous Areas!
         </div>
      </div>
   );
}

export default Safety;
