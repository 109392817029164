import React, { useEffect, useState } from "react";
import {
   ListView,
   ListViewHeader,
   ListViewFooter,
} from "@progress/kendo-react-listview";
import { Button } from "@progress/kendo-react-buttons";
import { GetValidationRecords } from "../db/valHandler";
import { connect } from "react-redux";
import { useIsAuthenticated } from "@azure/msal-react";
import Login from "../components/Login";
import { isNullOrEmpty } from "../helpers/utils";

function ReferenceLibrary({ sizeFactor }) {
   const [items, setItems] = useState([]);
   const isAuthenticated = useIsAuthenticated();

   useEffect(() => {
      GetValidationRecords("WorkOrders").then(function (response) {
         setItems(response);
      });
   }, []);

   if (!isAuthenticated) {
      return <Login />;
   }

   const listHeader = () => {
      return (
         <ListViewHeader
            style={{
               color: "black",
               fontWeight: "bold",
               fontSize: 1.15 * sizeFactor + "rem",
               paddingLeft: "5px",
            }}
         >
            Reference Library
         </ListViewHeader>
      );
   };

   const listFooter = () => {
      return (
         <ListViewFooter
            style={{
               color: "lightgrey",
               fontSize: 0.95 * sizeFactor + "rem",
               paddingLeft: "5px",
            }}
         ></ListViewFooter>
      );
   };

   const handleOnClick = (name, url) => {
      window.open(url, name);
   };

   const listItem = (props) => {
      let item = props.dataItem;
      if (isNullOrEmpty(item.ReferenceLibraryUrl)) return null;

      return (
         <div style={{ padding: "10px" }}>
            <Button
               fillMode="flat"
               onClick={() =>
                  handleOnClick(item.Name, item.ReferenceLibraryUrl)
               }
            >
               {item.Name}
            </Button>
         </div>
      );
   };

   return (
      <div>
         <ListView
            style={{ width: "100%", padding: "5px" }}
            data={items}
            item={listItem}
            header={listHeader}
            footer={listFooter}
         />
      </div>
   );
}

function mapStateToProps(state) {
   return {
      sizeFactor: state.ui.sizeFactor,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      actions: {},
   };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferenceLibrary);
