import db from "../db/db";
import * as enums from "../helpers/enums";
import { isNullOrEmpty, getDate, getTime, fixDateTimeLabelFormat } from "../helpers/utils";
import { GetGroupsFromHidden, UpdateGroupDataReferencedName } from "../db/groupHandler";
import { GetUserDb } from "../db/userHandler";
import { GetGroupDataNameValue } from "../db/valHandler";

let vals = null;
let form = null;
let userdb = null;
let formdata = null;
let newform = false;
let updateCount = 0;
let currentData = null;
let lasts = [];
let checklast = false;

export async function SetDefaults(theForm, formData) {
   newform = true;
   form = theForm;
   formdata = formData;
   lasts = [];
   checklast = false;

   const datakeys = Object.keys(formData);
   let idref = [];
   for (let k = 0; k < datakeys.length; k++) {
      let data = formData[datakeys[k]];
      await evaluateData(data, idref);
   }
   checklast = false;

   const groupkeys = Object.keys(form.FormGroups);
   for (let g = 0; g < groupkeys.length; g++) {
      const group = form.FormGroups[groupkeys[g]];
      const groupdef = form.def.GroupDefs[group.DefId];
      if (!isNullOrEmpty(groupdef.Hidden)) {
         currentData = null;
         const hidden = await resolve(groupdef.hiddenComponents);
         if (hidden) {
            group.Hidden = true;
            const tgroupdb = { Hidden: true };
            await db.formGroups.update(group.Id, tgroupdb);
         }
      }
   }

   return lasts;
}

export async function GetFilterOnValue(theForm, dataId, components) {
   newform = false;
   form = theForm;
   currentData = await getData(dataId);
   return await resolve(components);
}

export async function GetGroupAutoList(theForm, node) {
   let list = [];
   if (
      node.type !== enums.DefaultType.Reference &&
      node.hasOwnProperty("info") &&
      node.info.refType !== enums.ReferenceType.DefinedList
   )
      return list;

   form = theForm;

   let key = "";
   if (node.hasOwnProperty("args") && node.args.length > 0) {
      currentData = null;
      key = await resolve(node.args[0]);
   }

   const deflist = await getDefinedList(node.info.defList);
   const check = !isNullOrEmpty(key) && !isNullOrEmpty(node.info.property);
   for (let i = 0; i < deflist.keys.length; i++) {
      const record = deflist.records[deflist.keys[i]];
      // eslint-disable-next-line eqeqeq
      if (!check || record[node.info.property] == key) {
         const blabel = record.hasOwnProperty("BLabel")
            ? record.BLabel
            : record.id;
         list.push({
            label: record.label,
            blabel: blabel,
            dataNameValue: null,
         });
      }
   }

   return list;
}

export async function UpdateReferences(theForm, data) {
   let datadef = theForm.def.DataDefs[data.DefId];
   if (
      datadef.maps.defaultReferences.length === 0 &&
      datadef.maps.hiddenReferences.length === 0
   ) return 0;

   newform = false;
   form = theForm;
   updateCount = 0;
   let idref = [];
   const checkGroupId = form.def.GroupDefs[form.FormGroups[data.groupId].DefId]
      .Repeats
      ? data.groupId
      : null;

   await evaluateReferences(data, idref, checkGroupId);

   return updateCount;
}

export async function EvaluateGroupHidden(theForm, data, node) {
   form = theForm;
   formdata = null;
   currentData = data;
   const returnvalue = await resolve(node);
   return returnvalue;
}

async function evaluateData(data, idRef) {
   currentData = data;
   checklast = true;
   let value = await resolve(
      form.def.DataDefs[data.DefId].maps.defaultComponents
   );

   if ((form.def.DataDefs[data.DefId].Default != null) && (form.def.DataDefs[data.DefId].DataType === enums.DataType.String)) {
      value = await fixDateTimeLabelFormat(value, false, false);
   };

   if (form.def.DataDefs[data.DefId].InfoOnly) {
      value = '';
   }

   checklast = false;
   let hidden = await resolve(
      form.def.DataDefs[data.DefId].maps.hiddenComponents
   );
   if (hidden === null) hidden = false;

   let save = false;
   if (value !== null && data.Value !== value) {
      value = transformValue(value, form.def.DataDefs[data.DefId]);
      data.Value = value;
      save = true;
      updateCount++;
   }

   if (data.Hidden !== hidden) {
      data.Hidden = hidden;
      save = true;
   }

   if (save) {
      if (data.State !== enums.State.New) data.State = enums.State.Update;
      await db.formData.put(data);

      await evaluateReferences(data, idRef);
      if (data.groupDataNameIds.length > 0) {
         await UpdateGroupDataReferencedName(form, data);
      };
   }
}

function transformValue(value, dataDef) {
   if (dataDef.DataType === enums.DataType.Boolean) {
      if (typeof value === "boolean") return value;
      if (typeof value === "string") {
         const lvalue = value.toLocaleLowerCase();
         if (["yes", "true", "1"].indexOf(lvalue) !== -1) return true;
         return false;
      }
   }
   return value;
}

async function evaluateReferences(data, idRef, checkGroupId = null) {
   if (idRef.indexOf(data.Id) !== -1) return;
   idRef.push(data.Id);

   let refarray = [...form.def.DataDefs[data.DefId].maps.defaultReferences];
   for (
      let h = 0;
      h < form.def.DataDefs[data.DefId].maps.hiddenReferences.length;
      h++
   ) {
      let val = form.def.DataDefs[data.DefId].maps.hiddenReferences[h];
      if (!refarray.includes(val)) refarray.push(val);
   }
   if (refarray.length === 0) return;
   for (let r = 0; r < refarray.length; r++) {
      const defid = refarray[r];
      for (let d = 0; d < form.dataDefX[defid].length; d++) {
         const defxitem = form.dataDefX[defid][d];
         if (checkGroupId !== null && checkGroupId !== defxitem.groupId)
            continue;
         if (defxitem.dataId !== data.Id) {
            let refdata = await getData(defxitem.dataId);
            await evaluateData(refdata, idRef);
         }
      }
   }
}

export async function resolve(node) {
   if (isNullOrEmpty(node)) return null;

   let returnvalue = null;
   if (node.type === enums.DefaultType.Literal) {
      returnvalue = node.value;
   } else if (node.type === enums.DefaultType.Action) {
      returnvalue = await resolveAction(node);
   } else if (node.type === enums.DefaultType.GroupData) {
      returnvalue = await resolveGroupData(node);
   } else if (node.type === enums.DefaultType.Reference) {
      returnvalue = await resolveReference(node);
   } else if (node.type === enums.DefaultType.Operator) {
      returnvalue = await resolveOperator(node);
   }

   return returnvalue;
}

export async function resolveAction(node) {
   switch (node.info) {
      case enums.ActionType.Date:
         return getDate();

      case enums.ActionType.Time:
         return getTime();

      case enums.ActionType.Last:
         if (checklast) {
            lasts.push({ id: currentData.Id, defId: currentData.DefId });
         }
         return await getLastValue();

      default:
         return null;
   }
}

async function resolveGroupData(node) {
   if (node.info.dataDefId === null) {
      if (node.info.data.charAt(0) === "@" && currentData !== null) {
         const group = form.FormGroups[currentData.groupId];
         const groupprop = node.info.data.split("@")[1];
         if (!group.hasOwnProperty(groupprop)) return null;
         return group[groupprop];
      } else {
         return null;
      }
   }

   if (!form.dataDefX.hasOwnProperty(node.info.dataDefId)) return null;

   const datadefx = form.dataDefX[node.info.dataDefId];
   if (datadefx === 0) return;

   let dataidx = null;
   if (currentData === null) {
      dataidx = 0;
   } else {
      let groupx = GetGroupsFromHidden(form.groupX, currentData.groupId);
      for (let d = 0; d < datadefx.length; d++) {
         if (groupx.indexOf(datadefx[d].groupId) !== -1) {
            dataidx = d;
            break;
         }
      }
   }
   if (dataidx === null) return;

   const dataid = form.dataDefX[node.info.dataDefId][dataidx].dataId;

   const componentdata = await getData(dataid);
   if (componentdata === null) return null;
   let value = componentdata.Value;
   if (form.def.DataDefs[componentdata.DefId].ValType !== 1) { return value; };
   let dataname = await GetGroupDataNameValue(form.def.DataDefs[componentdata.DefId], value);
   return value + "|" + dataname;
}

async function resolveReference(node) {
   if (node.info.refType === enums.ReferenceType.User) {
      const user = await getUser();
      if (!user.hasOwnProperty(node.info.property)) return null;
      return user[node.info.property];
   }

   if (node.info.refType === enums.ReferenceType.Form) {
      if (!form.hasOwnProperty(node.info.property)) return null;
      return form[node.info.property];
   }

   if (node.info.refType !== enums.ReferenceType.DefinedList) return null;
   if (!node.hasOwnProperty("args") || node.args.length === 0) return null;

   const keyvalue = await resolve(node.args[0]); // assume only one argument
   if (keyvalue === null) return null;
   return await getDefinedListPropertyValue(
      node.info.defList,
      keyvalue,
      node.info.property
   );
}

async function resolveOperator(node) {
   let leftvalue = await resolve(node.left);
   let rightvalue = await resolve(node.right);
   if ((leftvalue ?? "").toString().indexOf("|") >= 0) {
      leftvalue = leftvalue.split("|")[0];
   };
   if ((rightvalue ?? "").toString().indexOf("|") >= 0) {
      rightvalue = rightvalue.split("|")[0];
   };

   const operator = node.operator;
   if (enums.Operators.arithmetic.indexOf(operator) !== -1) {
      if (operator === "&") {
         const string1 =
            typeof leftvalue === "number" ? leftvalue.toString() : leftvalue;
         const string2 =
            typeof rightvalue === "number" ? rightvalue.toString() : rightvalue;
         return string1 + string2;
      }
      const number1 =
         typeof leftvalue === "number"
            ? leftvalue
            : isNaN(Number(leftvalue))
               ? 0
               : Number(leftvalue);
      const number2 =
         typeof rightvalue === "number"
            ? rightvalue
            : isNaN(Number(rightvalue))
               ? 0
               : Number(rightvalue);
      let newvalue = 0;
      switch (operator) {
         case "+":
            newvalue = number1 + number2;
            break;
         case "-":
            newvalue = number1 - number2;
            break;
         case "*":
            newvalue = number1 * number2;
            break;
         case "/":
            newvalue = number1 / number2;
            break;
         case "%":
            newvalue = number1 % number2;
            break;
         default:
            newvalue = null;
            break;
      }
      return newvalue;
   } else if (enums.Operators.comparison.indexOf(operator) !== -1) {
      let newvalue;
      switch (operator) {
         case "==":
            // eslint-disable-next-line eqeqeq
            newvalue = leftvalue == rightvalue;
            break;
         case "!=":
            // eslint-disable-next-line eqeqeq
            newvalue = leftvalue != rightvalue;
            break;
         case ">":
            newvalue = leftvalue > rightvalue;
            break;
         case "<":
            newvalue = leftvalue < rightvalue;
            break;
         case ">=":
            newvalue = leftvalue >= rightvalue;
            break;
         case "<=":
            newvalue = leftvalue <= rightvalue;
            break;
         default:
            newvalue = null;
            break;
      }
      return newvalue;
   } else if (enums.Operators.logical.indexOf(operator) !== -1) {
      let newvalue;
      switch (operator) {
         case "||":
            newvalue = leftvalue || rightvalue;
            break;
         case "&&":
            newvalue = leftvalue && rightvalue;
            break;
         default:
            newvalue = null;
            break;
      }
      return newvalue;
   }
   return null;
}

async function getLastValue() {
   const defslastdb = await db.defsLast.get(form.def.Name);
   if (defslastdb === undefined) {
      await db.defsLast.put({ id: form.def.Name });
      return null;
   }
   let lastvalue = null;
   if (defslastdb.hasOwnProperty(form.WorkOrderId)) {
      const group = form.FormGroups[currentData.groupId];
      const groupdef = form.def.GroupDefs[group.DefId];
      let groupDataName = groupdef.Name;
      if (groupdef.Repeats) {
         if (!isNullOrEmpty(group.dataNameValue)) {
            groupDataName +=
               "_" + group.dataNameValue.replace(/ /g, "").toUpperCase();
         }
      } else if (
         !isNullOrEmpty(groupdef.AutoList) &&
         !isNullOrEmpty(group.BLabel)
      ) {
         groupDataName += "_" + group.BLabel.replace(/ /g, "").toUpperCase();
      }
      groupDataName += "_" + form.def.DataDefs[currentData.DefId].Name;
      if (defslastdb[form.WorkOrderId].hasOwnProperty(groupDataName)) {
         lastvalue = defslastdb[form.WorkOrderId][groupDataName];
      }
   }
   return lastvalue;
}

async function getDefinedListPropertyValue(definedList, key, property) {
   const val = await getDefinedList(definedList);
   if (val === null) return null;
   if (key === null) return null;

   if (val.records.hasOwnProperty(key)) {
      if (val.records[key].hasOwnProperty(property)) {
         return val.records[key][property];
      };
   };

   for (let v = 0; v < val.keys.length; v++) {
      if ((val.records[val.keys[v]]["WorkOrderId"] === form.WorkOrderId.toString()) && (val.records[val.keys[v]]["Name"] === key)) {
         return val.records[val.keys[v]][property];
      };
   };

   return null;
}

async function getUser() {
   if (userdb !== null) return userdb;
   userdb = await GetUserDb();
   return userdb;
}

async function getDefinedList(definedList) {
   if (vals !== null && vals.hasOwnProperty(definedList))
      return vals[definedList];

   if (vals === null) vals = {};

   const valdb = await db.vals.get(definedList);
   vals[definedList] = valdb;

   return vals[definedList];
}

async function getData(dataId) {
   // formdata is set for New Forms
   if (newform && formdata !== null && formdata.hasOwnProperty(dataId))
      return formdata[dataId];

   const datadb = await db.formData.get(dataId);
   return isNullOrEmpty(datadb) ? null : datadb;
}
