import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import DataLabel from "./DataLabel";
import { isNullOrEmpty } from "../../helpers/utils";
import "./DataPhoto.css";
import * as logger from "../../helpers/logger";

function DataPhoto({
   formId,
   formDescription,
   dataId,
   editorId,
   dataDef,
   dataValue,
   handleOnChange,
   handleOnBlur,
   addRefElement,
   sizeFactor,
   formIsLocked,
}) {
   const photoref = useRef(null);
   const elRef = useRef(null);

   useEffect(() => {
      if (!isNullOrEmpty(elRef.current)) {
         addRefElement(dataId, elRef.current);
      }
   }, [addRefElement, dataId, elRef]);

   useEffect(() => {
      if (!isNullOrEmpty(dataValue)) {
         fetch(dataValue)
            .then((res) => res.blob())
            .then((blob) => {
               const file = new File([blob], "filename", { type: blob.type });
               displayImage(file);
            });
      }
   });

   const displayImage = (file) => {
      const preview = document.querySelector("#preview-" + dataId);
      while (preview.firstChild) {
         preview.removeChild(preview.firstChild);
      }

      const image = document.createElement("img");
      image.src = URL.createObjectURL(file);
      image.className = "data-photo";
      preview.appendChild(image);
   };

   const onHandleGetPhoto = () => {
      photoref.current.click();
   };

   const processImage = () => {
      let temp = photoref.current;
      if (temp.files.length === 0) return;

      const file = temp.files[0];

      resizeAndSaveImage(file);
   };

   const resizeAndSaveImage = (file) => {
      const resizeWidth = 700;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.name = file.name;
      reader.size = file.size;
      logger.System("dataPhoto:resizeAndSaveImage", `DataPhoto:load -- formId: ${formId} | form: ${formDescription} | dataId: ${dataId} | name: '${file.name}' | origSize: ${file.size}`);
      reader.onload = function (event) {
         var img = new Image();
         img.src = event.target.result; // result is base64-encoded Data URI
         img.size = event.target.size;
         img.onload = function (el) {
            let elem = document.createElement("canvas");
            if (el.target.width > resizeWidth) {
               let scaleFactor = resizeWidth / el.target.width;
               elem.width = resizeWidth;
               elem.height = el.target.height * scaleFactor;
            } else {
               elem.width = el.target.width;
               elem.height = el.target.height;
            }
            let ctx = elem.getContext("2d");
            ctx.drawImage(el.target, 0, 0, elem.width, elem.height);
            let srcEncoded = ctx.canvas.toDataURL(file.type, 1);
            logger.System("dataPhoto:resizeAndSaveImage", `DataPhoto:resize -- formId: ${formId} | form: ${formDescription} | dataId: ${dataId} | name: '${file.name}' | newSize: ${srcEncoded.length}`);
            handleOnChange(srcEncoded);
         };
      };
   };

   const onClearPhoto = () => {
      handleOnChange("");
      const preview = document.querySelector("#preview-" + dataId);
      while (preview.firstChild) {
         preview.removeChild(preview.firstChild);
      }
      const node = document.createElement("p");
      node.innerHTML = "No photo selected";
      preview.appendChild(node);
   };

   return (
      <div ref={elRef}>
         <DataLabel
            dataDef={dataDef}
            dataValue={dataValue}
            sizeFactor={sizeFactor}
         />
         <input
            ref={photoref}
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={processImage}
         />
         <Button
            style={{ fontSize: 1 * sizeFactor + "rem" }}
            onClick={onHandleGetPhoto}
            disabled={formIsLocked}
         >
            Get Photo
         </Button>
         {!isNullOrEmpty(dataValue) ? (
            <Button
               style={{ fontSize: 1 * sizeFactor + "rem", marginLeft: "5px" }}
               onClick={onClearPhoto}
               disabled={formIsLocked}
            >
               Clear Photo
            </Button>
         ) : null}
         <div
            id={"preview-" + dataId}
            style={{
               marginTop: "5px",
               padding: "5px",
               width: "100%",
               border: "1px solid lightgrey",
               borderRadius: "5px",
            }}
         >
            <p>No photo selected</p>
         </div>
      </div>
   );
}

export default DataPhoto;
