import React, { useState, useEffect } from "react";
import * as enums from "../helpers/enums";
import { isNullOrEmpty } from "../helpers/utils";
import { getTimeObject, getTime } from "../helpers/dateUtils";
import DataTextBox from "./FormDataTypes/DataTextBox";
import DataTextArea from "./FormDataTypes/DataTextArea";
import DataCheckBox from "./FormDataTypes/DataCheckBox";
import DataDate from "./FormDataTypes/DataDate";
import DataNumber from "./FormDataTypes/DataNumber";
import DataSelect from "./FormDataTypes/DataSelect";
import DataTime from "./FormDataTypes/DataTime";
import DataRadio from "./FormDataTypes/DataRadio";
import DataStation from "./FormDataTypes/DataStation";
import DataChainage from "./FormDataTypes/DataChainage";
import DataReadOnly from "./FormDataTypes/DataReadOnly";
import DataPhoto from "./FormDataTypes/DataPhoto";
import DataGeoLocation from "./FormDataTypes/DataGeoLocation";
import DataWeather from "./FormDataTypes/DataWeather";
import DataInfoOnlyText from "./FormDataTypes/DataInfoOnlyText";
import DataInfoOnlyPic from "./FormDataTypes/DataInfoOnlyPic";
import { GetFilterOnValue } from "../helpers/resolver";

import "./FormData.css";
import DataAttachment from "./FormDataTypes/DataAttachment";

function FormData({
   form,
   data,
   saveData,
   addRefElement,
   sizeFactor,
   formIsLocked,
   online,
}) {
   const datadef = form.def.DataDefs[data.DefId];
   const [dataValueChanged, setDataValueChanged] = useState(false);

   let stateDataValue;
   if (datadef.IsAttachmentInputType) {
      stateDataValue = {
         content: data.content,
         fileName: data.fileName,
         fileSize: data.fileSize,
         documentId: data.Value
      };
   }
   else if (data.hasOwnProperty("content")) {
      stateDataValue = data.content;
   }
   else {
      stateDataValue = data.Value;
   }

   const [dataValue, setDataValue] = useState(stateDataValue);
   const [filterOnValue, setFilterOnValue] = useState(null);

   const editorId = "data-item-" + data.Id;

   useEffect(() => {
      let isMounted = true;
      if (
         !isNullOrEmpty(datadef.FilterOnProperty) &&
         !isNullOrEmpty(datadef.FilterOnValue)
      ) {
         GetFilterOnValue(form, data.Id, datadef.maps.filterComponents).then(
            (response) => {
               let splitresponse = response.split("|")[1];
               if (isMounted) setFilterOnValue(splitresponse);
            }
         );
      }
      return () => {
         isMounted = false;
      };
   }, [
      datadef.FilterOnProperty,
      datadef.FilterOnValue,
      form,
      datadef.maps.filterComponents,
      data.Id,
   ]);

   const handleAttachmentChange = async (fileBytes, fileName, fileSize) => {
      await handleOnChange(
         {
            content: fileBytes,
            fileName,
            fileSize
         });
   };

   const handleOnChange = async (e) => {
      if (formIsLocked) return;
      let newvalue;
      switch (datadef.InputType) {
         case enums.InputType.Date:
            newvalue = e.target.value;
            break;

         case enums.InputType.Time:
            if (e instanceof Date) {
               let newtimestring = getTime(e, true, false);
               newvalue = getTimeObject(newtimestring, true);
            } else {
               newvalue = getTimeObject(e.target.value, true);
            }
            break;

         case enums.InputType.Text:
            newvalue = e.target.value;
            break;

         case enums.InputType.Number:
            newvalue = e.target.value;
            break;

         case enums.InputType.TextArea:
         case enums.InputType.CheckBox:
            newvalue = e.value;
            break;

         case enums.InputType.Radio:
            newvalue = e.value;
            break;

         case enums.InputType.Select:
            newvalue = e.value?.id;
            break;

         case enums.InputType.Station:
            newvalue = e.target.value;
            break;

         case enums.InputType.Chainage:
            newvalue = e.target.value;
            break;

         case enums.InputType.Picture:
         case enums.InputType.Attachment:
            newvalue = e;
            break;

         case enums.InputType.Weather:
         case enums.InputType.GeoLocation:
            newvalue = e;
            break;

         default:
            console.log(
               "Unsupported type: " + enums.InputType[datadef.InputType]
            );
            newvalue = e.value;
            break;
      }

      setDataValue(newvalue);

      if (
         datadef.InputType === enums.InputType.Radio ||
         datadef.InputType === enums.InputType.CheckBox ||
         datadef.InputType === enums.InputType.Station ||
         datadef.InputType === enums.InputType.Chainage ||
         datadef.InputType === enums.InputType.Select ||
         datadef.InputType === enums.InputType.Picture ||
         datadef.InputType === enums.InputType.GeoLocation ||
         datadef.InputType === enums.InputType.Weather ||
         datadef.InputType === enums.InputType.Attachment ||
         (e instanceof Date && datadef.InputType === enums.InputType.Time)
      ) {
         await saveData(data.Id, newvalue);
      } else {
         setDataValueChanged(true);
      }
   };

   const handleOnBlur = async (e) => {
      if (formIsLocked) return;
      if (dataValueChanged) {
         await saveData(data.Id, dataValue);
         setDataValueChanged(false);
      }
   };

   return (
      <div
         className="form-data-item"
         key={"parent-data-item-" + data.Id}
         style={{
            backgroundColor: data.Highlight ? "#ffffb2" : "",
            display: data.Hidden ? "none" : "",
         }}
      >
         {datadef.InfoOnly &&
            <>
               {datadef.InputType !== enums.InputType.Picture &&
                  <DataInfoOnlyText dataDef={datadef} />}

               {datadef.InputType === enums.InputType.Picture &&
                  <DataInfoOnlyPic dataDef={datadef} />}
            </>
         }

         {datadef.ReadOnly &&
            <DataReadOnly
               dataId={data.Id}
               editorId={editorId}
               dataDef={datadef}
               dataValue={dataValue}
               addRefElement={addRefElement}
               sizeFactor={sizeFactor}
            />
         }

         {!datadef.InfoOnly && !datadef.ReadOnly &&
            <>
               {datadef.InputType === enums.InputType.CheckBox &&
                  <DataCheckBox
                     dataId={data.Id}
                     editorId={editorId}
                     dataDef={datadef}
                     dataValue={dataValue}
                     handleOnChange={handleOnChange}
                     handleOnBlur={handleOnBlur}
                     addRefElement={addRefElement}
                     sizeFactor={sizeFactor}
                     formIsLocked={formIsLocked}
                  />}

               {datadef.InputType === enums.InputType.Radio &&
                  <DataRadio
                     dataId={data.Id}
                     editorId={editorId}
                     dataDef={datadef}
                     dataValue={dataValue}
                     handleOnChange={handleOnChange}
                     handleOnBlur={handleOnBlur}
                     addRefElement={addRefElement}
                     sizeFactor={sizeFactor}
                     formIsLocked={formIsLocked}
                  />
               }

               {datadef.InputType === enums.InputType.Date &&
                  <DataDate
                     dataId={data.Id}
                     editorId={editorId}
                     dataDef={datadef}
                     dataValue={dataValue}
                     handleOnChange={handleOnChange}
                     handleOnBlur={handleOnBlur}
                     addRefElement={addRefElement}
                     sizeFactor={sizeFactor}
                     formIsLocked={formIsLocked}
                  />}

               {datadef.InputType === enums.InputType.Time &&
                  <DataTime
                     dataId={data.Id}
                     editorId={editorId}
                     dataDef={datadef}
                     dataValue={dataValue}
                     handleOnChange={handleOnChange}
                     handleOnBlur={handleOnBlur}
                     addRefElement={addRefElement}
                     sizeFactor={sizeFactor}
                     formIsLocked={formIsLocked}
                  />}

               {datadef.InputType === enums.InputType.Number &&
                  <DataNumber
                     dataId={data.Id}
                     editorId={editorId}
                     dataDef={datadef}
                     dataValue={dataValue}
                     handleOnChange={handleOnChange}
                     handleOnBlur={handleOnBlur}
                     addRefElement={addRefElement}
                     sizeFactor={sizeFactor}
                     formIsLocked={formIsLocked}
                  />}

               {datadef.InputType === enums.InputType.Select &&
                  <DataSelect
                     dataId={data.Id}
                     editorId={editorId}
                     dataDef={datadef}
                     dataValue={dataValue}
                     handleOnChange={handleOnChange}
                     handleOnBlur={handleOnBlur}
                     filterOnValue={filterOnValue}
                     workOrderId={form.WorkOrderId.toString()}
                     addRefElement={addRefElement}
                     sizeFactor={sizeFactor}
                     formIsLocked={formIsLocked}
                  />
               }

               {datadef.InputType === enums.InputType.TextArea &&
                  <DataTextArea
                     dataId={data.Id}
                     editorId={editorId}
                     dataDef={datadef}
                     dataValue={dataValue}
                     handleOnChange={handleOnChange}
                     handleOnBlur={handleOnBlur}
                     addRefElement={addRefElement}
                     sizeFactor={sizeFactor}
                     formIsLocked={formIsLocked}
                  />}

               {datadef.InputType === enums.InputType.Station &&
                  <DataStation
                     dataId={data.Id}
                     editorId={editorId}
                     dataDef={datadef}
                     dataValue={dataValue}
                     handleOnChange={handleOnChange}
                     handleOnBlur={handleOnBlur}
                     addRefElement={addRefElement}
                     sizeFactor={sizeFactor}
                     formIsLocked={formIsLocked}
                  />}

               {datadef.InputType === enums.InputType.Chainage &&
                  <DataChainage
                     dataId={data.Id}
                     editorId={editorId}
                     dataDef={datadef}
                     dataValue={dataValue}
                     handleOnChange={handleOnChange}
                     handleOnBlur={handleOnBlur}
                     addRefElement={addRefElement}
                     sizeFactor={sizeFactor}
                     formIsLocked={formIsLocked}
                  />}

               {datadef.InputType === enums.InputType.Picture &&
                  <DataPhoto
                     formId={form.Id}
                     formDescription={`${form.def.Name} - ${form.def.Label} (${form.ReportDate})`}
                     dataId={data.Id}
                     editorId={editorId}
                     dataDef={datadef}
                     dataValue={dataValue}
                     handleOnChange={handleOnChange}
                     handleOnBlur={handleOnBlur}
                     addRefElement={addRefElement}
                     sizeFactor={sizeFactor}
                     formIsLocked={formIsLocked}
                  />
               }

               {datadef.InputType === enums.InputType.GeoLocation &&
                  <DataGeoLocation
                     dataId={data.Id}
                     editorId={editorId}
                     dataDef={datadef}
                     dataValue={dataValue}
                     handleOnChange={handleOnChange}
                     handleOnBlur={handleOnBlur}
                     addRefElement={addRefElement}
                     sizeFactor={sizeFactor}
                     formIsLocked={formIsLocked}
                  />
               }

               {datadef.InputType === enums.InputType.Weather &&
                  <DataWeather
                     dataId={data.Id}
                     editorId={editorId}
                     dataDef={datadef}
                     dataValue={dataValue}
                     handleOnChange={handleOnChange}
                     addRefElement={addRefElement}
                     sizeFactor={sizeFactor}
                     formIsLocked={formIsLocked}
                     online={online}
                     location={form.Location}
                     reportdate={form.ReportDate}
                  />
               }

               {datadef.InputType === enums.InputType.Text &&
                  <DataTextBox
                     dataId={data.Id}
                     editorId={editorId}
                     dataDef={datadef}
                     dataValue={dataValue}
                     handleOnChange={handleOnChange}
                     handleOnBlur={handleOnBlur}
                     addRefElement={addRefElement}
                     sizeFactor={sizeFactor}
                     formIsLocked={formIsLocked}
                  />
               }

               {datadef.InputType === enums.InputType.Attachment &&
                  <DataAttachment
                     dataId={data.Id}
                     editorId={editorId}
                     dataDef={datadef}
                     dataValue={dataValue}
                     handleOnChange={handleAttachmentChange}
                     handleOnBlur={handleOnBlur}
                     addRefElement={addRefElement}
                     sizeFactor={sizeFactor}
                     formIsLocked={formIsLocked}
                     isOnline={online}
                  />
               }
            </>
         }
      </div>
   );
}

export default FormData;
