import { Input, InputChangeEvent, RadioButton, RadioButtonChangeEvent } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import { useEffect, useRef, useState } from "react";
import { isCanadianPostalCode, isUSZipCode } from "../helpers/utils";
import { GetGeoLocation } from "../helpers/location";
import { LocationType } from "../types/LocationType";

interface IProps {
  sizeFactor: number;
  location: string;
  onGeoLocate: (geoLocation: string) => void;
  onManualLocate: (value: string) => void;
  locationType: LocationType;
  setFormGeoLocation: (value: string) => void;
}

const LocationInput = ({ 
  sizeFactor,
  location,
  onManualLocate,
  onGeoLocate,
  locationType,
  setFormGeoLocation } : IProps) =>
{
  const locationRef = useRef<Input>();
  const [geoLocation, setGeoLocation] = useState<string>('');
  const [canGeoLocate, setCanGeoLocate] = useState<boolean>();
  const [geoLocationError, setGeoLocationError] = useState('');
  const [selectedLocation, setSelectedLocation] = useState<LocationType>(locationType);
  const [error, setError] = useState<string>('');

  useEffect(()=> {

    if (locationRef && locationRef.current && selectedLocation === 'manual') {
      locationRef.current.focus();
    }

  }, [selectedLocation]);

  useEffect(()=> {
    const testGeoLocate = async () => {
      try {
        const result = await GetGeoLocation();
        setCanGeoLocate(true);
        setGeoLocation(result);
        setFormGeoLocation(result);
        setGeoLocationError('');
      }
      catch (error: any) {
        console.log(error);
        setCanGeoLocate(false);
        setGeoLocationError("Device Location Unavailable");
        setSelectedLocation('manual');
      }
    };

    if (canGeoLocate === undefined)
    {
      testGeoLocate();
    }

  }, [canGeoLocate, setFormGeoLocation]);

  const onRadioChange = (e: RadioButtonChangeEvent) => {
    setSelectedLocation(e.value);
    if (e.value === 'geo') {
      onGeoLocate(geoLocation);
      setError("");
    }
    if (e.value === 'manual') {
      onManualLocate(location);
    }
  }

  const onChange = (e: InputChangeEvent) => {
    const newValue = e.value.toUpperCase();
    onManualLocate(newValue);
    
    if (!!newValue && !isCanadianPostalCode(newValue) && !isUSZipCode(newValue)) {
      setError("Invalid Zip/Postal Code");
    }
    else {
      setError("");
    }
  }

  return (
    <div>
      <Label style={{ fontSize: 0.95 * sizeFactor + "rem" }}>
        Location
      </Label>

      <div style={{ padding: '10px', display: 'grid', gap: '10px' }}>

        <div>
          <RadioButton
            disabled={canGeoLocate === false}
            id="geolocate"
            onChange={onRadioChange}
            name="locationSelector"
            value="geo"
            checked={selectedLocation === 'geo'}
          >
            <label
              htmlFor="geolocate"
              className={"k-radio-label"}
              style={{
                fontSize: 0.95 * sizeFactor + "rem",
                paddingLeft: 5,
              }}
            >
              Use Device Location
            </label>
            {geoLocationError && <Error style={{marginLeft: '30px'}}>{`[${geoLocationError}]`}</Error>}
          </RadioButton>
        </div>
        <div>
          <RadioButton
            id="manualLocate"
            onChange={onRadioChange}
            name="locationSelector"
            value="manual"
            checked={selectedLocation === 'manual'}
          >
            <label
              htmlFor="manualLocate"
              className={"k-radio-label"}
              style={{
                fontSize: 0.95 * sizeFactor + "rem",
                paddingLeft: 5,
              }}
            >
              Use Zip/Postal Code
            </label>
          </RadioButton>
        </div>

        <div
          style={{
            display: 'grid',
            gridAutoFlow: 'row',
            gap: '4px',
            justifyContent: 'left',
            alignItems: 'center',
            alignContent: 'center',
            marginLeft: '30px',
          }}>
          <Input
            ref={locationRef}
            disabled={selectedLocation === 'geo'}
            name="locationOverride"
            className="formnew-element"
            value={location}
            onChange={onChange}
            style={{ width: '150px', backgroundColor: `${selectedLocation === 'geo' ? '#eee' : 'inherit'}` }}
          />
          {error && <Error>{error}</Error>}
        </div>
      </div>
    </div>
  );
}

export { LocationInput }
