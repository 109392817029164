import db from "./db";
import * as enums from "../helpers/enums";
import { isNullOrEmpty } from "../helpers/utils";
import * as logger from "../helpers/logger";
import { GetDefsLastAsObject } from "../helpers/setupUtil";
import { GetDataForForm } from "./dataHandler";
import { GetForm } from "./formHandler";

export async function UpdateDataLast(
   form,
   datapointDefinition,
   dataPointFormGroup,
   dataPointGroupDefinition,
   dataPointName,
   dataValue
) {

   const formStatus = form.Status;
   const formGroups = form.FormGroups;
   const formDefinitionName = form.def.Name;
   const formWorkOrderId = form.WorkOrderId;

   const isAutoListRepeatingGroup =
      dataPointGroupDefinition
      && dataPointGroupDefinition.Repeats === true
      && dataPointGroupDefinition.AutoList === "$LAST";

   if (isAutoListRepeatingGroup) {

      await saveGroupAutoListData(
         formStatus,
         formDefinitionName,
         formWorkOrderId,
         formGroups,
         dataPointGroupDefinition.Id,
         dataPointFormGroup.groupId
      );

      return;
   }

   if (isNullOrEmpty(dataValue)) return;
   if (
      typeof datapointDefinition.Default !== "string" ||
      !datapointDefinition.Default.toUpperCase().startsWith("$LAST")
   )
      return;

   let defslastdb = await getDefsLast(formDefinitionName, formWorkOrderId);
   const lastDataName = getLastDataName(dataPointGroupDefinition, dataPointFormGroup, dataPointName);

   defslastdb[formWorkOrderId][lastDataName] = dataValue;
   defslastdb[formWorkOrderId].lastUpdated = new Date().toISOString();
   await db.defsLast.put(defslastdb);
}

export async function SaveAllLastsForForm(formId) {

   const form = await GetForm(formId);
   const formData = await GetDataForForm(formId);
   for (var dataId in formData) {
      const dataItem = formData[dataId];

      const datapointDefinition = form.def.DataDefs[dataItem.DefId];
      const dataPointFormGroup = form.FormGroups[dataItem.groupId];
      const dataPointGroupDef = form.def.GroupDefs[dataPointFormGroup.DefId];
      const dataPointName = form.def.DataDefs[dataItem.DefId].Name;
      const dataValue = dataItem.Value;

      await UpdateDataLast(form,
         datapointDefinition,
         dataPointFormGroup,
         dataPointGroupDef,
         dataPointName,
         dataValue
      );
   }

}

export async function UpdateDefaultsLast(lasts, form, formData) {
   const def = form.def;
   let defslastdb = await getDefsLast(def.Name, form.WorkOrderId);
   let anychange = false;
   for (let l = 0; l < lasts.length; l++) {
      const last = lasts[l];
      const data = formData[last.id];
      if (isNullOrEmpty(data.Value)) continue;

      const datadefname = def.DataDefs[data.DefId].Name;
      const group = form.FormGroups[data.groupId];
      const groupdef = def.GroupDefs[group.DefId];

      const lastname = getLastDataName(groupdef, group, datadefname);
      defslastdb[form.WorkOrderId][lastname] = data.Value;
      anychange = true;
   }
   if (anychange) {
      defslastdb[form.WorkOrderId].lastUpdated = new Date().toISOString();
      await db.defsLast.put(defslastdb);
   }
}

export async function saveGroupAutoListData(
   formStatus,
   formDefinitionName,
   formWorkOrderId,
   formGroups,
   groupDefinitionId,
   parentGroupId) {

   if (formStatus !== enums.Status.New) return;

   let defslastdb = await getDefsLast(formDefinitionName, formWorkOrderId);

   let autoList = defslastdb[formWorkOrderId].autoList || {};

   autoList[groupDefinitionId] = [];

   let groupIdKeys = [];
   if (parentGroupId === 0) {
      groupIdKeys = Object.keys(formGroups);
   }
   else {
      groupIdKeys = formGroups[parentGroupId].Groups;
   }

   let autoListGroups = groupIdKeys.reduce((result, groupId) => {

      result.push(formGroups[groupId]);
      return result;

   }, []);

   // get all instances of this group definition
   autoListGroups = autoListGroups.filter(x => x.DefId === groupDefinitionId);

   // if this group is in a containing group, only get its siblings
   if (parentGroupId !== 0) {
      autoListGroups = autoListGroups.filter(x => x.groupId === parentGroupId);
   }

   for (let i = 0; i < autoListGroups.length; i++) {

      let group = {};
      const autoListGroupId = autoListGroups[i].Id;
      for (let x = 0; x < formGroups[autoListGroupId].Data.length; x++) {
         const dataId = formGroups[autoListGroupId].Data[x];
         const datadb = await db.formData.get(dataId);
         group[datadb.DefId] = datadb.Value;
      }

      autoList[groupDefinitionId].push(group);
   }

   defslastdb[formWorkOrderId].autoList = autoList;
   defslastdb[formWorkOrderId].lastUpdated = new Date().toISOString();

   await db.defsLast.put(defslastdb);

   return;
}

export async function getDefsLast(formDefName, workOrderId) {
   let defslastdb = await db.defsLast.get(formDefName);
   if (!defslastdb.hasOwnProperty(workOrderId)) {
      defslastdb[workOrderId] = {};
   }
   return defslastdb;
}

function getLastDataName(groupDef, group, dataDefName) {
   let lastname = groupDef.Name;
   if (groupDef.Repeats) {
      if (!isNullOrEmpty(group.dataNameValue)) {
         lastname += "_" + group.dataNameValue.replace(/ /g, "").toUpperCase();
      }
   } else if (
      !isNullOrEmpty(groupDef.AutoList) &&
      !isNullOrEmpty(group.BLabel)
   ) {
      lastname += "_" + group.BLabel.replace(/ /g, "").toUpperCase();
   }
   lastname += "_" + dataDefName;

   return lastname;
}

export async function GetGroupAutoListLast(defName, workOrderId, groupDef) {
   let list = [];
   let defslastdb = await getDefsLast(defName, workOrderId);

   if (!defslastdb[workOrderId].autoList || !defslastdb[workOrderId].autoList[groupDef.Id]) {
      return [
         {
            label: groupDef.Label,
            blabel: groupDef.BLabel,
            dataNameValue: null,
            dataPoints: null
         },
      ];
   }

   let array = defslastdb[workOrderId].autoList[groupDef.Id];
   for (let a = 0; a < array.length; a++) {
      list.push({
         label: groupDef.Label,
         blabel: groupDef.BLabel,
         dataNameValue: null,
         dataPoints: array[a],
      });
   }

   return list;
}

export async function ResetDefsLast() {
   logger.Info("lastHandler:ResetDefsLast", "Start");
   db.defsLast.toCollection().delete();

   let names = [];
   await db.defs.toCollection().each(function (def) {
      names.push(def.Name);
   });

   for (let n = 0; n < names.length; n++) {
      await db.defsLast.put({ id: names[n] });
   }
}

export async function getLastsDataArray() {

   const lastsDef = await GetDefsLastAsObject();

   let lastsArray = [];

   const formDefinitionKeys = Object.keys(lastsDef);

   for (const formDefinitionName of formDefinitionKeys) {
      const result = getFormLastsDataObj(lastsDef, formDefinitionName);
      lastsArray = lastsArray.concat(result);
   };

   return lastsArray;
};

export function getFormLastsDataObj(lastsDef, formDefinitionName) {

   let lastsArray = [];

   const formLastData = lastsDef[formDefinitionName];

   const workOrderKeys = Object.keys(formLastData);

   for (const workOrderId of workOrderKeys) {

      if (isNaN(parseInt(workOrderId))) {
         continue;
      }

      const lastsDataObj = {
         formDefinitionName,
         workOrderId,
         lastUpdated: formLastData[workOrderId].lastUpdated ?? new Date().toISOString(),
         data: JSON.stringify(formLastData[workOrderId])
      };

      lastsArray.push(lastsDataObj);

   };

   return lastsArray;
}

export async function UpdateDefsLast(formDefinitionName, workOrderId, data) {
   var record = await db.defsLast.get(formDefinitionName);
   record[workOrderId] = JSON.parse(data);
   db.defsLast.update(formDefinitionName, record);
}
