import React, { useState, useEffect } from "react";
import * as denum from "../helpers/enums";
import { isLocked } from "../helpers/utils";
import FormGroupGroups from "./FormGroupGroups";
import FormGroupData from "./FormGroupData";
import FormGroupBreadcrumbs from "./FormGroupBreadcrumbs";

function FormGroup({
   form,
   groupAdd,
   groupDelete,
   groupId,
   setGroupId,
   sizeFactor,
   online,
}) {
   const [breadcrumbs, setBreadcrumbs] = useState(["root"]);
   const [formIsLocked] = useState(isLocked(form));
   useEffect(() => {
      if (groupId === "root") {
         if (breadcrumbs.length !== 1) {
            setBreadcrumbs(["root"]);
         }
      } else {
         let newbreadcrumbs = [];
         let found = false;
         for (let i = 0; i < breadcrumbs.length; i++) {
            const groupid = breadcrumbs[i];
            newbreadcrumbs.push(groupid);
            if (groupid === groupId) {
               found = true;
               break;
            }
         }
         if (!found) newbreadcrumbs.push(groupId);
         if (newbreadcrumbs.length !== breadcrumbs.length) {
            setBreadcrumbs(newbreadcrumbs);
         }
      }
   }, [groupId, breadcrumbs, form.Id, form.def]);
   const groupdef =
      groupId !== "root"
         ? form.def.GroupDefs[form.FormGroups[groupId].DefId]
         : null;
   const groupType =
      groupId === "root"
         ? "group"
         : groupdef.Type === denum.GroupType.Group
            ? "group"
            : groupdef.Type === denum.GroupType.Data
               ? "data"
               : null;

   return (
      <>
         <FormGroupBreadcrumbs
            form={form}
            groupId={groupId}
            setGroupId={setGroupId}
            groupBreadcrumbs={breadcrumbs}
            sizeFactor={sizeFactor}
         />
         {groupType === "group" ? (
            <FormGroupGroups
               form={form}
               groupId={groupId}
               setGroupId={setGroupId}
               groupAdd={groupAdd}
               groupDelete={groupDelete}
               sizeFactor={sizeFactor}
               formIsLocked={formIsLocked}
               isOnline={online}
            />
         ) : groupType === "data" ? (
            <FormGroupData
               form={form}
               groupId={groupId}
               setGroupId={setGroupId}
               sizeFactor={sizeFactor}
               formIsLocked={formIsLocked}
               online={online}
            />
         ) : null}
      </>
   );
}

export default FormGroup;
