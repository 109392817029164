import { getDate, getTime } from "./dateUtils";
import * as enums from "./enums";
export * from "./dateUtils";

export function normalizeUrl(path, endpoint) {
   if (path.endsWith("/")) {
      if (endpoint.startsWith("/")) {
         return path + endpoint.substr(1, endpoint.length - 1);
      } else {
         return path + endpoint;
      }
   } else {
      if (endpoint.startsWith("/")) {
         return path + endpoint;
      } else {
         return path + "/" + endpoint;
      }
   }
}

export function isNullOrEmpty(checkval) {
   if (checkval === null || checkval === undefined) return true;
   if (typeof checkval == "string" && checkval.trim().length === 0) return true;
   if (Array.isArray(checkval) && checkval.length === 0) return true;
   if (typeof checkval == "object") {
      if (checkval instanceof Date) return false;
      if (Object.keys(checkval).length === 0) return true;
   }
   return false;
}

export function formatStation(number) {
   if (isNullOrEmpty(number)) return "0+00";
   var tnumber = number.toString();
   switch (tnumber.length) {
      case 0:
         return "0+00";
      case 1:
         return "0+0" + tnumber;
      case 2:
         return "0+" + tnumber;
      default:
         return (
            tnumber.substr(0, tnumber.length - 2) +
            "+" +
            tnumber.substr(tnumber.length - 2)
         );
   }
}

export function unformatStation(station) {
   if (isNullOrEmpty(station) || station.indexOf("+") === -1) return station;
   const parts = station.split("+");
   let newnumber = parts[0] + parts[1];
   if (newnumber === "000") newnumber = "";
   return newnumber;
}

export function formatChainage(number) {
   if (isNullOrEmpty(number)) return "0+000";
   var tnumber = number.toString();
   switch (tnumber.length) {
      case 0:
         return "0+000";
      case 1:
         return "0+00" + tnumber;
      case 2:
         return "0+0" + tnumber;
      case 3:
         return "0+" + tnumber;
      default:
         return (
            tnumber.substr(0, tnumber.length - 3) +
            "+" +
            tnumber.substr(tnumber.length - 3)
         );
   }
}

export function unformatChainage(chainage) {
   if (isNullOrEmpty(chainage) || chainage.indexOf("+") === -1) return chainage;
   const parts = chainage.split("+");
   let newnumber = parts[0] + parts[1];
   if (newnumber === "0000") newnumber = "";
   return newnumber;
}

export function formatValue(dataDef, value) {
   if (isNullOrEmpty(value)) return "";

   if (
      dataDef.DataType === enums.DataType.DateTime &&
      dataDef.InputType === enums.InputType.Date
   )
      return getDate(value);

   if (
      dataDef.DataType === enums.DataType.DateTime &&
      dataDef.InputType === enums.InputType.Time
   )
      return getTime(value);

   if (dataDef.InputType === enums.InputType.Station)
      return formatStation(value);

   if (dataDef.InputType === enums.InputType.Number) return formatNumber(value);

   return value;
}

export function isAssigned(form) {
   if (isNullOrEmpty(form.AssignedTo)) return false;
   if (
      (form.CreatedBy.toLowerCase() !== form.AssignedTo.toLowerCase() || (form.assigned)) &&
      !isNullOrEmpty(form.AssignedTo)
   )
      return true;
   return false;
}

export function isLocked(form) {
   if (isAssigned(form)) return true;

   if (
      form.Status === enums.Status.New ||
      form.Status === enums.Status.ChangeRequest
   ) {
      return false;
   }
   return true;
}

export function getLocationObject(location) {
   if (isNullOrEmpty(location)) return null;
   if (!location.startsWith("lng:")) return null;

   const parts = location.split(",");
   if (parts.length < 2) return null;
   const lngparts = parts[0].split(":");
   if (lngparts.length < 2) return null;
   if (lngparts[0] !== "lng") return null;
   const latparts = parts[1].split(":");
   if (latparts.length < 2) return null;
   if (latparts[0] !== "lat") return null;

   return { lng: lngparts[1], lat: latparts[1] };
}

export function formatString(text) {
   if (isNullOrEmpty(text)) return "";
   return text;
}

export function formatNumber(num) {
   if (isNullOrEmpty(num)) return 0;
   if (isNaN(num)) return 0;
   return num;
}

export function formatUsername(username) {
   if (isNullOrEmpty(username)) return "";
   if (username.indexOf("@") === -1) return username;
   return username.split("@")[0];
}

export async function downloadPdfStringAsBlob(pdfString, fileName) {
   const r = await fetch(pdfString);
   const blob = await r.blob();
   downloadBlobAsFileName(blob, fileName || "file.pdf");
}

export function downloadBlobAsFileName(blob, fileName) {

   const link = document.createElement('a');
   link.href = URL.createObjectURL(blob);
   link.target = '_blank';
   link.download = fileName;
   document.body.appendChild(link);
   link.click();
   document.body.removeChild(link);
}

export const isCanadianPostalCode = (postalCode) => {
   const regex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/;
   return postalCode.match(regex) != null;
}

export const isUSZipCode = (postalCode) => {
   const regex = /^[0-9]{5}(?:-[0-9]{4})?$/;
   return postalCode.match(regex) != null;
}