import * as types from "../actionTypes";
import {
   AddUpdateRecipient,
   GetInitialUserState,
   RemoveRecipient,
   SetLastSyncDate,
   StoreCopyApprover,
   StoreLastLocation,
   StoreLastFormListSortBy,
} from "../../db/userHandler";
import { SyncDigitalId } from "../../api/apiUtils";

export function setInitialUserState() {
   return (dispatch) => {
      GetInitialUserState().then((newstate) => {
         dispatch({ type: types.SET_INITIAL_USER_STATE, newstate });
      });
   };
}

export function setDigitalId(accessToken, digitalId) {
   return (dispatch) => {
      SyncDigitalId(accessToken, digitalId).then((hasDigitalId) => {
         dispatch({ type: types.SET_DIGITAL_ID, hasDigitalId });
      });
   };
}

export function addUpdateRecipient(recipient) {
   return (dispatch) => {
      AddUpdateRecipient(recipient).then((recipients) => {
         dispatch({ type: types.SET_EMAIL_RECIPIENTS, recipients });
      });
   };
}

export function removeRecipient(recipient) {
   return (dispatch) => {
      RemoveRecipient(recipient).then((recipients) => {
         dispatch({ type: types.SET_EMAIL_RECIPIENTS, recipients });
      });
   };
}

export function setLastSync(lastSyncDate) {
   return (dispatch) => {
      SetLastSyncDate(lastSyncDate).then(() => {
         dispatch({ type: types.SET_LAST_SYNC, lastSyncDate });
      });
   };
}

export function setCopyApprover(copyApprover) {
   return (dispatch) => {
      StoreCopyApprover(copyApprover).then(() => {
         dispatch({ type: types.SET_COPY_APPROVER, copyApprover });
      });
   };
}

export function setFormNewLocationOverride(location, locationType) {
   return (dispatch) => {
      StoreLastLocation(location, locationType).then(() => {
         dispatch({ type: types.SET_FORM_NEW_LOCATION, location, locationType });
      });
   };
}

export function setFormListingSortBy(sortByValue) {
   return (dispatch) => {
      StoreLastFormListSortBy(sortByValue).then(() => {
         dispatch({ type: types.SET_FORM_LISTING_SORT_BY, sortByValue});
      });
   };
}

