import React, { useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@progress/kendo-theme-default/dist/all.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as uiActions from "../redux/actions/uiActions";
import * as userActions from "../redux/actions/userActions";
import AppRouter from "./AppRouter";
import NavigationContainer from "./NavigationContainer";
import ServiceWorkerWrapper from "./ServiceWorkerWrapper";
import { SyncReminder } from "./SyncReminder";
import { useOnlineDetector } from "../hooks/useOnlineDetector";

function App({ actions, init, sizeFactor, checkVersion, isOnline, lastSync }) {
   useEffect(() => {
      if (!init) {
         actions.setInitialUIState();
         actions.setInitialUserState();
      }
      if (checkVersion) {
         actions.checkVersion();
      }
   }, [init, actions, checkVersion]);

   const { onlineState } = useOnlineDetector();

   useEffect(() => {
      if (onlineState !== isOnline) {
         actions.setIsOnline(onlineState);
      }
   }, [onlineState, isOnline, actions]);

   return (
      <>
         <ServiceWorkerWrapper />
         <SyncReminder lastSync={lastSync} online={onlineState} />
         <NavigationContainer sizeFactor={sizeFactor}>
            <AppRouter />
         </NavigationContainer>
      </>
   );
}

function mapStateToProps(state) {
   return {
      init: state.ui.init,
      sizeFactor: state.ui.sizeFactor,
      checkVersion: state.ui.checkVersion,
      lastSync: state.user.LastSyncDate,
      isOnline: state.ui.online
   };
}

function mapDispatchToProps(dispatch) {
   return {
      actions: {
         setInitialUIState: bindActionCreators(
            uiActions.setInitialUIState,
            dispatch
         ),
         setInitialUserState: bindActionCreators(
            userActions.setInitialUserState,
            dispatch
         ),
         setIsOnline: bindActionCreators(uiActions.setIsOnline, dispatch),
         checkVersion: bindActionCreators(uiActions.checkVersion, dispatch),
      },
   };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
