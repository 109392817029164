import * as types from "../actionTypes";
import {
   GetInitialUIState,
   GetSyncInfo,
   SetSizeFactor,
   CheckVersion,
   UpdateLastRoute,
   SetReleaseNotes,
   SetFormListSortByDb
} from "../../db/uiHandler";
import { SetLogLevel, PurgeLogs } from "../../helpers/logger";

export function setTroubleCode(code) {
   return { type: types.SET_TROUBLE_CODE, code };
}

export function setRoute(route) {
   return (dispatch) => {
      UpdateLastRoute(route).then(() => {
         dispatch({ type: types.SET_ROUTE, route });
      });
   };
}

export function setSubmitType(submitType) {
   return { type: types.SET_SUBMIT_TYPE, submitType };
}

export function setIncludeSubmitted() {
   return { type: types.SET_INCLUDE_SUBMITTED };
}

export function loadSyncInfo() {
   return (dispatch) => {
      GetSyncInfo().then((obj) => {
         dispatch({ type: types.LOAD_SYNC_INFO, obj });
      });
   };
}

export function setInitialUIState() {
   return (dispatch) => {
      GetInitialUIState().then((newstate) => {
         dispatch({ type: types.SET_INITIAL_UI_STATE, newstate });
      });
   };
}

export function setIsOnline(isOnline) {
   return (dispatch) => {
      dispatch({ type: types.IS_ONLINE, online: isOnline });
   };
}

export function setLogLevel(level) {
   return (dispatch) => {
      SetLogLevel(level).then((logLevel) => {
         dispatch({ type: types.SET_LOG_LEVEL, logLevel: logLevel });
      });
   };
}

export function purgeLogs(daysToKeep) {
   return (dispatch) => {
      PurgeLogs(daysToKeep).then((purgeDate) => {
         dispatch({ type: types.PURGE_LOGS, lastLogPurge: purgeDate });
      });
   };
}

export function setSizeFactor(factor) {
   return (dispatch) => {
      SetSizeFactor(factor).then(() => {
         dispatch({ type: types.SET_SIZE_FACTOR, sizeFactor: factor });
      });
   };
}

export function checkVersion() {
   return (dispatch) => {
      CheckVersion().then(() => {
         dispatch({ type: types.CHECK_VERSION, checkVersion: false });
      });
   };
}

export function setReleaseNotes(notes) {
   return (dispatch) => {
      SetReleaseNotes(notes).then(() => {
         dispatch({ type: types.SET_RELEASE_NOTES, releaseNotes: notes });
      });
   };
}

export function setFormListSortBy(sortBy) {
   return (dispatch) => {
      SetFormListSortByDb(sortBy).then(() => {
         dispatch({ type: types.SET_FORM_LIST_SORT, sortBy });
      });
   };
}