import React, { useState, useEffect } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { PanelBar, PanelBarUtils } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";

function DefSelect({
   defs,
   setShowDefSelect,
   newFormDefId,
   setNewFormDefId,
   sizeFactor,
   newFormWorkOrder,
}) {
   const [tree, setTree] = useState([]);
   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         let items = [];
         for (let d = 0; d < defs.length; d++) {
            const workorder = defs[d];
            if (workorder.data.id === "workorder-" + newFormWorkOrder.id) {
               for (let f = 0; f < workorder.items.length; f++) {
                  const formtype = workorder.items[f];
                  let tformtype = {
                     title: formtype.data.text,
                     panelType: "header",
                     children: [],
                  };
                  for (let i = 0; i < formtype.items.length; i++) {
                     const item = formtype.items[i];
                     tformtype.children.push({
                        title: item.data.text,
                        panelType: "definition",
                        id: item.data.Id,
                     });
                  }
                  items.push(tformtype);
               }
            }
         }
         setTree(PanelBarUtils.mapItemsToComponents(items));
      }
      return () => {
         isMounted = false;
      };
   }, [defs, newFormWorkOrder.id]);

   const handleOnSelect = (event) => {
      if (event.target.props.panelType === "definition") {
         setNewFormDefId(event.target.props.id);
         setShowDefSelect(false);
      }
   };

   return (
      <Dialog
         title="Select Form"
         width={window.innerWidth}
         height={window.innerHeight}
         onClose={() => setShowDefSelect(false)}
         style={{ fontSize: 0.95 * sizeFactor + "rem" }}
      >
         <PanelBar
            expandMode="single"
            onSelect={handleOnSelect}
            children={tree}
            style={{ fontSize: 0.95 * sizeFactor + "rem" }}
         />
         <DialogActionsBar layout="center">
            <Button
               themeColor="primary"
               onClick={() => setShowDefSelect(false)}
               style={{ fontSize: 0.95 * sizeFactor + "rem" }}
            >
               Cancel
            </Button>
         </DialogActionsBar>
      </Dialog>
   );
}

export default DefSelect;
