import React, { useState, useEffect, useRef } from "react";
import { Loader } from "@progress/kendo-react-indicators";
import { bindActionCreators } from "redux";
import * as userActions from "../redux/actions/userActions";
import * as formActions from "../redux/actions/formActions";
import * as defActions from "../redux/actions/defActions";
import { connect } from "react-redux";
import {
   Card,
   CardBody,
   CardHeader,
   CardFooter,
} from "@progress/kendo-react-layout";
import {
   Notification,
   NotificationGroup,
} from "@progress/kendo-react-notification";
import { Button } from "@progress/kendo-react-buttons";
import { MdOutlineAttachEmail } from "react-icons/md";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { EmailForm, GeneratePdfBytes } from "../api/apiUtils";
import { formatDateTime, formatDate } from "../helpers/dateUtils";
import * as enums from "../helpers/enums";
import Login from "./Login";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../helpers/authConfig";
import "./FormInfo.css";
import { isNullOrEmpty, formatUsername, isAssigned } from "../helpers/utils";
import { SendForm } from "../api/formsApi";
import { useQuery } from '@tanstack/react-query';
import { getGeneratedFormPdf } from '../api/queries';
import { downloadPdfStringAsBlob } from "../helpers/utils";
import { TransferDialog } from "./TransferDialog";
import { DeleteForm } from "../db/formHandler";

const notifyPosition = {
   top: 300,
   left: "50%",
   transform: "translateX(-50%)",
};

const isInViewport = (element) => {
   const rect = element.getBoundingClientRect();
   return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
   );
};

function FormInfo({
   actions,
   form,
   online,
   setFormStatus,
   setFormId,
   setFormPage,
   workOrderName,
   sizeFactor,
   setSubmitType,
   setEditId,
   username,
}) {
   const [visibleConfirm, setVisibleConfirm] = useState(false);

   const [emailForm, setEmailForm] = useState({
      start: false,
      resendPdf: false,
   });

   const [toast, setToast] = useState({ state: "none", message: "" });
   const [newStatus, setNewStatus] = useState(null);
   const ref = useRef(null);
   const { instance, accounts } = useMsal();
   const isAuthenticated = useIsAuthenticated();
   const formIsAssigned = isAssigned(form);
   const [pdfInProgress, setPdfInProgress] = useState(false);
   const [showGeneratedPdf, setShowGeneratedPdf] = useState();
   const [accessToken, setAccessToken] = useState();
   const [showTransferDialog, setShowTransferDialog] = useState(false);

   useEffect(() => {
      let isMounted = true;
      if (ref !== null && !isInViewport(ref.current)) {
         ref.current.scrollIntoView({ inline: "end" });
      }

      if (emailForm.start && online && isAuthenticated) {
         instance
            .acquireTokenSilent({
               ...loginRequest,
               account: accounts[0],
            })
            .then((response) => {
               EmailForm(form.Id, response.accessToken)
                  .then(() => {
                     if (!isMounted) return;
                     setToast({
                        state: "success",
                        message: "Email started in the background",
                     });
                     setTimeout(() => {
                        setToast({ state: "none", message: "" });
                     }, 2000);
                  })
                  .catch((error) => {
                     setToast({
                        state: "error",
                        message:
                           "An error occurred sending email: " + error.message,
                     });
                  });
            });
      }

      return () => {
         isMounted = false;
      };
   }, [emailForm, form.Id, online, isAuthenticated, instance, accounts]);

   useQuery(['getGeneratedFormPdf', { formId: form.Id, accessToken }], getGeneratedFormPdf,
      {
         enabled: online && isAuthenticated && showGeneratedPdf === true && accessToken !== undefined,
         onSuccess: async (response) => {
            setAccessToken(undefined);
            setShowGeneratedPdf(false);
            setPdfInProgress(false);

            if (response.Success) {
               await downloadPdfStringAsBlob(`data:application/pdf;base64,${response.Data.FileContent}`, `${response.Data.FileName}.pdf`);
            }
         },
         onError: (response) => {
            setAccessToken(undefined);
            setShowGeneratedPdf(false);
            setPdfInProgress(false);
            setToast({
               state: "error",
               message:
                  "An error occurred retrieving the form PDF"
            });
         }
      }
   );

   const onShowGeneratedPdfClick = async () => {

      if (pdfInProgress) return;

      setPdfInProgress(true);

      const tokenResponse = await instance
         .acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
         });

      setAccessToken(tokenResponse.accessToken);
      setShowGeneratedPdf(true);
   };


   const showPdf = async () => {

      if (pdfInProgress) return;

      setPdfInProgress(true);

      try {
         const tokenResponse = await instance
            .acquireTokenSilent({
               ...loginRequest,
               account: accounts[0],
            });

         const config = {
            headers: { Authorization: "Bearer " + tokenResponse.accessToken }
         };

         const sendFormResult = await SendForm(form.Id, config);

         const pdfBytes = await GeneratePdfBytes(sendFormResult.newid, tokenResponse.accessToken);

         const byteCharacters = atob(pdfBytes);
         const byteNumbers = new Array(byteCharacters.length);
         for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
         }
         const byteArray = new Uint8Array(byteNumbers);

         const file = new Blob(
            [byteArray],
            { type: 'application/pdf' });

         const downloadBlob = (fileName, blob) => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.target = '_blank';
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
         }

         downloadBlob('enform-preview.pdf', file)

         actions.setInitialUserState();
         actions.setFormsInit(true);

         if (sendFormResult.newid !== form.Id) {
            setFormId(sendFormResult.newid);
         }
      }
      catch (error) {
         setToast({
            state: "error",
            message:
               "An error occurred previewing the PDF"
         });
      }

      setPdfInProgress(false);
   };

   if (emailForm.start && online && !isAuthenticated) {
      return <Login />;
   }

   let buttons = {
      Submit: false,
      ResendPdf: false,
      Delete: false,
      Approve: false,
      ChangeRequest: false,
      Edit: false,
      EmailForm: false,
      PreviewPdf: false,
      ShowPdf: false,
      Transfer: false
   };

   if (
      form.Status === enums.Status.New ||
      form.Status === enums.Status.ChangeRequest
   ) {
      buttons.Submit = true;
      buttons.Delete = true;
      buttons.Edit = true;
      buttons.EmailForm = true;
      buttons.PreviewPdf = true;
      buttons.Transfer = true;
   } else if (form.Status === enums.Status.Submitted) {
      buttons.ResendPdf = true;
      buttons.ShowPdf = true;
      if (
         isAssigned(form) &&
         username.toLowerCase() === form.AssignedTo.toLowerCase()
      ) {
         buttons.Approve = true;
         buttons.ChangeRequest = true;
      } else {
         buttons.Delete = true;
      }
   }

   const btnStatusChange = (statusId, description) => {
      setNewStatus({ id: statusId, text: description });
      setVisibleConfirm(true);
   };

   function handleStatusChange() {
      setVisibleConfirm(false);
      setFormStatus(form.Id, newStatus.id);
      setFormId(null);
   }

   function handleEmailForm(resendPdf) {
      setEmailForm({ start: true, resendPdf: resendPdf });
   }

   async function handlePreviewPdf() {
      await showPdf();
   }

   const onTransferClick = () => {
      setShowTransferDialog(true);
   }

   const onTransferComplete = async (formId) => {
      setShowTransferDialog(false);
      await DeleteForm(formId);

      actions.setFormsInit(true);
   }

   return (
      <div ref={ref}>
         <Card
            style={{
               backgroundColor: "#f8f8f8",
               border: "1px solid gold",
            }}
         >
            <CardHeader>
               <div
                  style={{
                     display: "flex",
                     justifyContent: 'space-between',
                     gap: '4px'
                  }}
               >
                  <div>
                     <Button
                        style={{ fontSize: 0.95 * sizeFactor + "rem" }}
                        onClick={() => setFormPage("form")}
                        icon="arrow-right"
                        fillMode="outline"
                     >
                        Data Entry
                     </Button>
                  </div>
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }} >
                     {buttons.PreviewPdf && (
                        <div>
                           <Button
                              themeColor="primary"
                              fillMode="outline"
                              disabled={!online || !isAuthenticated || pdfInProgress}
                              onClick={handlePreviewPdf}
                              style={{ fontSize: 0.95 * sizeFactor + "rem", }}
                           >
                              {pdfInProgress && (
                                 <div style={{ display: 'grid', gridAutoFlow: 'column' }}>
                                    <span>Preview PDF</span>
                                    &nbsp;
                                    <Loader size="small" type='infinite-spinner' />
                                 </div>)
                              }
                              {!pdfInProgress && <span>Preview PDF</span>}
                           </Button>
                        </div>
                     )}
                     {buttons.EmailForm && (
                        <div>
                           <Button
                              themeColor="primary"
                              fillMode="outline"
                              disabled={!online || form.State !== enums.State.None}
                              onClick={() => handleEmailForm(false)}
                              style={{ fontSize: 0.95 * sizeFactor + "rem" }}
                           >
                              Email Form
                              <MdOutlineAttachEmail />
                           </Button>
                        </div>
                     )}
                  </div>
               </div>
            </CardHeader>
            <CardBody>
               <div
                  className="info-div"
                  style={{ fontSize: 0.95 * sizeFactor + "rem" }}
               >
                  <strong>Form Id:</strong>&nbsp;
                  {form.Id < 0 ? "(new)" : form.Id}
               </div>
               <div
                  className="info-div"
                  style={{ fontSize: 0.95 * sizeFactor + "rem" }}
               >
                  <strong>Work Order:</strong>&nbsp;
                  {workOrderName}
               </div>
               <div
                  className="info-div"
                  style={{ fontSize: 0.95 * sizeFactor + "rem" }}
               >
                  <strong>Report Date:</strong>&nbsp;
                  {formatDate(form.ReportDate)}
               </div>
               {formIsAssigned && form.CreatedBy !== username ? (
                  <div
                     className="info-div"
                     style={{ fontSize: 0.95 * sizeFactor + "rem" }}
                  >
                     <strong>Owner:</strong>&nbsp;
                     {formatUsername(form.CreatedBy)}
                  </div>
               ) : null}
               <div
                  className="info-div"
                  style={{ fontSize: 0.95 * sizeFactor + "rem" }}
               >
                  <strong>Created:</strong>&nbsp;
                  {formatDateTime(form.CreateDateTime)}
               </div>
               <div
                  className="info-div"
                  style={{ fontSize: 0.95 * sizeFactor + "rem" }}
               >
                  <strong>Uploaded:</strong>&nbsp;
                  {formatDateTime(form.UploadDateTime)}
               </div>
               <div
                  className="info-div"
                  style={{ fontSize: 0.95 * sizeFactor + "rem" }}
               >
                  <strong>Submitted:</strong>&nbsp;
                  {formatDateTime(form.SubmittedDateTime)}
               </div>
               <div
                  className="info-div"
                  style={{ fontSize: 0.95 * sizeFactor + "rem" }}
               >
                  <strong>Location:</strong>&nbsp;
                  {!form.hasOwnProperty("Location") ||
                     isNullOrEmpty(form.Location) ? (
                     ""
                  ) : form.Location.startsWith("lng:") ? (
                     <>
                        <span
                           className="k-icon k-i-globe"
                           style={{ color: "green" }}
                        ></span>
                        &nbsp;Geo Location
                     </>
                  ) : (
                     form.Location
                  )}
               </div>
            </CardBody>
            <CardFooter>
               <div
                  style={{
                     display: "flex",
                     justifyContent: "space-between",
                  }}
               >
                  <div>
                     {buttons.Submit && (
                        <Button
                           disabled={!online}
                           style={{
                              fontSize: 0.95 * sizeFactor + "rem",
                              marginRight: "5px",
                           }}
                           onClick={() => setSubmitType("submit")}
                        >
                           Go To Submit
                        </Button>
                     )}

                     {buttons.ResendPdf && (
                        <Button
                           disabled={!online}
                           style={{
                              fontSize: 0.95 * sizeFactor + "rem",
                              marginRight: "5px",
                           }}
                           onClick={() => handleEmailForm(true)}
                        >
                           Resend Pdf
                        </Button>
                     )}

                     {buttons.ShowPdf && (
                        <Button
                           disabled={!online || pdfInProgress === true}
                           style={{
                              fontSize: 0.95 * sizeFactor + "rem",
                              marginRight: "5px",
                           }}
                           onClick={onShowGeneratedPdfClick}
                        >
                           Show Pdf
                           {pdfInProgress && <>
                              &nbsp;
                              <Loader size="small" type='infinite-spinner' />
                           </>}
                        </Button>
                     )}

                     {buttons.Delete && (
                        <Button
                           style={{
                              fontSize: 0.95 * sizeFactor + "rem",
                              marginRight: "5px",
                           }}
                           onClick={() =>
                              btnStatusChange(enums.Status.Canceled, "Delete")
                           }
                        >
                           Delete
                        </Button>
                     )}

                     {buttons.Approve && (
                        <Button
                           disabled={!online}
                           style={{
                              fontSize: 0.95 * sizeFactor + "rem",
                              marginRight: "5px",
                           }}
                           onClick={() => setSubmitType("approve")}
                        >
                           Approve
                        </Button>
                     )}

                     {buttons.ChangeRequest && (
                        <Button
                           disabled={!online}
                           style={{
                              fontSize: 0.95 * sizeFactor + "rem",
                              marginRight: "5px",
                           }}
                           onClick={() => setSubmitType("changeRequest")}
                        >
                           Change Request
                        </Button>
                     )}

                     {buttons.Transfer && (
                        <Button
                           disabled={!online || !isAuthenticated}
                           style={{
                              fontSize: 0.95 * sizeFactor + "rem",
                              marginRight: "5px",
                           }}
                           onClick={onTransferClick}
                        >
                           Transfer
                        </Button>
                     )}

                     {showTransferDialog &&
                        <TransferDialog
                           formId={form.Id}
                           workOrderId={form.WorkOrderId}
                           onClose={() => setShowTransferDialog(false)}
                           onTransferComplete={onTransferComplete} />
                     }

                  </div>

                  {buttons.Edit && (
                     <div>
                        <Button
                           style={{
                              fontSize: 0.95 * sizeFactor + "rem",
                           }}
                           icon="pencil"
                           onClick={() => setEditId(form.Id)}
                        >
                           Edit
                        </Button>
                     </div>
                  )}

               </div>
            </CardFooter>
         </Card>
         {visibleConfirm ? (
            <Dialog
               title="Confirm Status Change"
               onClose={() => setVisibleConfirm(false)}
            >
               <p
                  style={{
                     textAlign: "center",
                     fontSize: 1 * sizeFactor + "rem",
                  }}
               >
                  Are you sure you want to change the status to {newStatus.text}
                  ?
               </p>
               <DialogActionsBar>
                  <button onClick={() => setVisibleConfirm(false)}>No</button>
                  <button onClick={handleStatusChange}>Yes</button>
               </DialogActionsBar>
            </Dialog>
         ) : null}
         <NotificationGroup style={notifyPosition}>
            {toast.state !== "none" ? (
               <Notification
                  type={{
                     style: toast.state,
                     icon: true,
                  }}
                  closable={true}
                  onClose={() => setToast({ state: "none", message: "" })}
               >
                  {toast.message}
               </Notification>
            ) : null}
         </NotificationGroup>

      </div>
   );
}

function mapDispatchToProps(dispatch) {
   return {
      actions: {
         setInitialUserState: bindActionCreators(
            userActions.setInitialUserState,
            dispatch
         ),
         setFormsInit: bindActionCreators(formActions.setFormsInit, dispatch),
         setDefInit: bindActionCreators(defActions.setDefInit, dispatch),
      },
   };
}

export default connect(null, mapDispatchToProps)(FormInfo);
