import * as types from "../actionTypes";
import initialState from "../initialState";

export default function defReducer(state = initialState.defs, action) {
   switch (action.type) {
      case types.LOAD_DEFS_LIST:
         return { ...state, init: false, list: action.defList };
      case types.SET_DEF_INIT:
         return { ...state, init: action.value, list: [] };
      default:
         return state;
   }
}
