import { useMsal } from '@azure/msal-react';
import { Loader } from '@progress/kendo-react-indicators';
import { useMutation } from '@tanstack/react-query';
import { isAfter, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { acknowledgeOwnership } from '../api/queries';
import { GetDefById, GetWorkOrderById } from '../db/defHandler';
import { SaveAllLastsForForm } from '../db/lastHandler';
import { loginRequest } from '../helpers/authConfig';
import { IApiResponse } from '../types/IApiResponse';
import { AppYesNoDialog } from './AppYesNoDialog';

interface ITransferResult {
  id: number;
  definitionId: number;
  workOrderId: number;
  isNewTransfer: boolean;
  updateDateTime: string;
  updatedBy: string;
}

interface ITransferDisplayResult extends ITransferResult {
  definitionName: string;
  workOrderName: string;
  count: number;
}

interface IProps {
  transfers: ITransferResult[],
  onTransfersCompleted: () => void;
}

const ReceiveTransfers = ({ transfers, onTransfersCompleted }: IProps) => {

  const { instance, accounts } = useMsal();
  const [reducedResults, setReducedResults] = useState<ITransferResult[]>([]);
  const [newTransfers, setNewTransfers] = useState<ITransferDisplayResult[]>([]);
  const [pause, setPause] = useState<boolean>(false);

  const getDisplayResult = async (transfer: ITransferResult) => {

    const def = await GetDefById(transfer.definitionId);
    const workOrder = await GetWorkOrderById(transfer.workOrderId);

    return {
      ...transfer,
      definitionName: `${def.Name} - ${def.Label}`,
      workOrderName: workOrder.Name,
      count: transfers.filter(x => x.definitionId === transfer.definitionId && x.workOrderId === transfer.workOrderId).length
    };
  };

  useEffect(() => {

    let interval: any = null;

    if (pause) {
      interval = setInterval(() => {
        setPause(false);
      }, 1000);
    }

    return () => clearInterval(interval);

  }, [pause, setPause])

  useEffect(() => {

    const go = async () => {

      const results = transfers.reduce((results: ITransferResult[], transfer: ITransferResult) => {

        if (transfer.isNewTransfer !== true) return results;

        const existing = results.find(x => x.definitionId === transfer.definitionId && x.workOrderId === transfer.workOrderId);

        if (!existing) {
          results.push(transfer);
        }
        else if (isAfter(parseISO(transfer.updateDateTime), parseISO(existing.updateDateTime))) {
          results = [...results.filter(x => x.definitionId !== transfer.definitionId && x.workOrderId !== transfer.workOrderId), transfer];
        }

        return results;

      }, []);

      setReducedResults(results);
    }

    if (transfers.length === 0 || reducedResults.length > 0) {
      return;
    }

    go();

  }, [transfers, reducedResults]);

  useEffect(() => {

    const makeDisplayResults = async () => {
      let resultArray: ITransferDisplayResult[] = [];
      for (let idx in reducedResults) {
        const displayResult = await getDisplayResult(reducedResults[idx]);
        resultArray.push(displayResult);
      }

      setNewTransfers(resultArray);
    };

    if (reducedResults.length > 0 && newTransfers.length === 0) {
      makeDisplayResults();
    }
  });

  const acknowledgeTransferMutation = useMutation(acknowledgeOwnership,
    {
      onSuccess: async (response: IApiResponse<boolean>) => {
        if (response.Success && response.Data) {
          onTransfersCompleted();
        }
      },
      onError: (response: any) => {
        console.log("Error acknowledging transfers");
        onTransfersCompleted();
      }
    }
  );

  const nextTransfer = async (formId: number) => {
    setPause(true);

    const remaining = [...newTransfers.filter(x => x.id !== formId)];

    if (remaining.length === 0) {

      const tokenResponse = await instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });

      acknowledgeTransferMutation.mutate({ formIds: transfers.map(x => x.id), accessToken: tokenResponse.accessToken })

      return;
    }
    else {
      setNewTransfers(remaining);
    }
  }

  const setLastsDataForTransferredForms = async (formId: number) => {
    await SaveAllLastsForForm(formId);
    nextTransfer(formId);
  };

  if (newTransfers.length === 0) return <></>;

  const thisTransfer = newTransfers[0];

  return <div style={{ padding: '10px' }}>
    <AppYesNoDialog
      disabled={pause === true}
      title="New Transferred Forms"
      body={
        <div>
          {pause === false &&
            <>
              <p>{thisTransfer.updatedBy} has transferred {thisTransfer.count} {thisTransfer.count === 1 ? 'form' : 'forms'} of the following type to you.</p>

              <p>Work Order: {thisTransfer.workOrderName}</p>
              <p>Form: {thisTransfer.definitionName}</p>

              <p>Would you like to update your current saved data to match the data on these transferred forms?</p>
            </>
          }
          {pause && <Loader size="large" type='infinite-spinner' />}
        </div>
      }
      onClose={() => nextTransfer(thisTransfer.id)}
      onNo={() => nextTransfer(thisTransfer.id)}
      onYes={() => setLastsDataForTransferredForms(thisTransfer.id)}
    />
  </div>;
}

export { ReceiveTransfers }