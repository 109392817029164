import React, { useRef, useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../helpers/authConfig";

function LoadDigitalId({ sizeFactor, setLoadDigitalId, setDigitalId }) {
   const digitalidref = useRef(null);
   const { instance, accounts } = useMsal();
   const [accessToken, setAccessToken] = useState(null);

   useEffect(() => {
      let isMounted = true;

      instance
         .acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
         })
         .then((response) => {
            if (isMounted) setAccessToken(response.accessToken);
         });
      return () => {
         isMounted = false;
      };
   }, [accounts, instance]);

   const onHandleGetDigitalId = () => {
      digitalidref.current.click();
   };

   const processDigitalId = () => {
      let temp = digitalidref.current;
      if (temp.files.length === 0) return;

      const file = temp.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.name = file.name;
      reader.size = file.size;
      reader.onload = function (event) {
         let content = event.target.result; // result is base64-encoded Data URI
         setDigitalId(accessToken, content);
      };

      setLoadDigitalId(false);
   };

   return (
      <Dialog
         title="Load Digital Id"
         onClose={() => setLoadDigitalId(false)}
         width={window.innerWidth * 0.75}
      >
         <div>
            <input
               ref={digitalidref}
               type="file"
               accept=".pfx,.p12"
               onChange={processDigitalId}
               style={{ display: "none" }}
            />
         </div>
         <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
               <Button
                  style={{ fontSize: 1 * sizeFactor + "rem" }}
                  onClick={onHandleGetDigitalId}
               >
                  Get Digital Id
               </Button>
            </div>
         </div>
      </Dialog>
   );
}

export default LoadDigitalId;
