import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button } from "@progress/kendo-react-buttons";
import {
   Window,
   Dialog,
   DialogActionsBar,
} from "@progress/kendo-react-dialogs";
import { TextArea } from "@progress/kendo-react-inputs";
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import {
   Notification,
   NotificationGroup,
} from "@progress/kendo-react-notification";
import * as uiActions from "../redux/actions/uiActions";
import {
   GetForms,
   DeleteALLForms,
   DeleteAllDefinitions,
   GetVals,
   GetDefsLastAsObject,
} from "../helpers/setupUtil";
import { ParseDataDefs } from "../db/defHandler";
import { ResetDefsLast } from "../db/lastHandler";
import { FixBrokenAutoListDatas } from "../db/fixHandler";
import { getRollingCode, validRollingCode } from "../helpers/rollingCode";
import "./Troubles.css";
import { isNullOrEmpty } from "../helpers/utils";
import { SendEmail } from "../api/apiUtils";
import Logout from "../components/Logout";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../helpers/authConfig";

const notifyPosition = {
   top: 300,
   left: "50%",
   transform: "translateX(-50%)",
};

function Troubles({ actions, stateDump, sizeFactor, role, troubleCode }) {
   const [rollingCode, setRollingCode] = useState([troubleCode, ""]);
   const [verifiedCode, setVerifiedCode] = useState(false);
   const [confirmInfo, setConfirmInfo] = useState({
      visible: false,
      type: "none",
      title: "",
      message: "",
      callback: null,
   });
   const isAdmin = role === "Admin";
   const [showState, setShowState] = useState({
      show: false,
      text: "",
      title: "",
      type: "",
   });
   // toast style: success, error, warning, info, none
   const [toastState, setToastState] = useState({ style: "none", message: "" });
   const { instance, accounts } = useMsal();

   useEffect(() => {
      if (isAdmin) {
         if (isNullOrEmpty(troubleCode)) {
            const newcode = getRollingCode();
            setRollingCode(newcode);
            actions.setTroubleCode(newcode[0]);
         } else {
            const checkcode = validRollingCode(troubleCode);
            if (checkcode[0]) {
               setRollingCode([troubleCode, checkcode[1]]);
            } else {
               const newcode = getRollingCode();
               setRollingCode(newcode);
               actions.setTroubleCode(newcode[0]);
            }
         }
         setVerifiedCode(true);
      } else {
         if (troubleCode === "TROUBLE") {
            setRollingCode(["TROUBLE", ""]);
            setVerifiedCode(true);
         } else if (isNullOrEmpty(troubleCode)) {
            setRollingCode(["", ""]);
            setVerifiedCode(false);
         } else {
            const checkcode = validRollingCode(troubleCode);
            if (checkcode[0]) {
               setRollingCode([troubleCode, checkcode[1]]);
               setVerifiedCode(true);
            } else {
               setRollingCode(["", ""]);
               actions.setTroubleCode("");
               setVerifiedCode(false);
            }
         }
      }
   }, [setVerifiedCode, actions, isAdmin, troubleCode]);

   const resetConfirmInfo = () => {
      setConfirmInfo({
         visible: false,
         type: "",
         title: "",
         message: "",
         callback: null,
      });
   };

   const showStateButton = () => {
      setShowState({
         show: true,
         title: "State Dump",
         text: JSON.stringify(stateDump, null, 2),
         raw: stateDump,
         type: "State",
      });
   };

   const showFormsButton = () => {
      GetForms().then((forms) => {
         setShowState({
            show: true,
            title: "Forms Dump",
            text: JSON.stringify(forms, null, 2),
            raw: forms,
            type: "Forms",
         });
      });
   };

   const showValButton = () => {
      GetVals().then((vals) => {
         setShowState({
            show: true,
            title: "Validations",
            text: JSON.stringify(vals, null, 2),
            raw: vals,
            type: "Vals",
         });
      });
   };

   const showDefsLastButton = () => {
      GetDefsLastAsObject().then((lasts) => {
         setShowState({
            show: true,
            title: "Last",
            text: JSON.stringify(lasts, null, 2),
            raw: lasts,
            type: "DefsLast",
         });
      });
   };

   const handleSendEmail = async () => {
      instance
         .acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
         })
         .then((response) => {
            SendEmail(
               showState.type,
               showState.title,
               "Sent from mobile device",
               "dwaynelott@yahoo.com",
               showState.text,
               response.accessToken
            ).then(
               function (response) {
                  setShowState({ show: false, text: "", title: "", type: "" });
                  if (response) {
                     setToastState({
                        style: "success",
                        message: "Success sending email",
                     });
                  } else {
                     setToastState({
                        style: "error",
                        message: "Error sending email",
                     });
                  }
               },
               function (response) {
                  setShowState({ show: false, text: "", title: "", type: "" });
                  setToastState({
                     style: "error",
                     message: "Error sending email",
                  });
               }
            );
         });
   };

   const parseDataDefs = async () => {
      let success = await ParseDataDefs();
      if (success) {
         setToastState({
            style: "success",
            message: "Parse Definitions complete",
         });
      } else {
         setToastState({
            style: "error",
            message: "Parse Definitions has errors.  See logs.",
         });
      }
   };

   const resetDefsLast = async () => {
      await ResetDefsLast();
      setToastState({
         style: "success",
         message: "Reset Defs Last complete",
      });
   };

   const fixBrokenAutoList = async () => {
      let success = await FixBrokenAutoListDatas();
      if (success) {
         setToastState({
            style: "success",
            message: "Fix Broken AutoList Datas complete",
         });
      } else {
         setToastState({
            style: "error",
            message: "Fix Broken AutoList Datas has errors.  See logs.",
         });
      }
   };

   const handleGetRollingCode = () => {
      const newcode = getRollingCode();
      setRollingCode(newcode);
      actions.setTroubleCode(newcode[0]);
   };

   const handleCheckRollingCode = () => {
      const isvalid = validRollingCode(rollingCode[0]);
      setVerifiedCode(isvalid);
      if (isvalid) {
         actions.setTroubleCode(rollingCode[0]);
      } else {
         actions.setTroubleCode("");
      }
   };

   const handleOnCodeChange = (event) => {
      const newcode = [event.value, ""];
      setRollingCode(newcode);
   };

   const confirmDeleteData = (type) => {
      setConfirmInfo({
         visible: true,
         type: type,
         title: "Confirm Delete " + type,
         message: "Are you sure you want to delete ALL " + type + "?",
         callback:
            type === "Forms"
               ? handleDeleteForms
               : type === "Definitions"
                  ? handleDeleteDefs
                  : () => console.log("none"),
      });
   };

   const handleDeleteForms = async () => {
      const numdeletes = await DeleteALLForms();
      postDelete(numdeletes, "Forms");
   };

   const handleDeleteDefs = async () => {
      const numdeletes = await DeleteAllDefinitions();
      postDelete(numdeletes, "Definitions");
   };

   const postDelete = (numDeletes, type) => {
      if (numDeletes === 0) {
         setToastState({
            style: "warning",
            message: "No " + type + " were deleted",
         });
         setTimeout(() => {
            setToastState({ style: "none", message: "" });
         }, 3000);
      } else {
         setToastState({
            style: "success",
            message: numDeletes + " " + type + " were deleted",
         });
         setTimeout(() => {
            setToastState({ style: "none", message: "" });
         }, 2000);
      }
      resetConfirmInfo();
   };

   return (
      <div>
         <div
            className="setup-title-bar"
            style={{ fontSize: 1.1 * sizeFactor + "rem" }}
         >
            Troubleshooting
         </div>
         <div className="setup-button-group">
            <div className="setup-element">
               <Logout />
            </div>
            <div>
               <Button
                  style={{ margin: "5px", fontSize: 1 * sizeFactor + "rem" }}
                  fillMode="flat"
                  icon="grid"
                  onClick={() => actions.setRoute("logs")}
               >
                  Logs
               </Button>
            </div>
         </div>
         <div className="setup-button-group">
            <div className="setup-element">
               <Button
                  fillMode="flat"
                  icon="ungroup"
                  onClick={showStateButton}
                  style={{ margin: "5px", fontSize: 1 * sizeFactor + "rem" }}
               >
                  Show State
               </Button>
            </div>
            <div className="setup-element">
               <Button
                  fillMode="flat"
                  icon="grid-layout"
                  onClick={showFormsButton}
                  style={{ margin: "5px", fontSize: 1 * sizeFactor + "rem" }}
               >
                  Show Forms
               </Button>
            </div>
         </div>

         <div className="setup-button-group">
            <div className="setup-element">
               <Button
                  fillMode="flat"
                  icon="check"
                  onClick={showValButton}
                  style={{ margin: "5px", fontSize: 1 * sizeFactor + "rem" }}
               >
                  Show Validations
               </Button>
            </div>
            <div className="setup-element">
               <Button
                  fillMode="flat"
                  icon="arrow-end-right"
                  onClick={showDefsLastButton}
                  style={{ margin: "5px", fontSize: 1 * sizeFactor + "rem" }}
               >
                  Show $LAST
               </Button>
            </div>
         </div>

         <div className="setup-button-group">
            <div className="setup-element">
               <Button
                  fillMode="flat"
                  icon="table-delete"
                  disabled={!verifiedCode}
                  onClick={() => confirmDeleteData("Forms")}
                  style={{ margin: "5px", fontSize: 1 * sizeFactor + "rem" }}
               >
                  Delete ALL Forms
               </Button>
            </div>
            <div className="setup-element">
               <Button
                  style={{ margin: "5px", fontSize: 1 * sizeFactor + "rem" }}
                  fillMode="flat"
                  icon="table-delete"
                  disabled={!verifiedCode}
                  onClick={() => confirmDeleteData("Definitions")}
               >
                  Delete ALL Definitions
               </Button>
            </div>
         </div>
         <div className="setup-button-group">
            <div className="setup-element">
               <Button
                  fillMode="flat"
                  disabled={!verifiedCode}
                  onClick={parseDataDefs}
                  style={{ margin: "5px", fontSize: 1 * sizeFactor + "rem" }}
               >
                  Parse Data Defs
               </Button>
            </div>
            <div className="setup-element">
               <Button
                  fillMode="flat"
                  disabled={!verifiedCode}
                  onClick={resetDefsLast}
                  style={{ margin: "5px", fontSize: 1 * sizeFactor + "rem" }}
               >
                  Reset Defs Last
               </Button>
            </div>
         </div>
         <div className="setup-button-group">
            <div className="setup-element">
               <Button
                  fillMode="flat"
                  disabled={!verifiedCode}
                  onClick={fixBrokenAutoList}
                  style={{ margin: "5px", fontSize: 1 * sizeFactor + "rem" }}
               >
                  Fix Broken AutoList Datas
               </Button>
            </div>
            <div className="setup-element"></div>
         </div>
         <div
            className="setup-title-bar"
            style={{ fontSize: 1.1 * sizeFactor + "rem" }}
         >
            Code
         </div>
         <div style={{ display: "flex", padding: "5px" }}>
            <div style={{ width: "30%" }}>
               <Label
                  style={{
                     display: "block",
                     fontWeight: "600",
                     fontSize: 1 * sizeFactor + "rem",
                  }}
               >
                  Code
               </Label>
               <Input
                  value={rollingCode[0]}
                  disabled={isAdmin || verifiedCode}
                  style={{ width: "125px", fontSize: 1 * sizeFactor + "rem" }}
                  onChange={handleOnCodeChange}
               />
            </div>
            <div style={{ paddingTop: "25px", marginLeft: "20px" }}>
               <Button
                  style={{ width: "50px", marginLeft: "10px" }}
                  disabled={isAdmin || verifiedCode}
                  onClick={handleCheckRollingCode}
               >
                  Go
               </Button>
            </div>
         </div>
         {isAdmin ? (
            <div style={{ display: "flex", padding: "5px" }}>
               <div style={{ width: "20%" }}>
                  <Label
                     style={{
                        display: "block",
                        fontWeight: "600",
                        fontSize: 1 * sizeFactor + "rem",
                     }}
                  >
                     Expires
                  </Label>
                  <Input
                     value={rollingCode[1]}
                     disabled={true}
                     style={{
                        width: "90px",
                        fontSize: 1 * sizeFactor + "rem",
                        textAlign: "center",
                        marginRight: "10px",
                     }}
                  />
               </div>
               <div style={{ paddingTop: "25px", marginLeft: "15px" }}>
                  <Button
                     style={{ marginLeft: "10px" }}
                     onClick={handleGetRollingCode}
                  >
                     Get New Code
                  </Button>
               </div>
            </div>
         ) : (
            ""
         )}
         {showState.show ? (
            <Window
               title={showState.title}
               onClose={() =>
                  setShowState({
                     show: false,
                     text: "",
                     raw: "",
                     title: "",
                     type: "",
                  })
               }
               initialWidth={window.innerWidth}
               initialHeight={window.innerHeight}
            >
               <Button onClick={handleSendEmail}>Email</Button>
               <TextArea
                  className="setup-state-dump"
                  autoSize={true}
                  readOnly={true}
                  value={showState.text}
               />
            </Window>
         ) : (
            ""
         )}
         <NotificationGroup style={notifyPosition}>
            {toastState.style !== "none" ? (
               <Notification
                  type={{
                     style: toastState.style,
                     icon: true,
                  }}
                  closable={true}
                  onClose={() => setToastState({ style: "none", message: "" })}
               >
                  {toastState.message}
               </Notification>
            ) : (
               ""
            )}
         </NotificationGroup>
         {confirmInfo.visible ? (
            <Dialog
               title={confirmInfo.title}
               onClose={() => resetConfirmInfo()}
            >
               <p
                  style={{
                     textAlign: "center",
                     fontSize: 1 * sizeFactor + "rem",
                  }}
               >
                  {confirmInfo.message}
               </p>
               <DialogActionsBar>
                  <button onClick={() => resetConfirmInfo()}>No</button>
                  <button onClick={confirmInfo.callback}>Yes</button>
               </DialogActionsBar>
            </Dialog>
         ) : (
            ""
         )}
      </div>
   );
}

function mapStateToProps(state) {
   return {
      stateDump: state,
      sizeFactor: state.ui.sizeFactor,
      role: state.user.Roles,
      troubleCode: state.ui.troubleCode,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      actions: {
         setRoute: bindActionCreators(uiActions.setRoute, dispatch),
         setTroubleCode: bindActionCreators(uiActions.setTroubleCode, dispatch),
      },
   };
}

export default connect(mapStateToProps, mapDispatchToProps)(Troubles);
