import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as uiActions from "../../redux/actions/uiActions";
import * as formActions from "../../redux/actions/formActions";
import * as userActions from "../../redux/actions/userActions";
import { Label } from "@progress/kendo-react-labels";
import { Checkbox, TextArea } from "@progress/kendo-react-inputs";
import { Loader } from "@progress/kendo-react-indicators";
import { Button } from "@progress/kendo-react-buttons";
import {
   Notification,
   NotificationGroup,
} from "@progress/kendo-react-notification";
import { isNullOrEmpty, isAssigned } from "../../helpers/utils";
import { SubmitForm as SubmitFormApi } from "../../api/formsApi";
import { GetDigitalIdInfo, SaveDigitalIdInfo } from "../../db/userHandler";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../helpers/authConfig";
import Login from "../Login";
import SubmitOffline from "./SubmitOffline";
import SubmitDigitalId from "./SubmitDigitalId";
import { RecipientSelector } from "../Recipients/RecipientSelector";
import styles from "./SubmitForm.module.css";
import { getDate } from "../../helpers/dateUtils";

const notifyPosition = {
   top: 300,
   left: "50%",
   transform: "translateX(-50%)",
};

// submitType = "submit", "approve", "changeRequest"
function SubmitForm({ actions, form, user, online, sizeFactor, submitType, copyApprover, sendExcelFile }) {
   const [digitalIdInfo, setDigitalIdInfo] = useState({
      FullName: "",
      locationInfo: "",
      reason: "I am the author",
      jobTitle: ""
   });
   const [changeRequestComment, setChangeRequestComment] = useState("");
   const [submittingForm, setSubmittingForm] = useState(false);
   const [digitalIdKey, setDigitalIdKey] = useState("");
   const [notifyMessage, setNotifyMessage] = useState({
      state: "none",
      message: "",
   });
   const [selectedRecipients, setSelectedRecipients] = useState([]);
   const [sendCopyToApprover, setSendCopyToApprover] = useState(submitType === "approve" ? copyApprover : false);
   const [sendExcelFileSelection, setSendExcelFileSelection] = useState();


   const { instance, accounts } = useMsal();
   const isAuthenticated = useIsAuthenticated();

   const isSubmit = submitType === "submit";
   const isApprove = submitType === "approve";
   const isChangeRequest = submitType === "changeRequest";

   const signatureRequired =
      isChangeRequest
         ? false
         : isAssigned(form)
            ? form.hasOwnProperty("SignatureRequired")
               ? form.SignatureRequired
               : false
            : form.def.hasOwnProperty("SignatureRequired")
               ? form.def.SignatureRequired
               : false;

   // If true, then the option to submit an excel file is available
   const showSendExcelFile = !isSubmit
      ? false
      : !form.def.SendExcelFile
         ? false
         : true;

   useEffect(() => {
      GetDigitalIdInfo().then((info) => {
         setDigitalIdInfo({
            FullName: info.FullName,
            locationInfo: info.locationInfo,
            reason: info.reason,
            jobTitle: info.jobTitle || ''
         });
      });
   }, []);

   const handleSubmit = async () => {

      const additionalRecipientEmails = selectedRecipients.map(x => x.email);

      if (isApprove) {
         actions.setCopyApprover(sendCopyToApprover);
      }

      await SaveDigitalIdInfo(digitalIdInfo);
      setSubmittingForm(true);
      instance
         .acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
         })
         .then((response) => {
            SubmitFormApi(
               form.Id,
               digitalIdKey,
               submitType,
               changeRequestComment,
               response.accessToken,
               additionalRecipientEmails,
               sendCopyToApprover,
               sendExcelFileSelection
            )
               .then((resp) => {
                  if (resp === true) {
                     setNotify(true, "Form submitted successfully!");
                  }
                  else {
                     setNotify(false, "There was a problem submitting the form.  Please try again.");
                  }
               })
               .catch((error) => {
                  setNotify(
                     false,
                     "An error occurred submitting the form. " + error
                  );
               });
         });
   };

   const setNotify = (success, message) => {
      setSubmittingForm(false);
      setNotifyMessage({
         state: success ? "success" : "error",
         message: message,
      });
      if (success) {
         setTimeout(() => {
            setNotifyMessage({ state: "none", message: "" });
            actions.loadFormsList();
            actions.setRoute("forms");
         }, 2000);
      }
   };

   const handleChangeRequestChange = (event) => {
      setChangeRequestComment(event.value);
   };

   if (online && !isAuthenticated) {
      return <Login />;
   }

   if (!online) {
      return <SubmitOffline actions={actions} sizeFactor={sizeFactor} />;
   }

   const onSelectRecipient = (recipient) => {

      const exists = selectedRecipients.find(x => x.id === recipient.id) !== undefined;

      if (exists) {
         setSelectedRecipients([...selectedRecipients.filter(x => x.id !== recipient.id)]);
      }
      else {
         setSelectedRecipients([...selectedRecipients, recipient]);
      }

   };

   const onApproveCopyChange = (e) => {
      setSendCopyToApprover(e.value);
   }

   const onSendExcelFileChange = (e) => {
      setSendExcelFileSelection(e.value);
   }

   const buttonText =
      isSubmit ? "Submit" :
         isApprove ? "Approve" :
            isChangeRequest ? "Request Change" :
               submitType

   return (
      <div className={styles.container}>

         <div className={styles.actionBar}>
            <Button
               icon="undo"
               onClick={() => actions.setRoute("forms")}
               style={{ fontSize: 1 * sizeFactor + "rem" }}
            >
               Back to Form Listing
            </Button>
         </div>

         <div className={styles.sectionContainer}>

            <div className={styles.sectionTitleBar} style={{ fontSize: 1.1 * sizeFactor + "rem" }}>
               Submit Form
            </div>

            <div className={styles.sectionSubTitle}>

               <div className={styles.submitInfoItem} style={{ fontSize: 0.95 * sizeFactor + "rem" }}>
                  Form: {form.def.Name + " - " + form.def.Label}
               </div>
               <div className={styles.submitInfoItem} style={{ fontSize: 0.95 * sizeFactor + "rem" }}>
                  Report Date: {getDate(form.ReportDate)}
               </div>

               <div className={styles.submitInfoItem} style={{ textAlign: "center" }}>
                  {isChangeRequest ? (
                     "Change Request"
                  ) : signatureRequired ? (
                     <>
                        {user.HasDigitalId ? (
                           <span style={{ color: "blue" }}>Signature Required</span>
                        ) : (
                           <span style={{ color: "red" }}>
                              Signature Required
                              <br />
                              Digital Id Needed
                           </span>
                        )}
                     </>
                  ) : (
                     <span style={{ color: "blue" }}>
                        Signature Not Required
                        <br />
                        Ready to {isSubmit ? "Submit" : "Approve"}
                     </span>
                  )}
               </div>
            </div>

            <div className={styles.sectionContent}>
               {signatureRequired && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                     {user.HasDigitalId ? (
                        <SubmitDigitalId
                           digitalIdKey={digitalIdKey}
                           setDigitalIdKey={setDigitalIdKey}
                           digitalIdInfo={digitalIdInfo}
                           setDigitalIdInfo={setDigitalIdInfo}
                           sizeFactor={sizeFactor}
                           submittingForm={submittingForm}
                        />
                     ) : (
                        <div>
                           <span
                              style={{
                                 fontSize: 0.95 * sizeFactor + "rem",
                                 color: "red",
                              }}
                           >
                              Add Digital Id and Sign to Submit.
                           </span>
                        </div>
                     )}
                  </div>
               )}

               {isChangeRequest && (
                  <div>
                     <Label
                        style={{
                           display: "block",
                           fontWeight: "600",
                           fontSize: 1 * sizeFactor + "rem",
                        }}
                     >
                        Comment
                     </Label>
                     <TextArea
                        rows={4}
                        value={changeRequestComment}
                        style={{ width: "100%", fontSize: 1 * sizeFactor + "rem" }}
                        onChange={handleChangeRequestChange}
                     />
                  </div>
               )}

               {isSubmit && <RecipientSelector
                  list={user.Recipients}
                  selected={selectedRecipients}
                  onSelect={onSelectRecipient}
                  setRoute={actions.setRoute} />}

               {isApprove && <div style={{ display: 'grid', justifyContent: 'center', padding: '10px' }}>
                  <Checkbox
                     checked={sendCopyToApprover}
                     onChange={onApproveCopyChange}
                     label="Send a copy of the approved form to me">
                  </Checkbox>
               </div>}

               {showSendExcelFile && <div style={{ display: 'grid', justifyContent: 'center', padding: '10px' }}>
                  <Checkbox
                     checked={sendExcelFileSelection}
                     onChange={onSendExcelFileChange}
                     label="Send the Excel file along with the PDF">
                  </Checkbox>
               </div>}

               <div style={{ justifySelf: 'center' }}>
                  <Button
                     style={{ fontSize: 0.95 * sizeFactor + "rem" }}
                     onClick={handleSubmit}
                     disabled={
                        submittingForm ||
                        (signatureRequired && isNullOrEmpty(digitalIdKey))
                     }
                  >
                     {buttonText}
                  </Button>
               </div>
            </div>
         </div>

         {submittingForm && (
            <div
               style={{
                  textAlign: "center",
                  fontSize: 1 * sizeFactor + "rem",
                  margin: "15px 7px 0px 7px",
               }}
            >
               <p>Submitting the form to the server...</p>
               <Loader type="infinite-spinner" />
            </div>
         )}

         <NotificationGroup style={notifyPosition}>
            {notifyMessage.state !== "none" && (
               <Notification
                  type={{
                     style: notifyMessage.state,
                     icon: true,
                  }}
                  closable={true}
                  onClose={() =>
                     setNotifyMessage({ state: "none", message: "" })
                  }
               >
                  {notifyMessage.message}
               </Notification>
            )}

         </NotificationGroup>
      </div>
   );
}

function mapStateToProps(state) {
   return {
      form: state.forms.form,
      user: state.user,
      online: state.ui.online,
      sizeFactor: state.ui.sizeFactor,
      submitType: state.ui.submitType,
      copyApprover: state.user.copyApprover
   };
}

function mapDispatchToProps(dispatch) {
   return {
      actions: {
         setRoute: bindActionCreators(uiActions.setRoute, dispatch),
         loadFormsList: bindActionCreators(formActions.loadFormsList, dispatch),
         setCopyApprover: bindActionCreators(userActions.setCopyApprover, dispatch)
      },
   };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmitForm);
