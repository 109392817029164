import { GetUserDb } from "../db/userHandler";

let user = null;

export async function getUser(force) {
   if (force === undefined) force = false;
   if (user !== null && user.hasOwnProperty("FullName") && !force) {
      return user;
   }

   user = await GetUserDb();
   return user;
}
