import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as uiActions from "../redux/actions/uiActions";
import * as userActions from "../redux/actions/userActions";
import * as formActions from "../redux/actions/formActions";
import * as defActions from "../redux/actions/defActions";
import Login from "../components/Login";
import Logout from "../components/Logout";
import * as syncProcess from "../api/syncProcess";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../helpers/authConfig";
import Safety from "../components/Safety";
import "./Sync.css";
import { useIsAuthenticated } from "@azure/msal-react";
import SyncMessages from "../components/SyncMessages";
import { ReceiveTransfers } from "../components/ReceiveTransfers";

function Sync({ actions, online, sizeFactor }) {
   const [showSafety, setShowSafety] = useState(true);
   const [syncInfo, setSyncInfo] = useState({ start: false, in: false });
   const [completedState, setCompletedState] = useState({
      status: "none",
      message: "",
   });
   const [progressMessages, setProgressMessages] = useState([]);
   const [newTransfers, setNewTransfers] = useState([]);
   const { instance, accounts } = useMsal();
   const isAuthenticated = useIsAuthenticated();

   useEffect(() => {
      let isMounted = true;

      if (syncInfo.start && !syncInfo.in) {
         instance
            .acquireTokenSilent({
               ...loginRequest,
               account: accounts[0],
            })
            .then((response) => {
               setShowSafety(false);
               setSyncInfo({ start: true, in: true });
               setProgressMessages([]);
               setCompletedState({ status: "none", message: "" });
               syncProcess
                  .Sync(response.accessToken, setProgressMessages)
                  .then(async (response) => {
                     actions.setInitialUserState();
                     actions.setInitialUIState();
                     actions.setFormsInit(true);
                     actions.setDefInit(true);
                     setSyncInfo({ start: false, in: false });

                     if (response.success) {
                        setCompletedState({
                           status: "success",
                           message: "Sync Process Completed Successfully",
                        });

                        actions.setLastSyncDate(new Date().toISOString())

                        setNewTransfers(response.syncFormsResponse.results);

                     } else {
                        setSyncInfo({ start: false, in: false });
                        setCompletedState({
                           status: "error",
                           message: "Sync Process Failed",
                        });
                     }
                  })
                  .catch((error) => {
                     if (isMounted) {
                        setSyncInfo({ start: false, in: false });
                        setCompletedState({
                           status: "error",
                           message: "Sync Process Failed with a Hard Error.",
                        });
                     }
                  });
            });
      }
      return () => {
         isMounted = false;
      };
   }, [
      actions,
      instance,
      accounts,
      progressMessages,
      syncInfo.start,
      syncInfo.in,
   ]);

   if (online) {
      if (!isAuthenticated) {
         return <Login />;
      }
   }

   const handleStart = (e) => {
      e.preventDefault();
      setSyncInfo({ start: true, in: false });
   };

   const onTransfersCompleted = () => {
      setNewTransfers([]);
   }

   return (
      <div>
         {!syncInfo.start ? (
            <div>
               <div style={{ display: "flex", justifyContent: "right" }}>
                  <div>
                     <Logout />
                  </div>
               </div>
               {completedState.status !== "none" ? (
                  <Card>
                     <CardHeader
                        className="card-header"
                        style={{ fontSize: 1.15 * 1 + "rem" }}
                     >
                        Sync Finished
                     </CardHeader>

                     <CardBody
                        className="card-body"
                        style={{
                           fontSize: 1.15 * 1 + "rem",
                           color:
                              completedState.status === "error"
                                 ? "red"
                                 : "green",
                        }}
                     >
                        {completedState.message}
                     </CardBody>
                  </Card>
               ) : null}
               <div className="sync-button-container">
                  <Button
                     className="sync-button"
                     themeColor="primary"
                     icon="reset"
                     onClick={handleStart}
                     disabled={!online}
                     style={{ fontSize: 1.15 * sizeFactor + "rem" }}
                  >
                     Start Sync
                  </Button>
               </div>
               {!online ? (
                  <div
                     className="sync-offline-message"
                     style={{ fontSize: 1.15 * sizeFactor + "rem" }}
                  >
                     A network connection is not detected.
                     <br />
                     <br />
                     Please try again later.
                  </div>
               ) : null}
            </div>
         ) : (
            <div>
               <div className="sync-loader-container">
                  <p>Please wait while data is sync'd with the server...</p>
                  <Loader type="infinite-spinner" />
               </div>
               <SyncMessages
                  progressMessages={progressMessages}
                  sizeFactor={sizeFactor}
               />
            </div>
         )}
         {showSafety ? (
            <Safety sizeFactor={sizeFactor} style={{ marginTop: "125px" }} />
         ) : null}
         {completedState.status !== "none" ? (
            <SyncMessages
               progressMessages={progressMessages}
               sizeFactor={sizeFactor}
            />
         ) : null}

         {newTransfers.length > 0 &&
            <ReceiveTransfers
               transfers={newTransfers.filter(x => x.isNewTransfer === true)}
               onTransfersCompleted={onTransfersCompleted} />
         }

      </div>
   );
}

function mapStateToProps(state) {
   return {
      online: state.ui.online,
      sizeFactor: state.ui.sizeFactor,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      actions: {
         setInitialUserState: bindActionCreators(
            userActions.setInitialUserState,
            dispatch
         ),
         setInitialUIState: bindActionCreators(uiActions.setInitialUIState, dispatch),
         setFormsInit: bindActionCreators(formActions.setFormsInit, dispatch),
         setDefInit: bindActionCreators(defActions.setDefInit, dispatch),
         setLastSyncDate: bindActionCreators(userActions.setLastSync, dispatch)
      },
   };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sync);
