import * as logger from "../helpers/logger";
import { isNullOrEmpty } from "../helpers/utils";
import db from "./db";
import { GetForm } from "./formHandler";
import { GetDataForForm } from "./dataHandler";
import { FindValidationIdFromValue } from "./valHandler";
import * as enums from "../helpers/enums";

export async function FixBrokenAutoListDatas() {
   logger.System("fixHandler:FixBrokenAutoListDatas", "Start Fix");

   let dataList = [];
   await db.formData.toCollection().each(function (datadb, cursor) {
      if (
         datadb.hasOwnProperty("groupDataNameIds") &&
         datadb.groupDataNameIds.length > 0
      ) {
         dataList.push({
            Id: datadb.Id,
            formId: datadb.formId,
            Value: datadb.Value,
            DefId: datadb.DefId,
         });
      }
   });

   let form;
   try {
      let lastformid = null;
      for (let d = 0; d < dataList.length; d++) {
         let data = dataList[d];

         if (lastformid === null || lastformid !== data.formId) {
            form = await GetForm(data.formId);
         }
         lastformid = form.Id;

         let datadef = form.def.DataDefs[data.DefId];

         if (
            !isNullOrEmpty(datadef.ValType) &&
            !isNullOrEmpty(datadef.ValList)
         ) {
            const newvalue = await FindValidationIdFromValue(
               datadef.ValType,
               datadef.ValList,
               data.Value
            );
            if (newvalue !== data.Value) {
               const updateDataObject = {
                  Value: newvalue,
                  State:
                     data.State === enums.State.New
                        ? enums.State.New
                        : enums.State.Update,
               };
               await db.formData.update(data.Id, updateDataObject);
               if (form.State !== enums.State.New) {
                  await db.forms.update(form.Id, { State: enums.State.Update });
               }
            }
         }
      }
   } catch (err) {
      logger.Error(
         "fixHandler:FixBrokenAutoListDatas",
         "Error occurred fixing data",
         err
      );
      return false;
   }
   return true;
}

export async function ConvertPictures() {
   logger.System("fixHandler:ConvertPictures", "Start Convert Pictures");
   const formids = await db.forms.toCollection().primaryKeys();
   try {
      for (let f = 0; f < formids.length; f++) {
         const formid = formids[f];
         let form = await db.forms.get(formid);
         let def = await db.defs.get(form.DefId);
         let formdata = await GetDataForForm(formid);

         const keys = Object.keys(formdata);
         for (let k = 0; k < keys.length; k++) {
            let data = formdata[keys[k]];
            if (data.hasOwnProperty("content")) continue;

            let datadef = def.DataDefs[data.DefId];
            if (datadef.InputType !== enums.InputType.Picture) continue;

            data.content = data.Value;
            data.Value = "";
            await db.formData.put(data);
         }
      }
   } catch (err) {
      logger.Error(
         "fixHandler:ConvertPictures",
         "Error occurred converting pictures",
         err
      );
      return false;
   }
   logger.System("fixHandler:ConvertPictures", "Finished Convert Pictures");
   return true;
}
