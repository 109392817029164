import { Button } from '@progress/kendo-react-buttons';
import { IRecipient } from "../../types/IRecipient";
import styles from './RecipientCard.module.css';

interface ICardProps {
  recipient: IRecipient,
  onRemove: (recipient: IRecipient) => void;
  onEdit: (recipient: IRecipient) => void;
}

const RecipientCard = ({ recipient, onEdit, onRemove }: ICardProps) => {

  const onRemoveClick = () => {
    if (window.confirm(`Are you sure you want to delete '${recipient.name}?`)) {
      onRemove(recipient);
    }
  };

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardDetail}>
        <div className={styles.bold}>{`${recipient.name}, ${recipient.title}`}</div>
        <div>{recipient.email}</div>
      </div>
      <div className={styles.actionButtons}>
        <Button fillMode='outline' icon="pencil" size='small' onClick={() => onEdit(recipient)}></Button>
        <Button onClick={onRemoveClick} fillMode='outline' themeColor='primary' icon="trash" size='small'></Button>
      </div>
    </div>
  );
}

export { RecipientCard };