import { isNullOrEmpty } from "./utils";
import * as enums from "./enums";

interface IGroupStateCounts {
  total: number;
  totalEntered: number;
  required: number;
  requiredEntered: number;
  viewed: boolean;
}

const incrementTotals = (counts: IGroupStateCounts, dataValue: string | number, dataDef: any) => {
  counts.total++;

  switch (dataDef.InputType) {
    case enums.InputType.CheckBox:
      counts.totalEntered++;
      break;

    case enums.InputType.Number:
      if (dataDef.Required) {
        counts.required++;
        // eslint-disable-next-line eqeqeq
        if (!isNullOrEmpty(dataValue) && dataValue != 0) {
          counts.totalEntered++;
          counts.requiredEntered++;
        }
      } else {
        if (!isNullOrEmpty(dataValue)) counts.totalEntered++;
      }
      break;

    default:
      if (dataDef.Required) {
        counts.required++;
        if (!isNullOrEmpty(dataValue)) {
          counts.totalEntered++;
          counts.requiredEntered++;
        }
      } else if (!isNullOrEmpty(dataValue)) {
        counts.totalEntered++;
      }
      break;
  }
}

interface IGroupStateSummary {
  isGroup: boolean;
  isData: boolean;
  isRepeating: boolean;
  isViewed: boolean;
  isCompleted: boolean;
  isRequired: boolean;
}

interface IFormGroup {
  Id: number;
  WasViewed: boolean;
  total: number;
  totalEntered: number;
  required: number;
  requiredEntered: number;
  DefId: number;
  Groups: number[];
}

interface IGroupDefinition {
  Repeats: boolean;
  Type: string | number;
}

const getGroupEditStatus = (form: any, groupId: number): IGroupStateSummary => {

  const group: IFormGroup = form.FormGroups[groupId];
  const groupDef: IGroupDefinition = form.def.GroupDefs[group.DefId];

  const isData = groupDef.Type === enums.GroupType.Data;
  const isGroup = groupDef.Type === enums.GroupType.Group;
  const isRepeating = groupDef.Repeats && groupDef.Type === enums.GroupType.Group;
  let isViewed = group.WasViewed === true;
  let isCompleted = group.totalEntered === group.total;
  let isRequired = group.required > group.requiredEntered;

  if (isGroup) {
    const groupStates = group.Groups.map(x => getGroupEditStatus(form, x));
    isCompleted = groupStates.every(x => x.isViewed === true && x.isCompleted === true);
    isViewed = groupStates.find(x => x.isViewed === true) !== undefined;
    isRequired = groupStates.find(x => x.isRequired === true && x.isViewed === true) !== undefined;
  }

  return {
    isGroup,
    isRepeating,
    isData,
    isViewed,
    isCompleted,
    isRequired
  };

};

export { incrementTotals, getGroupEditStatus }


