import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

interface IProps {
  title: string;
  body: JSX.Element;
  onOk: () => void;
  onClose: () => void;
}
const AppOkDialog = ({ title, body, onClose }: IProps) => {
  return <Dialog title={title} onClose={onClose}>
    <p
      style={{
        margin: "25px",
        textAlign: "center",
      }}
    >
      {body}
    </p>
    <DialogActionsBar>
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
        onClick={onClose}
      >
        Ok
      </button>
    </DialogActionsBar>
  </Dialog>

}

export { AppOkDialog };