import db from "./db";
import { GetFormDefsList } from "./defHandler";
import * as enums from "../helpers/enums";
import { getISODateTime, isNullOrEmpty } from "../helpers/utils";
import { SetDefaults } from "../helpers/resolver";
import { GetDataForForm } from "./dataHandler";
import { AddCancelKey } from "./keyHandler";
import {
   CreateGroups,
   PopulateFormGroups,
   SetGroupTotals,
   MapGroups,
} from "./groupHandler";
import { GetNextFormId } from "./keyHandler";
import { UpdateDefaultsLast } from "./lastHandler";

export async function GetFormsList() {
   let list = [];
   const workorders = await db.vals.get("WorkOrders");
   const defList = await GetFormDefsList();
   await db.forms.toCollection().each(function (form, cursor) {
      if (form.State !== enums.State.Delete) {
         const defidx = defList.findIndex((def) => {
            return def.Id === form.DefId;
         });
         const def = defList[defidx];
         const workordername = workorders.records.hasOwnProperty(
            form.WorkOrderId
         )
            ? workorders.records[form.WorkOrderId].Name
            : form.WorkOrderId;
         list.push({
            Id: form.Id,
            CreateDateTime: form.CreateDateTime,
            ReportDate: form.ReportDate,
            State: form.State,
            Status: enums.Status[form.Status],
            DefName: def.Name,
            DefLabel: def.Label,
            DefId: form.DefId,
            WorkOrderName: workordername,
            Title: form.Title,
            highlight: form.highlight && form.Status !== enums.Status.Approved,
            assigned: form.hasOwnProperty("assigned") ? form.assigned : false,
         });
      }
   });
   return list;
}

export async function SetFormStatus(formId, status) {
   if (status === enums.Status.Canceled) {
      if (formId > 0) {
         await AddCancelKey(formId);
      }
      await DeleteForm(formId);
   } else {
      let form = await db.forms.get(formId);
      if (form.status !== status) {
         form.prevStatus = form.Status;
         form.Status = status;
         if (form.Status !== enums.State.New && formId > 0)
            form.State = enums.State.Update;
         await db.forms.put(form);
      }
   }
   return GetFormsList();
}

export async function UpdateFormSaveData(formId, dataId, datadef, value) {
   let form = await db.forms.get(formId);

   let anychange = false;
   if (
      datadef.InputType === enums.InputType.Weather &&
      !isNullOrEmpty(value) &&
      value.startsWith("{")
   ) {
      if (!form.hasOwnProperty("weather")) {
         form.weather = [];
         anychange = true;
      }
      const weatherIndex = form.weather.indexOf(dataId);
      const obj = JSON.parse(value);
      if (obj.source === "later") {
         if (weatherIndex === -1) {
            form.weather.push(dataId);
            anychange = true;
         }
      } else {
         if (weatherIndex !== -1) {
            form.weather.splice(weatherIndex, 1);
            anychange = true;
         }
      }
   }

   if (form.State === enums.State.None) {
      form.State = enums.State.Update;
      anychange = true;
   }

   if (anychange) await db.forms.put(form);
}

export async function SaveEditForm(id, updateObject) {
   await db.forms.update(id, updateObject);
   return GetForm(id);
}

export async function GetForm(formId) {
   if (formId === null || formId === 0) {
      return {};
   }

   let form = await db.forms.get(formId);
   if (form === undefined) return null;

   form.FormGroups = {};

   await PopulateFormGroups(form.Groups, form);

   form.def = await db.defs.get(form.DefId);

   return form;
}

export async function CreateNewForm(defId, date, woId, location, title) {
   let def = await db.defs.get(defId);

   let username = await getUsername();

   let nformid = await GetNextFormId();
   let form = {
      Id: nformid,
      OrigId: nformid,
      Title: title,
      ReportDate: date,
      CreatedBy: username,
      AssignedTo: username,
      CreateDateTime: getISODateTime(),
      UpdateDateTime: null,
      UploadDateTime: null,
      ApprovedDateTime: null,
      ChangeRequestComment: "",
      Location: location,
      DefId: defId,
      WorkOrderId: woId,
      Status: enums.Status.New,
      State: enums.State.New,
      Groups: [],
      changeRequest: false,
      highlight: false,
      assigned: false,
   };

   form.dataDefX = {};
   form.Groups = await CreateGroups(def.Groups, form, def, 0, null, false);

   await db.forms.put(form);

   form.FormGroups = {};

   await PopulateFormGroups(form.Groups, form);

   form.def = def;
   form.groupX = await MapGroups(form);

   const formdata = await GetDataForForm(form.Id);
   const lasts = await SetDefaults(form, formdata);
   UpdateDefaultsLast(lasts, form, formdata);
   await SetGroupTotals(formdata, form);

   let obj = {
      form: form,
      list: await GetFormsList(),
   };

   return obj;
}

async function getUsername() {
   const ui = await db.ui.get(1);
   return ui.username;
}

export async function DeleteForm(id) {
   await db.forms.delete(id);
   await db.formGroups.where("formId").equals(id).delete();
   await db.formData.where("formId").equals(id).delete();
}

export async function SetDeleteForm(id) {
   if (id > 0) {
      const dbform = db.forms.get(id);
      dbform.State = enums.State.Delete;
      await db.forms.put(dbform);
      return;
   }
   await DeleteForm(id);
}

export async function CheckFormId(id) {
   const formdb = await db.forms.get(id);
   if (!isNullOrEmpty(formdb)) {
      return id;
   }
   if (id > 0) throw new Error("Form Id " + id + " could not be found");

   let newid = -1;
   await db.forms.toCollection().each(function (form, cursor) {
      if (form.OrigId === id) {
         newid = form.Id;
      }
   });
   if (newid === -1) throw new Error("Form Id " + id + " could not be found");

   return newid;
}
