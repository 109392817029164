import * as types from "../actionTypes";
import initialState from "../initialState";

export default function userReducer(state = initialState.user, action) {
   switch (action.type) {
      case types.SET_INITIAL_USER_STATE:
         return { ...action.newstate };
      case types.SET_DIGITAL_ID:
         return { ...state, HasDigitalId: action.hasDigitalId };
      case types.SET_EMAIL_RECIPIENTS:
         return { ...state, Recipients: [...action.recipients] };
      case types.SET_LAST_SYNC:
         return { ...state, LastSyncDate: action.lastSyncDate };
      case types.SET_COPY_APPROVER:
         return { ...state, copyApprover: action.copyApprover };
      case types.SET_FORM_NEW_LOCATION:
         return { ...state, FormNewPreferences: {
            LastZipCodeUsed: action.location, 
            LastLocationType: action.locationType,
         }};
      case types.SET_FORM_LISTING_SORT_BY:
         return { ...state, FormListingPreferences: {SortByValue: action.sortByValue} };
      default:
         return state;
   }
}
